import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {ReactThreeFiber} from "@react-three/fiber";
import {InstancedBufferAttribute, InstancedMesh, Object3D} from "three";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/mountain-two-transformed.glb`;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // TODO UPDATE TO ALLOW NULL ON TYPES
      instancedMesh: ReactThreeFiber.Object3DNode<InstancedMesh, typeof InstancedMesh>
      instancedBufferAttribute: ReactThreeFiber.Object3DNode<InstancedBufferAttribute, typeof InstancedBufferAttribute>
    }
  }
}

type GLTFResult = GLTF & {
  nodes: {
    Mountain_B: THREE.Mesh
  }
  materials: {
    ['LPEP_Atlas01.001']: THREE.MeshStandardMaterial
  }
}

export default function Mountains({ snow, divisionAmount, radius, ...props }: any) {

  // const DIVISION_AMOUNT = 7; // 10 will produce 36 mountain
  // const RADIUS = 380;
  const [mountainCount, setMountainCount] = useState(360/divisionAmount);

  // useEffect(() => {
  //   if(weatherData) {
  //     setMountainCount(weatherData.weather.mountainCount);
  //     setSpreadDistance(weatherData.weather.spreadDistance);
  //     console.log('mountainCount', weatherData.weather.mountainCount)
  //     console.log('spreadDistance', weatherData.weather.spreadDistance)
  //   }
  // }, [weatherData])

  const ref = useRef(null!);
  const dummy = new Object3D();
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  useEffect(() => {
    let allMountains: any = [];

    // https://stackoverflow.com/questions/43641798/how-to-find-x-and-y-coordinates-on-a-flipped-circle-using-javascript-methods#:~:text=Typically%2C%20to%20find%20the%20x,sin(degrees%E2%80%8E%C2%B0)).
    function circleXZ(radius: number, theta: number) {
      // Convert angle to radians
      theta = (theta-90) * Math.PI/180;
      return {x: radius*Math.cos(theta),
        z: -radius*Math.sin(theta)}
    }

    for (let theta=0; theta<=360; theta += divisionAmount) {
      let answer = circleXZ(radius, theta);
      // console.log('(x, y) = ' + '(' + answer.x + ', ' + answer.y + ') for theta=' + theta);
      allMountains.push({position: [answer.x, 0, answer.z]});
    }

    // TODO CHECK HOW MANY THERE ACTUALLY ARE
    // console.log('allMountains', allMountains);

    // pow 2 because value comes in as a sqrt
    allMountains.forEach((mountain: any, index: number) => {
      // dummy.position.set(Math.random()*10*i,0, Math.random()*10*i);
      dummy.position.set(mountain.position[0], mountain.position[1], mountain.position[2]);
      dummy.rotation.set(0, Math.random(), 0);
      // dummy.position.set(0, 2, 0);
      // dummy.scale.setScalar(1.5+Math.random()+Math.random());
      dummy.scale.setScalar(0.4+Math.random()+Math.random());
      dummy.updateMatrix();
      // @ts-ignore
      ref.current.setMatrixAt(index, dummy.matrix);
    });
    // @ts-ignore
    ref.current.instanceMatrix.needsUpdate = true;
  }, [mountainCount]);

  // Math.pow(count,2)*4], because there are 4 quadrants each squared
  // todo look into shadows
  return (
    <instancedMesh ref={ref} args={[nodes.Mountain_B.geometry, materials['LPEP_Atlas01.001'], mountainCount]} >

      {/*<group ref={group} {...props} dispose={null}>*/}
      {/*  <mesh castShadow receiveShadow geometry={nodes.Mountain_B.geometry} material={materials['LPEP_Atlas01.001']} >*/}
          <meshStandardMaterial attach="material"  color={snow ? 'white' : 'grey'} />
      {/*  </mesh>*/}
      {/*</group>*/}

    </instancedMesh>
  )
}
