import * as THREE from 'three'
import { RigidBody } from "@react-three/rapier"
import {ReactThreeFiber} from "@react-three/fiber";
import {InstancedMesh, InstancedBufferAttribute, Object3D} from "three";
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // TODO UPDATE TO ALLOW NULL ON TYPES
      instancedMesh: ReactThreeFiber.Object3DNode<InstancedMesh, typeof InstancedMesh>
      instancedBufferAttribute: ReactThreeFiber.Object3DNode<InstancedBufferAttribute, typeof InstancedBufferAttribute>
    }
  }
}

type GLTFResult = GLTF & {
  nodes: {
    Fence: THREE.Mesh
  }
  materials: {
    Fence: THREE.MeshStandardMaterial
  }
}

export function Fences ({ nodes, materials, lowGraphics, position, rotation, scale, count }: any) {

  const ref = useRef(null!);
  const dummy = new Object3D();
// >>>>>>> fences-to-instanced

  // console.log(materials)

  useEffect(() => {
    // @ts-ignore
    // geometry.computeVertexNormals();

    // Math.random()+1
    //
    // console.log(`${count} count`)
    // pow 2 because value comes in as a sqrt
    for (let i = 0; i < count; i++) {

      // console.log('here')
      // console.log(i)
      // todo replace with mod function
      if (i == 10 || i == 20 || i == 30 || i == 40 || i == 50 || i == 60 || i == 70 || i == 80 || i == 90) {

// <<<<<<< HEAD
//   // todo fix fence position in blender so can add collider properly in instanced mesh (might be the issue)
//
//   return (
//     <RigidBody {...props} type="fixed" colliders="cuboid" ref={ref}>
//       <group ref={group} {...props} dispose={null}>
//         <mesh castShadow receiveShadow geometry={nodes.Fence.geometry} material={materials.Fence} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={1} />
//       </group>
//     </RigidBody>
// =======
      } else {

        // console.log('here2')

        // key={`fence-${i}`}

        const posX = position[0](i);
        // console.log(`posX ${posX}`);

        const posZ = position[2](i);
        // console.log(`posZ ${posZ}`);

        dummy.position.set(position[0](i), position[1], position[2](i));
        dummy.rotation.set(rotation[0], rotation[1], rotation[2]);
        dummy.scale.setScalar(scale);
        dummy.updateMatrix();
        // @ts-ignore
        ref.current.setMatrixAt(i, dummy.matrix); // todo should this be i or i++?
        // ref.current.setMatrixAt(i++, dummy.matrix);
      }
    }
    // @ts-ignore
    ref.current.instanceMatrix.needsUpdate = true;
  }, [count, lowGraphics]);  // todo may need to re-add geometry (and then move geometry into variable above)

  {/*<group ref={group} {...props} dispose={null}>*/}
  {/*  <mesh castShadow receiveShadow geometry={nodes['01_Tree_Fir_2'].geometry} material={materials.Mat_3} position={[0,0,0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />*/}
  {/*</group>*/}

  // todo is it worth making only the first few lines do shadow stuff ?
  // <RigidBody type="fixed" colliders="cuboid">
  return (
    <>
      {!lowGraphics && (
        <instancedMesh ref={ref} castShadow receiveShadow args={[nodes.Fence.geometry, materials.Fence, count]} >
          {/*<RigidBody scale={scale} type="fixed" colliders="cuboid">*/}
          {/*</RigidBody>*/}
        </instancedMesh>
      )}
      {lowGraphics && (
        <instancedMesh ref={ref} args={[nodes.Fence.geometry, materials.Fence, count]} >
          {/*<RigidBody scale={scale} type="fixed" colliders="cuboid">*/}
          {/*</RigidBody>*/}
        </instancedMesh>
      )}
    </>
  )
}


export function WoodenFence({ lowGraphics, position, rotation, scale, count }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/fence-one-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Fences nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} count={count} />
  )
}

export function WoodenFenceSnow({ lowGraphics, position, rotation, scale, count }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/snow-version/fence-snow-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Fences nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} count={count} />
  )
}

