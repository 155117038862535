import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material";
import {useWeb3React} from "@web3-react/core";
import {validEmail} from "../../helpers/helperFunctions";
import {getSignature, postTransaction} from "../../helpers/web3Functions";
type ConsoleState = 'error' | 'success';

/**
 * PlantModal
 *
 * @param placing
 * @param setPlacing
 * @param setPlacedObject
 * @param donationAmount
 * @param setDonationAmount
 * @param yourOrganization
 * @param setYourOrganization
 * @param mode
 * @param highlightedCommunity
 * @param setAllFailed
 * @param setTransactionHash
 * @param setShowInfoModal
 * @param setSlideModalCharacter
 * @param setSlideData
 * @param setSlideMessage
 * @param setShowSlideModal
 * @param allDone
 * @param setAllDone
 * @param roseUUID
 * @param setObjectUUID
 * @param receiptEmail
 * @param setReceiptEmail
 * @param messageFrom
 * @param sendToEmail
 * @param noteMessage
 * @param placedObject
 * @param objectColor
 * @param showInfoModal
 * @param lockKeyboard
 * @param setLockKeyboard
 * @param showPlantModal
 * @param setShowPlantModal
 * @param pointerControls
 * @constructor
 */
export default function PlantModal (
  { placing, setPlacing, setPlacedObject, donationAmount, setDonationAmount, yourOrganization, setYourOrganization,
    mode, highlightedCommunity, setAllFailed, setTransactionHash, setShowInfoModal, setSlideModalCharacter,
    setSlideData, setSlideMessage, setShowSlideModal, allDone, setAllDone, setObjectUUID, receiptEmail,
    setReceiptEmail, messageFrom, sendToEmail, noteMessage, placedObject, objectColor, showInfoModal, lockKeyboard,
    setLockKeyboard, showPlantModal, setShowPlantModal, pointerControls }: any
) {
  const { account, library, chainId } = useWeb3React();
  const [transactionComplete, setTransactionComplete] = useState(false);
  const [transactionProcessing, setTransactionProcessing] = useState(false);
  const [consoleMessage, setConsoleMessage] = useState("");
  const [consoleState, setConsoleState] = useState<ConsoleState>('success');
  const [localReceiptEmail, setLocalReceiptEmail] = useState("");
  const [validEmailAddress, setValidEmailAddress] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [allowDonateButtonClick, setAllowDonateButtonClick] = useState(true);

  useEffect(() => {
    if (showPlantModal === true) {
      setLockKeyboard(true);
    } else {
      setLockKeyboard(false);
    }
  }, [showPlantModal]);

  useEffect(() => {
    if (chainId === 137) {
      setDonationAmount(10);
    } else {
      setDonationAmount(0.01);
    }
  }, [chainId]);

  // const lionImage = useLoader(THREE.TextureLoader, imgs.lion);

  useEffect(() => {
    if (transactionComplete) {
      setAllowDonateButtonClick(false)
    } else if (transactionProcessing){
      setAllowDonateButtonClick(false)
    } else {
      setAllowDonateButtonClick(true)
    }
  }, [transactionComplete, transactionProcessing])

  // TODO IF YOU USE THIS AS THE INTRO MODAL, NEED TO CHECK FOR POINTERCONTROLS.CURRENT === NULL AS THIS IS THE FIRST THING THE USER SEES, NEED TO MAKE SURE POINTERCONTROLS EXISTS OTHERWISE ERRORS
  const handleClose = () => {

    // todo check this keeps the confirmation page open when clicking donate
    if (showConfirmationModal && !allDone) {
      setShowConfirmationModal(false);
    } else {
      setShowPlantModal(false);

      // don't re-engage lock on info modal (so can use close when making donation)
      if (!showInfoModal) {
        pointerControls.current.connect()
        setTimeout(() => {
          pointerControls.current.lock()
        }, 110) // this needs to be higher than the timeout on the modal
      }
    }
  };

  const handleChangeDonationAmount = (value: any) => {
    setDonationAmount(value);
  };

  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const handleChangeReceiptEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    // todo still need to lock keyboard controls
    setTimeout(() => {pointerControls.current.unlock()},100);
    setLockKeyboard(true);
    const newEmail = event.target.value;
    setLocalReceiptEmail(newEmail)
    if (validEmail(newEmail)) {
      setValidEmailAddress(true);
      setReceiptEmail(newEmail);
    } else {
      setValidEmailAddress(false);
      setReceiptEmail("");
    }
  }

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90vw',
    minWidth: '85vw',
    maxHeight: '90vh',
    // minHeight: '85vh',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const sendTransaction = async () => {
    setTransactionProcessing(true);

    const signature = await getSignature(library, account, donationAmount);
    console.log(signature)
    if (signature !== null) {
      setTransactionProcessing(false);
      setConsoleState('success');
      setConsoleMessage(`Transaction Complete.. Saving ${mode.replace(/\w/, (c: string) => c.toUpperCase())}..`);
      setTransactionHash(signature.hash);
      setTransactionComplete(true);

      console.log(signature)

      const data = await postTransaction(signature, chainId, donationAmount, mode, receiptEmail, yourOrganization, false, noteMessage, objectColor, placedObject, highlightedCommunity, sendToEmail, messageFrom);
      console.log(data)
      if (data.message === 'success') {
        // todo get receipt?
        setConsoleState('success');
        setConsoleMessage(`{\`${mode.replace(/\w/, (c: string) => c.toUpperCase())}\`} Saved.`);
        setAllDone(true); // all done will trigger a refresh of the field
        // todo not sure why we have placedObject AND placing
        setPlacedObject(false);
        setPlacing(false);
        setObjectUUID(data.uuid);
        setShowSlideModal(true);
        setSlideModalCharacter('herbert');
        // setSlideData
        setSlideMessage('thanks');
        setShowPlantModal(false);
        setShowInfoModal(false);
      } else {
        // in this scenario, the transaction might
        setConsoleState('error');
        setConsoleMessage(`Error Saving ${mode.replace(/\w/, (c: string) => c.toUpperCase())}. Please contact us by pressing [i] and submitting feedback (please include transaction id: ${signature ? signature.hash : ''}).`);
        setAllDone(true); // this stops the donate button being clicked again (and a user creating multiple objects in the same place on the backend
        setAllFailed(true);
        setTransactionProcessing(false);
        setConsoleState('error');
      }
    } else {
      setConsoleState('error');
      setConsoleMessage('There was an error, do you have enough funds?');
      setAllDone(true); // this stops the donate button being clicked again (and a user creating multiple objects in the same place on the backend
      setAllFailed(true);
    }
  };

  return (
    <Modal
      className="info-modal"
      open={showPlantModal}
      onClose={handleClose}
      onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}

      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
    >
      <Box className="infoModal" component="div" sx={modalStyle}>

        { !showConfirmationModal && (
          <>
            <h2 className="modalTitle">Donate ({chainId === 1 ? "Eth" : ""}{chainId === 137 ? "Matic" : ""}{chainId === 80001 ? "Testnet Matic" : ""})</h2>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between" }}>
              {chainId === 1 && (
                <a target="_blank" href="https://chainlist.org/chain/1"><img style={{width:"175px", height:"auto", marginRight:"15px" }} src={`https://assets.unegma.net/shared/various/ethereum-logo-2.png`} /></a>
              )}
              {chainId === 137 && (
                <a target="_blank" href="https://chainlist.org/chain/137"> <img style={{width:"140px", height:"auto" }} src={`https://assets.unegma.net/shared/various/polygon-logo.png`} /></a>
              )}
              {chainId === 80001 && (
                <a target="_blank" href="https://chainlist.org/chain/80001"> <img style={{width:"140px", height:"auto", filter: "grayscale(1)" }} src={`https://assets.unegma.net/shared/various/polygon-mumbai-logo.jpg`} /></a>
              )}
            </div>

            <br/>

            <Typography>
              Please enter <b>Donation Amount</b> in <b>{chainId === 1 ? "Eth" : ""}{chainId === 137 ? "Matic" : ""}{chainId === 80001 ? "Testnet Matic" : ""}</b> (or Switch Network to use other funds):<br/>
              <span style={{color:'green'}}>(The bigger the donation, the bigger the { mode === 'rose' ? 'Rose' : mode === 'rocket' ? 'Rocket' : ''})</span>
            </Typography>
            {/*<div style={{}}>*/}
            {/*  <span style={{color:'red'}}>Display my Donation Amount</span>*/}
            {/*  <Tooltip title="Amount will be displayed in the field">*/}
            {/*    <Checkbox onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)} checked={showDonationAmount} onChange={(e) => handleShowDonationAmount(!showDonationAmount)} />*/}
            {/*  </Tooltip>*/}
            {/*</div>*/}

            <br/>


            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
              <OutlinedInput
                type="number"
                id="outlined-adornment-amount"
                value={donationAmount}
                onChange={(e) => handleChangeDonationAmount(e.target.value)}
                startAdornment={<InputAdornment position="start">&#8801;</InputAdornment>}
                // labelWidth={60}
                endAdornment={<InputAdornment position="end">{chainId === 1 ? "Eth" : ""}{chainId === 137 ? "Matic" : ""}{chainId === 80001 ? "Testnet Matic" : ""}</InputAdornment>}
              />
              <br/>
              <TextField
                className={validEmailAddress ? 'green-input' : 'red-input'}
                // defaultValue={objectCount}
                // inputProps={{ maxLength: 4 }}
                label="Receipt (Email)"
                value={localReceiptEmail}
                onChange={handleChangeReceiptEmail}
                onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
              />
            </FormControl>
            <br/>


            <p className={consoleState === 'error' ? 'red' : 'green' }>{consoleMessage}</p>

            <Button style={{marginRight: "5px"}} className="buyButton" variant="contained" color="error" onClick={handleShowConfirmationModal}>
              Donate
            </Button>
          </>
        )}

        { showConfirmationModal && (
          <>
            <h2>Confirmation</h2>

            <span>You will be given an option in the future to <b style={{color:'green'}}>Mint your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`} as an NFT</b> with its co-ordinates.</span>

            <hr/>

            <p><b>Message (only displayed to those with the link)</b>: {noteMessage}</p>

            { mode == 'rose' && (
              <>
                <p><b>To</b>: {sendToEmail}</p>
                <p><b>From (anonymous if blank)</b>: {messageFrom}</p>
              </>
            )}

            <br/>
            <p><b>Receipt To</b>: {receiptEmail}</p>
            <p><b>Donation</b>: {donationAmount} {chainId === 1 ? "Eth" : ""}{chainId === 137 ? "Matic" : ""}{chainId === 80001 ? "Testnet Matic" : ""}</p>
            <p><b>Payment Network</b>: {chainId === 1 ? "Ethereum" : ""}{chainId === 137 ? "Polygon" : ""}{chainId === 80001 ? "Polygon Mumbai Testnet" : ""}</p>

            <hr/>
            <p><b>Your Organization</b>: {yourOrganization}</p>

            <hr/>

            <Typography className='red'>
              Amount does not include (usually small) <b>Network Transaction Fee</b> (non-refundable).
            </Typography>

            <p className={consoleState === 'error' ? 'red' : 'green' }><b>{consoleMessage}</b></p>

            <br/>

            <Button disabled={!allowDonateButtonClick} style={{marginRight: "5px"}} className="buyButton" variant="contained" color="error" onClick={sendTransaction}>
              Donate
            </Button>

          </>
        )}

        <Button style={{float: "right"}} className="closeInfoModalButton--large" variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Modal>
  )
}
