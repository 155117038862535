import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  Description,
  Twitter,
  LocalFlorist, FilterVintage, RocketLaunch
} from "@mui/icons-material";
import Web3ConnectionButtons from "./Web3ConnectionButtons";
// import NightTimeSwitch from "./NightTimeSwitch";
// import SnowSwitch from "./SnowSwitch";

let imgs = {
  lion: `${process.env.REACT_APP_ASSETS_URL}/lionImage.jpg`,
  lionDark: `${process.env.REACT_APP_ASSETS_URL}/lionDarkImage.jpg`,
};

/**
 * NavBar
 * @param DEBUG_MODE
 * @param mode
 * @param setMode
 * @param lowGraphics
 * @param setLowGraphics
 * @param snow
 * @param setSnow
 * @param night
 * @param setNight
 * @param showInfoModal
 * @param setShowInfoModal
 * @param setObjectCount
 * @param setModalOpen
 * @param showModal
 * @param modalOpen
 * @param pointerControls
 * @param showNFTModal
 * @constructor
 */
export default function NavBar(
  {
    DEBUG_MODE, mode, setMode, lowGraphics, setLowGraphics, snow, setSnow, night, setNight, showInfoModal,
    setShowInfoModal, setObjectCount, setModalOpen, showModal, modalOpen, pointerControls, showNFTModal
  }: any)
{

  // // this prevents errors if the pointercontrols isnt defined
  // if (mode == "") {
  //   pointerControls = {
  //     current: {
  //       lock: () => {},
  //       unlock: () => {},
  //       connect: () => {}
  //     }
  //   }
  // }

  /**
   * handleChangeObjectCount
   * @param event
   */
  const handleChangeObjectCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newObjectCount = parseInt((event.target.value));
    if (newObjectCount >= 1 && newObjectCount <= 9999) {
      setObjectCount(newObjectCount);
    }
  }

  /**
   * handleToggleMode
   */
  const handleToggleMode = () => {
    if (mode === 'rose') {
      setMode('rocket');
    } else if(mode === 'rocket') {
      setMode('rose');
    }

    if (pointerControls.current !== null) {
      setTimeout(() => {
        pointerControls.current.unlock()
      }, 100)
    }
  };

  /**
   * returnHome
   */
  const returnHome = () => {
    // do this first or throws error, because pointerControls are removed
    // todo might need to fix this
    // if (pointerControls.current !== null) {
    //   setTimeout(() => {
    //     pointerControls.current.unlock()
    //   }, 100)
    // }
    // setMode("");

    // easier to just redirect like this, because otherwise, have to try and clear url params (if ?rose=1, and then lots of variables are still set)
    window.location.replace(window.location.origin);
  };

  /**
   * handleToggleGraphics
   */
  const handleToggleGraphics = () => {
    setLowGraphics(!lowGraphics);

    if (pointerControls.current !== null) {
      setTimeout(() => {
        pointerControls.current.unlock()
      }, 100)
    }
  };

  /**
   * handleGuideButton
   * @param event
   */
  const handleGuideButton = (event: any) => {
    event.preventDefault(); // todo is this needed?
    setTimeout(() => {pointerControls.current.unlock()},100);
    setShowInfoModal(true);
  }

  return (
    <Box component="div" sx={{ flexGrow: 1 }} className={`navBar ${mode == "" ? 'navBar--black' : ''}`} >
      <AppBar position="fixed" color="transparent">
        <Toolbar>
          <Typography className="main-title" variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={(event:any) => returnHome()}>
            {process.env.REACT_APP_NAV_TITLE}
          </Typography>

          {/*<TextField*/}
          {/*  defaultValue={objectCount}*/}
          {/*  inputProps={{ maxLength: 4 }}*/}
          {/*  onChange={handleChangObjectCount}*/}
          {/*  onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}*/}
          {/*/>*/}
          {/*<SnowSwitch pointerControls={pointerControls} snow={snow} setSnow={setSnow} />*/}

          <div className='infoModal__desktop-content'>
            { mode != "" && (
              <>
                {mode === 'rose' && DEBUG_MODE && (
                  <Button style={{marginRight:'5px'}} onClick={handleToggleMode} variant="contained" color="primary" className="lowGraphicsButton"><RocketLaunch/></Button>
                )}

                {mode === 'rocket' && DEBUG_MODE && (
                  <Button style={{marginRight:'5px'}} onClick={handleToggleMode} variant="contained" color="primary" className="lowGraphicsButton"><FilterVintage/></Button>
                )}
              </>
            )}

            { lowGraphics && (mode != "") && (
              <Button onClick={handleToggleGraphics} variant="contained" color="success" className="lowGraphicsButton"><LocalFlorist /></Button>
            )}

            { !lowGraphics && (mode != "") && (
              <Button onClick={handleToggleGraphics} variant="outlined" color="success" className="lowGraphicsButton"><LocalFlorist /></Button>
            )}
          </div>

          {/* TODO PREVENT USER TABBING ONTO THESE BUTTONS, OTHERWISE PRESSING SPACE WILL KEEP OPENING DIALOG */}

          { (mode != "") && (
            <Button color="inherit" variant="text" className='infoModal__desktop-content'
                    onClick={handleGuideButton}>
              { mode === 'rose' && (
                <img src={imgs.lion} style={{width: "30px", height: "30px", marginTop: "9px"}} />
              )}
              { mode === 'rocket' && (
                <img src={imgs.lionDark} style={{width: "30px", height: "30px", marginTop: "9px"}} />
              )}

            </Button>
          )}

          {/*<NightTimeSwitch  pointerControls={pointerControls} night={night} setNight={setNight} />*/}

          <Button className="extra-box-shadow" style={{marginLeft: '5px'}} color="inherit" variant="outlined" href={`${process.env.REACT_APP_ARTICLE_LINK}`} target="_blank"><Description/>&nbsp;Why Web4</Button>
          <Button style={{marginLeft: '5px'}} variant='contained' color='info' target="_blank" href={`https://twitter.com/${process.env.REACT_APP_TWITTER}`}><Twitter/></Button>

          <div className='infoModal__desktop-content'>
            <Web3ConnectionButtons showInfoModal={showInfoModal} showNFTModal={showNFTModal} setModalOpen={setModalOpen} showModal={showModal} modalOpen={modalOpen} className='connect-button' pointerControls={pointerControls} />
          </div>
          <div className='infoModal__mobile-content'>
            <Web3ConnectionButtons mobile={true} showInfoModal={showInfoModal} showNFTModal={showNFTModal} setModalOpen={setModalOpen} showModal={showModal} modalOpen={modalOpen} className='connect-button' pointerControls={pointerControls} />
          </div>

        </Toolbar>
      </AppBar>
    </Box>
  )
}
