import {Loader, Stars} from "@react-three/drei";
import React, {Suspense, useState, useEffect} from "react";
import {XR, XRButton} from "@react-three/xr";
import { Physics } from "@react-three/rapier"
import { Canvas } from "@react-three/fiber"
import { PointerLockControls, KeyboardControls } from "@react-three/drei"
import { Player } from "../Player"
import { TreeLine } from "../RegularVersion/TreeLine"
import Globe from "./Globe";
import { Fire } from "./Fire";
import Parakeet from "./Parakeet";
import ObjectHighlight from "./ObjectHighlight";
import CommunityHighlight from "./CommunityHighlight";
import {SecretPath} from "./SecretPath";
import {InfoOutlined} from "@mui/icons-material";
import {Rainbow} from "./Rainbow";
import Cloud1 from "./Cloud1";
import Logos from "../logos/Logos";
import { MovingSun } from "./MovingSun";
const initialHelperText = '<- PRESS [ESC] and [Click] for Info';

export function _SpaceBase(
  { searchedRocketScale, launch, DEBUG_MODE, mode, highlightedCommunity, signArray, day, setDay, weatherData, allDone,
    objectData, setShowCreditsModal, searchedObject, snow, lockKeyboard, setLockKeyboard, hintMessage,
    setShowHintBox, setHintMessage, setSlideData, setSlideModalCharacter, setShowSlideModal, forestColliders,
    roseSizings, rocketSizings, setSlideMessage, setShowSizeModal, playerPosition, setPlayerPosition, setNight, night,
    donationAmount, objectColor, placedObject, setPlacedObject, placing, setPlacing, objectCount, setObjectCount,
    pointerControls, setShowInfoModal, setHasObject, hasObject, lowGraphics, setLowGraphics, pageNumber, setPageNumber,
    ...props}: any)
{

  const [helperText, setHelperText] = useState(initialHelperText);

  const handleClickCredits = (event: any) => {
    event.preventDefault(); // todo is this needed?
    setTimeout(() => {pointerControls.current.unlock()},100);
    setShowCreditsModal(true);
  }

  const [sunRise, setSunRise] = useState(false);
  const [sunSet, setSunSet] = useState(false);

  // todo is it better to track weatherData in useEffect here too? or pass straight into component? with hooks, it wouldn't even be tracked here

  return (
    <>
      <div className={`buttons-container buttons-container--left-helper`}>
        <InfoOutlined className="pointer" style={{ color: "white", margin: "0 4px" }} onClick={handleClickCredits}/>
        <p onClick={handleClickCredits} className='helperText'>{helperText}</p>
      </div>

      <Loader />
      <KeyboardControls
        map={[
          { name: "forward", keys: ["ArrowUp", "w", "W"] },
          { name: "backward", keys: ["ArrowDown", "s", "S"] },
          { name: "left", keys: ["ArrowLeft", "a", "A"] },
          { name: "right", keys: ["ArrowRight", "d", "D"] },
          { name: "action", keys: ["e", "E"] },
          { name: "place", keys: ["p", "P"] },
          { name: "info", keys: ["i", "I"] },
          { name: "jump", keys: ["Space"] },
          { name: "shift", keys: ["Shift"] },
          { name: "control", keys: ["Control"] },
          { name: "debug", keys: ["/"] },
        ]}>

        <XRButton
          className='xr-button'
          mode={'VR'}
          enterOnly={false}
          exitOnly={false}
        >
          Enter VR
        </XRButton>

        {/* what does this do on canvas? pixelRatio={window.devicePixelRatio}*/}
        <Canvas shadows camera={{ fov: 45 }}>
          <XR>
            {/*<Hands />*/}

            {night && (
              <color attach="background" args={['black']} />
            )}
            {day && (
              <color attach="background" args={['white']} />
            )}
            {sunSet && (
              <color attach="background" args={['purple']} />
            )}
            {sunRise && (
              <color attach="background" args={['#FFE1C7']} />
            )}

            <pointLight castShadow intensity={0.8} position={[100, 100, 100]} />

            <PointerLockControls ref={pointerControls} />
            <Physics gravity={[0, -30, 0]}>
              <Suspense>

                {/*todo fireflies? see sparkes component */}

                <Player
                  DEBUG_MODE={DEBUG_MODE} mode={mode} allDone={allDone} setShowCreditsModal={setShowCreditsModal}
                  objectData={objectData} searchedObject={searchedObject} lockKeyboard={lockKeyboard}
                  setLockKeyboard={setLockKeyboard} hintMessage={hintMessage} setHintMessage={setHintMessage}
                  setSlideModalCharacter={setSlideModalCharacter} setObjectCount={setObjectCount}
                  setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage} playerPosition={playerPosition}
                  setPlayerPosition={setPlayerPosition} pointerControls={pointerControls}
                  setShowInfoModal={setShowInfoModal} setPlacedObject={setPlacedObject} placedObject={placedObject}
                  setHasObject={setHasObject} hasObject={hasObject} pageNumber={pageNumber}
                  setPageNumber={setPageNumber} placing={placing} setPlacing={setPlacing}
                />

                { !night && !lowGraphics && (
                  <Parakeet
                    pos={[-10,6,6]} setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage}
                    pointerControls={pointerControls} playerPosition={playerPosition}
                    setSlideModalCharacter={setSlideModalCharacter}
                  />
                )}

                {/*{ snow && (<Market position={[10,-0.5,16]} rotation={[0,-0.5,0]} scale={1} />)}*/}
                <Fire
                  sunRise={sunRise} sunSet={sunSet} setSunSet={setSunSet} setSunRise={setSunRise} day={day}
                  setDay={setDay} setNight={setNight} playerPosition={playerPosition} night={night}
                  position={[-10,-0.5,7]} scale={0.3}
                />

                { searchedObject != "" && searchedObject != null && (
                  <>
                    { !launch && (
                      <ObjectHighlight
                        lockKeyboard={lockKeyboard} setSlideModalCharacter={setSlideModalCharacter}
                        setSlideData={setSlideData} setShowSlideModal={setShowSlideModal}
                        setSlideMessage={setSlideMessage} pointerControls={pointerControls}
                        playerPosition={playerPosition} searchedObject={searchedObject}
                      />
                    )}
                  </>
                )}

                {/*{ highlightedCommunity != "" && highlightedCommunity != null && (*/}
                {/*  <CommunityHighlight*/}
                {/*    lockKeyboard={lockKeyboard} setSlideModalCharacter={setSlideModalCharacter}*/}
                {/*    setSlideData={setSlideData} setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage}*/}
                {/*    pointerControls={pointerControls} playerPosition={playerPosition}*/}
                {/*    highlightedCommunity={highlightedCommunity}*/}
                {/*  />*/}
                {/*)}*/}

                {/*<Labels objectData={objectData} />*/}
                {/*<Logos*/}
                {/*  signArray={signArray} lockKeyboard={lockKeyboard} setSlideMessage={setSlideMessage}*/}
                {/*  setShowSlideModal={setShowSlideModal} setSlideModalCharacter={setSlideModalCharacter}*/}
                {/*  pointerControls={pointerControls} playerPosition={playerPosition}*/}
                {/*  setPlayerPosition={setPlayerPosition}*/}
                {/*/>*/}

                {/* ### Global*/}
                <MovingSun
                  weatherData={weatherData} sunRise={sunRise} setSunRise={setSunRise} sunSet={sunSet}
                  setSunSet={setSunSet} day={day} setDay={setDay}
                />

                { day && (
                  <>
                    <Rainbow weatherData={weatherData} />
                    {/*adding just one big cloud looks like mist*/}
                  </>
                )}

                {/* may want to add the clouds back to lowgraphics mode*/}
                {/*{ !lowGraphics && (*/}
                  <Cloud1 lowGraphics={lowGraphics} weatherData={weatherData} />
                {/*)}*/}

                { (DEBUG_MODE) && (
                  <>
                    <SecretPath>
                    </SecretPath>
                  </>
                )}

                {night && (
                  <>
                    {/* todo fix stars blur out when looking at them if too big... (and shining through mountain)*/}
                    <Stars
                      radius={450} // Radius of the inner sphere (default=100)
                      depth={50} // Depth of area where stars should fit (default=50)
                      count={5000} // Amount of stars (default=5000)
                      factor={20} // Size factor (default=4)
                      saturation={0} // Saturation 0-1 (default=0)
                      fade // Faded dots (default=false)
                    />
                  </>
                )}

                <Globe name="The Moon" weatherData={weatherData} night={night} sunRise={sunRise} sunSet={sunSet}
                       size={[20, 24, 24]} color="white"/>

                {/*this is all the components from extenders*/}
                {props.children}
                {/*this is all the components from extenders*/}
              </Suspense>
            </Physics>
          </XR>
        </Canvas>
      </KeyboardControls>
    </>
  )
}










