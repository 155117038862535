import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {getRocketSize} from "../../../helpers/helperFunctions";
import {useWeb3React} from "@web3-react/core";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--white-transformed.glb`;

type GLTFResult = GLTF & {
  nodes: {
    Rocket_1: THREE.Mesh
    Rocket_2: THREE.Mesh
  }
  materials: {
    BodyColor: THREE.MeshPhysicalMaterial
  }
}

export default function PlacedRocket({ placedObject, objectColor, rocketSizings, donationAmount }: any) {
  const { active, account, library, chainId } = useWeb3React();
  const group = useRef<THREE.Group>(null)
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  const [placedObjectColor, setPlacedObjectColor] = React.useState(objectColor);
  const [localScale, setLocalScale] = React.useState(1);

  React.useEffect(() => {
    if (objectColor === 'brown') {
      setPlacedObjectColor('#4d3210');
    } else {
      setPlacedObjectColor(objectColor);
    }
  }, [objectColor]);

  React.useEffect(() => {
    const rocketRize = getRocketSize(chainId, donationAmount, rocketSizings);
    setLocalScale(rocketRize);
  }, [donationAmount]);

  // console.log(`placed rose: ${JSON.stringify(placedObject)}`)
  return (
    <group ref={group} dispose={null} position={[placedObject.x, -0.5, placedObject.z]} scale={localScale}>
      <mesh geometry={nodes.Rocket_1.geometry} material={nodes.Rocket_1.material} >
        <meshStandardMaterial color={'white'} />
      </mesh>
      <mesh geometry={nodes.Rocket_2.geometry} >
        <meshStandardMaterial color={placedObjectColor} />
      </mesh>
      <mesh>
        <cylinderGeometry attach="geometry" args={[0.01, 0.01, 100, 32]}>
          <meshBasicMaterial
            attach="material"
            opacity={0.5}
          />
        </cylinderGeometry>
      </mesh>

      {/*<mesh geometry={nodes.Rocket_1.geometry} material={materials.BodyColor} />*/}
      {/*<mesh geometry={nodes.Rocket_2.geometry} material={nodes.Rocket_1.material} />*/}
    </group>
  )
}
