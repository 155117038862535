import React, { useRef } from 'react'
import { RigidBody } from '@react-three/rapier';


export function SecretPath({ rotation, position, scale }: any) {
  return (
    <group rotation={rotation} position={[-41,3,1]}>
      {/*<RigidBody type="fixed" colliders="cuboid" scale={[scale[0], 1, scale[2]]}>*/}
      <RigidBody type="fixed" colliders="cuboid" >
        <mesh>
          <boxGeometry args={[2, 0.3, 2]} />
          <meshPhongMaterial color="#ffffff" opacity={0.02} transparent />
          {/*<meshPhongMaterial color="#ff0000" />*/}
        </mesh>
      </RigidBody>
      {[...Array(20)].map((x, i) => (
        <RigidBody type="fixed" colliders="cuboid" key={`step-${i}`}>
          <mesh position={[0,i+1,-i*2-2]}>
            <boxGeometry args={[2, 0.3, 2]} />
            <meshPhongMaterial color="#ffffff" opacity={0.02} transparent />
            {/*<meshPhongMaterial color="#ff0000" />*/}
          </mesh>
        </RigidBody>
      ))}
    </group>
  )
}

