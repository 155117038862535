import React, { useRef, useEffect, useState } from 'react'

export function Rainbow({weatherData}: any) {

  const ref = useRef(null);
  const [rainbow, setRainbow] = useState(false);

  useEffect(() => {
    if(weatherData) {
      setRainbow(weatherData.weather.rainbow);
    }
  }, [weatherData])

  return (
    <>
      { rainbow && (
        <group ref={ref} position={[200, 0, -180]} rotation={[0,-0.6,0]}>
          {/*<mesh visible position={[0, 0, 0]} castShadow ref={ref}>*/}
          {/*  <torusGeometry args={[10, 3, 16, 100]} />*/}
          {/*  <meshStandardMaterial attach="material" color="lightblue" />*/}
          {/*</mesh>*/}
          <mesh visible >
            <torusGeometry args={[150, 1, 10, 100]} />
            <meshPhongMaterial attach="material" color="red" opacity={0.3} transparent />
          </mesh>
          <mesh visible>
            <torusGeometry args={[152, 1, 10, 100]} />
            <meshPhongMaterial attach="material" color="orange" opacity={0.3} transparent />
          </mesh>
          <mesh visible>
            <torusGeometry args={[154, 1, 10, 100]} />
            <meshPhongMaterial attach="material" color="yellow" opacity={0.3} transparent />
          </mesh>
          <mesh visible>
            <torusGeometry args={[156, 1, 10, 100]} />
            <meshPhongMaterial attach="material" color="green" opacity={0.3} transparent />
          </mesh>
          <mesh visible>
            <torusGeometry args={[158, 1, 10, 100]} />
            <meshPhongMaterial attach="material" color="blue" opacity={0.3} transparent />
          </mesh>
          <mesh visible>
            <torusGeometry args={[160, 1, 10, 100]} />
            <meshPhongMaterial attach="material" color="indigo" opacity={0.3} transparent />
          </mesh>
          <mesh visible>
            <torusGeometry args={[162, 1, 10, 100]} />
            <meshPhongMaterial attach="material" color="violet" opacity={0.3} transparent />
          </mesh>
        </group>
      )}
    </>
  );
}
