import * as THREE from 'three'
import React, { useRef } from 'react'
import {useGLTF, useKeyboardControls} from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { RigidBody } from "@react-three/rapier"
import {useFrame} from "@react-three/fiber";
import {interactivePlayerPosition} from "../../../helpers/helperFunctions";

type GLTFResult = GLTF & {
  nodes: {
    Sign: THREE.Mesh
    Logo: THREE.Mesh
  }
  materials: {
    Sign: THREE.MeshStandardMaterial
    Logo: THREE.MeshStandardMaterial
  }
}

/**
 * SignBase
 * @param nodes
 * @param materials
 * @param lockKeyboard
 * @param setShowSlideModal
 * @param setSlideMessage
 * @param setSlideModalCharacter
 * @param playerPosition
 * @param setPlayerPosition
 * @param position
 * @param scale
 * @param pointerControls
 * @param rotation
 * @constructor
 */
export function SignBase(
  { nodes, materials, lockKeyboard, setShowSlideModal, setSlideMessage, setSlideModalCharacter,
    playerPosition, setPlayerPosition, position, scale, pointerControls, rotation}: any)
{
  const group = useRef<THREE.Group>(null!)
  const myPosition = {x: position[0], y: position[1], z:position[2]}
  // console.log(myPosition)
  const [, get] = useKeyboardControls();
  const [interacting, setInteracting] = React.useState(false);

  useFrame((state) => {
    const { action } = get();
    if (action && !interacting && !lockKeyboard) {

      if (interactivePlayerPosition(playerPosition, myPosition, scale, 2)) {
        setInteracting(true);
        // console.log('Sign Interaction');
        setSlideModalCharacter('sign');
        setSlideMessage('unegma');
        setShowSlideModal(true);
        setTimeout(() => {
          pointerControls.current.unlock();
        }, 100);
        setTimeout(() => {
          setInteracting(false);
        }, 100);
      }
    }
  });

  return (
      <group ref={group} position={position} scale={scale} dispose={null} rotation={rotation}>
        <RigidBody type="fixed" colliders="cuboid">
          <mesh castShadow receiveShadow geometry={nodes.Sign.geometry} material={materials.Sign} />
          <mesh castShadow receiveShadow geometry={nodes.Logo.geometry} material={materials.Logo} position={[0.06, 2.4, 0.45]} rotation={[1.57, 0.05, 0]} scale={[1.35, 0.58, 0.58]} />
        </RigidBody>
      </group>
  )
}

/**
 * SignOne
 *
 * @param lockKeyboard
 * @param setShowSlideModal
 * @param setSlideMessage
 * @param setSlideModalCharacter
 * @param playerPosition
 * @param setPlayerPosition
 * @param position
 * @param scale
 * @param pointerControls
 * @param rotation
 * @constructor
 */
export function SignOne({ lockKeyboard, setShowSlideModal, setSlideMessage, setSlideModalCharacter, playerPosition, setPlayerPosition, position, scale, pointerControls, rotation}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/Sign-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <SignBase nodes={nodes} materials={materials} lockKeyboard={lockKeyboard} setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage} setSlideModalCharacter={setSlideModalCharacter} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition} position={position} scale={scale} pointerControls={pointerControls} rotation={rotation} />
  )
}
