import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    Object_119: THREE.SkinnedMesh
    Object_121: THREE.SkinnedMesh
    _rootJoint: THREE.Bone
  }
  materials: {
    wings: THREE.MeshStandardMaterial
    body: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Armature|Take 001|BaseLayer'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export function Butterfly({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/regular-version/butterfly-transformed.glb`;

  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group)

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useFrame(() => {
    if (launch) {
      // setLocalScale(3);
      mixer.clipAction(animations[0], group.current).setDuration(2);
      group.current.position.y += 0.01;
    }
  })

  useEffect(() => {
    mixer.clipAction(animations[0], group.current).setDuration(10);
    mixer.clipAction(animations[0], group.current).play();
  }, [animations])

  useFrame((scene, delta) => {
    mixer?.update(delta)
  });

  return (
    <group ref={group} position={position} rotation={rotation} scale={scale}>
      <group name="Armature">
        <group name="Object_5">
          <primitive object={nodes._rootJoint} />
          <group name="Object_118" rotation={[-Math.PI / 2, 0, 0]} />
          <group name="Object_120" position={[0, 0.58, -1.13]} rotation={[-Math.PI / 2, 0, 0]} />
          <skinnedMesh name="Object_119" geometry={nodes.Object_119.geometry} material={materials.wings} skeleton={nodes.Object_119.skeleton} />
          <skinnedMesh name="Object_121" geometry={nodes.Object_121.geometry} material={materials.body} skeleton={nodes.Object_121.skeleton} />
        </group>
      </group>
      <group name="Body" position={[0, 0.58, -1.13]} rotation={[-Math.PI / 2, 0, 0]} />
      <group name="Wings" rotation={[-Math.PI / 2, 0, 0]} />
    </group>
  )
}
