/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import {useGLTF, useAnimations, useKeyboardControls} from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";
import {interactivePlayerPosition} from "../../helpers/helperFunctions";
import {RigidBody} from "@react-three/rapier";
import {AnimationAction} from "three";

type GLTFResult = GLTF & {
  nodes: {
    Object_18: THREE.SkinnedMesh
    Object_19: THREE.SkinnedMesh
    Object_20: THREE.SkinnedMesh
    Object_21: THREE.SkinnedMesh
    Object_22: THREE.SkinnedMesh
    Object_23: THREE.SkinnedMesh
    _rootJoint: THREE.Bone
  }
  materials: {
    Default_Material: THREE.MeshStandardMaterial
  }
}

type ActionName = 'going to sleep' | 'run cycle' | 'sleeping' | 'waking up' | 'walk cycle' | 'sitted' | 'sitting' | 'standing up' | 'call' | 'roar' | 'stand'
interface actionLengthMap {
  [name: string]: number
}
// times assuming 30fps (may need to reduce 'length' so speed changes)
const actionLengths: actionLengthMap = {
  'sitted': 1483, // 89 frames // 1483 for 60fps
  'sitting': 667, // 40 frames // 667 for 60fps
  'stand': 1483, // 89 frames // 1483 for 60fps
  'standing up': 667 // 40 frames // 667 for 60fps
}
type GLTFActions = Record<ActionName, THREE.AnimationAction>

/**
 * LionBase
 * @param nodes
 * @param materials
 * @param mode
 * @param lockKeyboard
 * @param setHintMessage
 * @param hintMessage
 * @param setShowHintBox
 * @param playerPosition
 * @param setPlayerPosition
 * @param scale
 * @param rotation
 * @param position
 * @param setShowInfoModal
 * @param pointerControls
 * @param setHasObject
 * @param hasObject
 * @param animations
 * @param group
 * @param night
 * @constructor
 */
function LionBase(
  { nodes, materials, mode, lockKeyboard, setHintMessage, hintMessage, setShowHintBox, playerPosition, animations, group,
    setPlayerPosition, scale, rotation, position, setShowInfoModal, pointerControls, setHasObject, hasObject, night }: any) {
  const ref = useRef<any>(null)
  const myPosition = {x: position[0], y: position[1], z:position[2]}
  const [, get] = useKeyboardControls();
  const [interacting, setInteracting] = React.useState(false);

  // const onClick = useCallback((e: any) => { // todo what does this do?
  // const onClick = (e: any) => {
  //   console.log(playerPosition, myPosition)
  //
  //   if (interactivePlayerPosition(playerPosition)) {
  //     e.stopPropagation()
  //     setShowInfoModal(true);
  //
  //     setTimeout(() => {
  //       pointerControls.current.unlock();
  //     }, 100);
  //   }
  // }
  const [activeAnimation, setActiveAnimation] = useState<ActionName>('sitted');

  useEffect(() => {
    if (!night) {
      setActiveAnimation('sitted')
    } else {
      setActiveAnimation('sleeping')
    }
  }, []);
  // const [ animationInProgress, setAnimationInProgress] = useState(false);

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())
  // @ts-ignore
  const { actions }: ActionName = useAnimations<GLTFActions>(animations, group)

  // console.log(actions)

  //
  // const handleAnimationChange = (newAnimation: ActionName, loop = true, nextAnimation: ActionName = 'sitted') => {
  //   console.log(`changing animation to ${newAnimation}`)
  //   // setActiveAnimation(newAnimation);
  //   mixer.stopAllAction();
  //   const action: AnimationAction = actions[newAnimation];
  //   action.reset();
  //   // action.fadeIn(0.5);
  //   // action.setDuration(actionLengths[newAnimation]);
  //   action.setDuration(3000);
  //   action.play();
  //
  //   if (!loop) {
  //     setTimeout(() => {
  //       action.stop();
  //       if (nextAnimation) {
  //         setActiveAnimation(nextAnimation);
  //       }
  //     }, actionLengths[newAnimation]+100) // todo add a bit of extra ????
  //   }
  // };

  // useEffect(() => void mixer.clipAction(animations[5], group.current).play(), [])

  // useEffect(() => {
  //
  // }, [animations])



  useEffect(() => {
    console.log(`handlingAnimation: ${activeAnimation}`)
    switch (activeAnimation) {
      case 'sitted':
        // setActiveAnimation('sitted');
        mixer.stopAllAction();
        mixer.clipAction(animations[5], group.current).reset();
        mixer.clipAction(animations[5], group.current).setDuration(animations[5].duration);
        mixer.clipAction(animations[5], group.current).play();
        break;
      case 'standing up':
        // mixer.clipAction(animations[7], group.current).setDuration(2);
        // mixer.clipAction(animations[7], group.current).setLoop(THREE.LoopOnce, 1);
        // mixer.clipAction(animations[7], group.current).play();
        // @ts-ignore
        // actions['standing up'].play();
        // setAnimationInProgress(true);
        // setTimeout(() => {
        //   setAnimationInProgress(false);
        //   setActiveAnimation('standing up')
        //   handleAnimationChange('standing up', false, 'stand')
        mixer.stopAllAction();
        mixer.clipAction(animations[7], group.current).reset();
        mixer.clipAction(animations[7], group.current).setDuration(animations[7].duration);
        mixer.clipAction(animations[7], group.current).play();
        setTimeout(() => {
          setActiveAnimation('stand');
        }, animations[7].duration);
        // }, actionLengths['standing up'])
        break;
      case 'sitting':
        // mixer.clipAction(animations[6], group.current).setDuration(2);
        // mixer.clipAction(animations[6], group.current).setLoop(THREE.LoopOnce, 1);
        // mixer.clipAction(animations[6], group.current).play();
        // @ts-ignore
        // actions['sitting'].play();
        // setAnimationInProgress(true);
        // setTimeout(() => {
          // setAnimationInProgress(false);
          // setActiveAnimation('sitting');
          // handleAnimationChange('sitting', false, 'sitted')
        mixer.stopAllAction();
        mixer.clipAction(animations[6], group.current).reset();
        mixer.clipAction(animations[6], group.current).setDuration(animations[6].duration);
        mixer.clipAction(animations[6], group.current).play();
        setTimeout(() => {
          setActiveAnimation('sitted');
        }, animations[6].duration);
        // }, actionLengths['sitting'])
        break;
      case 'going to sleep':
        // mixer.clipAction(animations[6], group.current).setDuration(2);
        // mixer.clipAction(animations[6], group.current).setLoop(THREE.LoopOnce, 1);
        // mixer.clipAction(animations[6], group.current).play();
        // @ts-ignore
        // actions['sitting'].play();
        // setAnimationInProgress(true);
        // setTimeout(() => {
        // setAnimationInProgress(false);
        // setActiveAnimation('sitting');
        // handleAnimationChange('sitting', false, 'sitted')
        mixer.stopAllAction();
        mixer.clipAction(animations[0], group.current).reset();
        mixer.clipAction(animations[0], group.current).setDuration(animations[0].duration);
        mixer.clipAction(animations[0], group.current).play();
        setTimeout(() => {
          setActiveAnimation('sleeping');
        }, animations[0].duration);
        // }, actionLengths['sitting'])
        break;
      case 'waking up':
        // mixer.clipAction(animations[6], group.current).setDuration(2);
        // mixer.clipAction(animations[6], group.current).setLoop(THREE.LoopOnce, 1);
        // mixer.clipAction(animations[6], group.current).play();
        // @ts-ignore
        // actions['sitting'].play();
        // setAnimationInProgress(true);
        // setTimeout(() => {
        // setAnimationInProgress(false);
        // setActiveAnimation('sitting');
        // handleAnimationChange('sitting', false, 'sitted')
        mixer.stopAllAction();
        mixer.clipAction(animations[3], group.current).reset();
        mixer.clipAction(animations[3], group.current).setDuration(animations[3].duration);
        mixer.clipAction(animations[3], group.current).play();
        setTimeout(() => {
          setActiveAnimation('stand');
        }, animations[3].duration);
        // }, actionLengths['sitting'])
        break;
      case 'stand':
        // mixer.clipAction(animations[5], group.current).setDuration(6);
        // mixer.clipAction(animations[5], group.current).play();
        // @ts-ignore
        // actions['stand'].play();
        // setActiveAnimation('stand');
        // handleAnimationChange('stand')
        mixer.stopAllAction();
        mixer.clipAction(animations[10], group.current).reset();
        mixer.clipAction(animations[10], group.current).setDuration(animations[10].duration);
        mixer.clipAction(animations[10], group.current).play();
        break;
      case 'sleeping':
        // mixer.clipAction(animations[5], group.current).setDuration(6);
        // mixer.clipAction(animations[5], group.current).play();
        // @ts-ignore
        // actions['stand'].play();
        // setActiveAnimation('stand');
        // handleAnimationChange('stand')
        mixer.stopAllAction();
        mixer.clipAction(animations[2], group.current).reset();
        mixer.clipAction(animations[2], group.current).setDuration(animations[2].duration);
        mixer.clipAction(animations[2], group.current).play();
        break;
    }
  }, [animations, activeAnimation])

  useFrame((scene, delta) => {
    mixer?.update(delta)
    // console.log(activeAnimation)
  });

  useFrame((state) => {
    const { action } = get();

    // // todo combine this with the below
    if (interactivePlayerPosition(playerPosition, myPosition, scale, 7)) {
      if (activeAnimation !== 'stand' && activeAnimation !== 'standing up' && activeAnimation !== 'waking up') {
        if (night) {
          console.log('setting to waking')
          setActiveAnimation('waking up');
        } else {
          console.log('setting to standing')
          setActiveAnimation('standing up');
        }
      }
    } else {
      if (activeAnimation !== 'sitted' && activeAnimation !== 'sitting' && activeAnimation !== 'sleeping' && activeAnimation !== 'going to sleep') {
        if (night) {
          console.log('setting to sleeping')
          setActiveAnimation('going to sleep');
        } else {
          console.log('setting to sitting')
          setActiveAnimation('sitting');
        }
      }
    }

    if (action && !interacting && !lockKeyboard) {

      if (interactivePlayerPosition(playerPosition, myPosition, scale, 2)) {
        setInteracting(true);
        // console.log('Lion Interaction');

        if (hintMessage === 'step-1') {
          setHintMessage('step-2');
        }
        // hide hint when user speaks to wolf for the first time
        if (hintMessage === 'step-3') {
          setShowHintBox(false);
        }

        setShowInfoModal(true);
        setTimeout(() => {
          pointerControls.current.unlock();
        }, 100);
        setTimeout(() => {
          setInteracting(false);
        }, 100);
      }
    }
  });
  // return (
  //   <group ref={group} dispose={null} position={position}>
  //     <RigidBody type="fixed" colliders="cuboid" ref={ref} scale={scale} rotation={rotation}>
  //       <mesh castShadow receiveShadow geometry={nodes.wolf.geometry} material={materials.wolf} rotation={[-Math.PI / 2, 0, 0]} />
  //     </RigidBody>
  //   </group>
  // )


  return (
    <group ref={group} dispose={null} position={position} castShadow receiveShadow>
      <RigidBody type="fixed" colliders="cuboid" ref={ref} scale={scale} rotation={rotation}>

        <group name="Scene" castShadow receiveShadow>
          <group name="Lion">
            <group name="ambientLight1">
              <group name="Object_5" rotation={[Math.PI / 2, 0, 0]}>
                <group name="Object_6" />
              </group>
            </group>
            <group name="Lion_Geo01">
              <group name="body01" />
              <group name="jaws01" />
              <group name="mane_1_01" />
              <group name="mane_2_01" />
              <group name="mane_3_01" />
              <group name="whiskers01" />
            </group>
            <group name="Master_ctrl01">
              <group name="arm_l_g_ctrl01">
                <group name="arm_l_ctrl01" position={[17.23, 0, 45.29]}>
                  <group name="ankle_l_carpal_pivot01" position={[0, 25.63, 1.38]}>
                    <group name="finger_l_tip_pivot01" position={[0.37, -25.63, 24.78]}>
                      <group name="swivel_l_hand_pivot01" position={[-0.37, 9.89, -22.09]} rotation={[0, 0.17, 0]}>
                        <group name="finger_l_tap_pivot01">
                          <group name="ik_l_carpal01" />
                          <group name="ik_l_fingers01" position={[0, -4.21, 8.12]} />
                        </group>
                        <group name="heel_l_hand_peel_pivot01">
                          <group name="ik_humerus_l_radius01" position={[0, 15.74, -2.69]} />
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="arm_l_g_PoleV01" position={[-24.23, 0, -66.12]}>
                    <group name="arm_l_PoleV01" position={[24.23, 27.16, -140]} />
                  </group>
                </group>
              </group>
              <group name="arm_r_g_ctrl01">
                <group name="arm_r_ctrl01" position={[-17.23, 0, 66.12]}>
                  <group name="ankle_r_carpal_pivot01" position={[0, 25.63, 1.38]}>
                    <group name="finger_r_tip_pivot01" position={[-0.37, -25.63, 24.78]}>
                      <group name="swivel_r_hand_pivot01" position={[0.37, 9.89, -22.09]} rotation={[0, -0.17, 0]}>
                        <group name="finger_r_tap_pivot01">
                          <group name="ik_r_carpal01" />
                          <group name="ik_r_fingers01" position={[0, -4.21, 8.12]} />
                        </group>
                        <group name="heel_r_hand_peel_pivot01">
                          <group name="ik_humerus_r_radius01" position={[0, 15.74, -2.69]} />
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="arm_r_g_PoleV01" position={[24.23, 0, -66.12]}>
                    <group name="arm_r_PoleV01" position={[-24.23, 27.16, -140]} />
                  </group>
                </group>
              </group>
              <group name="joints01">
                <group name="Object_16">
                  <primitive object={nodes._rootJoint} />
                  <skinnedMesh castShadow receiveShadow name="Object_18" geometry={nodes.Object_18.geometry} material={materials.Default_Material} skeleton={nodes.Object_18.skeleton} />
                  <skinnedMesh castShadow receiveShadow name="Object_19" geometry={nodes.Object_19.geometry} material={materials.Default_Material} skeleton={nodes.Object_19.skeleton} />
                  <skinnedMesh castShadow receiveShadow name="Object_20" geometry={nodes.Object_20.geometry} material={materials.Default_Material} skeleton={nodes.Object_20.skeleton} />
                  <skinnedMesh castShadow receiveShadow name="Object_21" geometry={nodes.Object_21.geometry} material={materials.Default_Material} skeleton={nodes.Object_21.skeleton} />
                  <skinnedMesh castShadow receiveShadow name="Object_22" geometry={nodes.Object_22.geometry} material={materials.Default_Material} skeleton={nodes.Object_22.skeleton} />
                  <skinnedMesh castShadow receiveShadow name="Object_23" geometry={nodes.Object_23.geometry} material={materials.Default_Material} skeleton={nodes.Object_23.skeleton} />
                </group>
              </group>
              <group name="leg_l_g_ctrl01">
                <group name="leg_l_ctrl01" position={[17.23, 0, -61.95]}>
                  <group name="ankle_l_tarsus_pivot01" position={[0, 42.47, 0]}>
                    <group name="toe_l_tip_pivot01" position={[0.33, -42.47, 26.85]}>
                      <group name="swivel_l_foot_pivot01" position={[-0.33, 9.15, -21.33]} rotation={[0, 0.26, 0]}>
                        <group name="heel_l_foot_peel_pivot01" rotation={[-0.24, 0, 0]}>
                          <group name="ik_femur_l_tibia01" position={[0, 33.32, -5.52]} />
                        </group>
                        <group name="toe_l_tap_pivot01">
                          <group name="ik_l_tarsus01" />
                          <group name="ik_l_toes01" position={[0, -2.76, 7.16]} />
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="leg_l_g_PoleV01" position={[-24.23, 0, 96.87]}>
                    <group name="leg_l_PoleV01" position={[24.23, 27.16, 140]} />
                  </group>
                </group>
              </group>
              <group name="leg_r_g_ctrl01">
                <group name="leg_r_ctrl01" position={[-17.23, 0, -108.87]}>
                  <group name="ankle_r_tarsus_pivot01" position={[0, 42.47, 0]}>
                    <group name="toe_r_tip_pivot01" position={[-0.33, -42.47, 26.85]}>
                      <group name="swivel_r_foot_pivot01" position={[0.33, 9.15, -21.33]} rotation={[0, -0.26, 0]}>
                        <group name="heel_r_foot_peel_pivot01" rotation={[0.25, 0, 0]}>
                          <group name="ik_femur_r_tibia01" position={[0, 33.32, -5.52]} />
                        </group>
                        <group name="toe_r_tap_pivot01">
                          <group name="ik_r_tarsus01" />
                          <group name="ik_r_toes01" position={[0, -2.76, 7.16]} />
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="leg_r_g_PoleV01" position={[24.23, 0, 96.87]}>
                    <group name="leg_r_PoleV01" position={[-24.23, 27.16, 140]} />
                  </group>
                </group>
              </group>
              <group name="root_g_ctrl01">
                <group name="root_ctrl01" position={[0, 121.41, -72.89]}>
                  <group name="femur_l_g_ctrl01" position={[0, -121.41, 72.89]}>
                    <group name="femur_l_ctrl01" position={[24.23, 123.44, -10]} />
                  </group>
                  <group name="femur_r_g_ctrl01" position={[0, -121.41, 72.89]}>
                    <group name="femur_r_ctrl01" position={[-24.23, 123.44, -10]} />
                  </group>
                  <group name="spine_g_ctrl_a01" position={[0, -121.41, 72.89]}>
                    <group name="spine_ctrl_a01" position={[0, 121.41, -35.51]}>
                      <group name="spine_g_ctrl_b01" position={[0, -121.41, 35.51]}>
                        <group name="spine_ctrl_b01" position={[0, 121.41, 1.87]}>
                          <group name="spine_g_ctrl_c01" position={[0, -121.41, -1.87]}>
                            <group name="spine_ctrl_c01" position={[0, 121.41, 39.24]}>
                              <group name="shoulders_g_ctrl01" position={[0, -121.41, -39.24]}>
                                <group name="shoulders_ctrl01" position={[0, 121.41, 76.62]}>
                                  <group name="humerus_l_g_ctrl01" position={[0, -121.41, -76.62]}>
                                    <group name="humerus_l_ctrl01" position={[24.23, 16, 12]}>
                                      <group name="shoulderblade_l_g_ctrl01" position={[-24.23, -119.13, -81.86]}>
                                        <group name="shoulderblade_l_ctrl01" position={[19.37, 119.13, 81.86]} rotation={[-0.7, 0, 0]} />
                                      </group>
                                    </group>
                                  </group>
                                  <group name="humerus_r_g_ctrl01" position={[0, -121.41, -76.62]}>
                                    <group name="humerus_r_ctrl01" position={[-24.23, 16, 12]}>
                                      <group name="shoulderblade_r_g_ctrl01" position={[24.23, -119.13, -81.86]}>
                                        <group name="shoulderblade_r_ctrl01" position={[-19.37, 119.13, 81.86]} />
                                      </group>
                                    </group>
                                  </group>
                                  <group name="neck_g_ctrl01" position={[0, -121.41, -76.62]}>
                                    <group name="neck_ctrl01" position={[0, 0, 96.56]} rotation={[-0.17, 0, 0]}>
                                      <group name="head_g_ctrl01" position={[0, -139.99, -96.56]}>
                                        <group name="head_ctrl01" position={[0, 145.62, 120.68]} rotation={[0.44, 0, 0]}>
                                          <group name="ear_l_g_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="ear_l_ctrl01" position={[19.76, 165.26, 128.6]} />
                                          </group>
                                          <group name="ear_r_g_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="ear_r_ctrl01" position={[-19.76, 165.26, 128.6]} />
                                          </group>
                                          <group name="eyebrow_l_g_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="eyebrow_l_ctrl01" position={[10.34, 15, 158.49]} />
                                          </group>
                                          <group name="eyebrow_r_g_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="eyebrow_r_ctrl01" position={[-10.34, 15, 158.49]} />
                                          </group>
                                          <group name="eyelids_l_g_box01" position={[0, -145.62, -120.68]}>
                                            <group name="eyelids_l_box01" position={[21, 155.74, 156.49]}>
                                              <group name="eyelids_l_g_ctrl01" position={[-21, -155.74, -156.49]}>
                                                <group name="eyelids_l_ctrl01" position={[21, 1, 156.49]} />
                                              </group>
                                            </group>
                                          </group>
                                          <group name="eyelids_r_g_box01" position={[0, -145.62, -120.68]}>
                                            <group name="eyelids_r_box01" position={[-21, 155.74, 156.49]}>
                                              <group name="eyelids_r_g_ctrl01" position={[21, -155.74, -156.49]}>
                                                <group name="eyelids_r_ctrl01" position={[-21, 1, 156.49]} />
                                              </group>
                                            </group>
                                          </group>
                                          <group name="lip_l_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="lip_l_ctrl01001" position={[10.38, 23.64, 70]} />
                                          </group>
                                          <group name="lip_r_g_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="lip_r_ctrl01" position={[-10.38, 23.64, 70]} />
                                          </group>
                                          <group name="lowerjaw_g_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="lowerjaw_ctrl01" position={[0, 146.19, 134.25]} rotation={[-Math.PI / 6, 0, 0]}>
                                              <group name="low_l_lip_g_ctrl01" position={[0, -146.19, -134.25]}>
                                                <group name="low_l_lip_ctrl01" position={[5, 10, 12]} />
                                              </group>
                                              <group name="low_mid_lip_g_ctrl01" position={[0, -146.19, -134.25]}>
                                                <group name="low_mid_lip_ctrl01" position={[0, 8, 12]} />
                                              </group>
                                              <group name="low_r_lip_g_ctrl01" position={[0, -146.19, -134.25]}>
                                                <group name="low_r_lip_ctrl01" position={[-5, 10, 12]} />
                                              </group>
                                            </group>
                                          </group>
                                          <group name="nose_g_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="nose_ctrl01" position={[0, 5, 179.07]} />
                                          </group>
                                          <group name="whiskers_l_g_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="whiskers_l_ctrl01" position={[8.43, 20, 173.06]} />
                                          </group>
                                          <group name="whiskers_r_g_ctrl01" position={[0, -145.62, -120.68]}>
                                            <group name="whiskers_r_ctrl01" position={[-8.43, 20, 173.06]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="tail_g_ctrl_a01" position={[0, -121.41, 72.89]}>
                    <group name="tail_ctrl_a01" position={[0, 141.8, -92.11]} rotation={[-0.87, -0.03, -0.04]}>
                      <group name="tail_g_ctrl_b01" position={[0, -141.8, 92.11]}>
                        <group name="tail_ctrl_b01" position={[0, 140.43, -104.71]} rotation={[-0.09, -0.05, 0]}>
                          <group name="tail_g_ctrl_c01" position={[0, -140.43, 104.71]}>
                            <group name="tail_ctrl_c01" position={[0, 138.43, -117.38]} rotation={[-0.09, -0.05, 0]}>
                              <group name="tail_g_ctrl_d01" position={[0, -138.43, 117.38]}>
                                <group name="tail_ctrl_d01" position={[0, 136.23, -130.1]} rotation={[-0.09, -0.05, 0]}>
                                  <group name="tail_g_ctrl_e01" position={[0, -136.23, 130.1]}>
                                    <group name="tail_ctrl_e01" position={[0, 134.02, -142.87]} rotation={[-0.09, -0.05, 0]}>
                                      <group name="tail_g_ctrl_f01" position={[0, -134.02, 142.87]}>
                                        <group name="tail_ctrl_f01" position={[0, 131.87, -155.68]} rotation={[-0.09, -0.05, 0]}>
                                          <group name="tail_g_ctrl_g01" position={[0, -131.87, 155.68]}>
                                            <group name="tail_ctrl_g01" position={[0, 130.42, -168.53]} rotation={[0, -0.05, 0]}>
                                              <group name="tail_g_ctrl_h01" position={[0, -130.42, 168.53]}>
                                                <group name="tail_ctrl_h01" position={[0, 129.48, -181.39]} rotation={[0.31, -0.05, 0.02]}>
                                                  <group name="tail_g_ctrl_i01" position={[0, -129.48, 181.39]}>
                                                    <group name="tail_ctrl_i01" position={[0, 128.93, -194.27]} rotation={[0.31, -0.05, 0.02]}>
                                                      <group name="tail_g_ctrl_j01" position={[0, -128.93, 194.27]}>
                                                        <group name="tail_ctrl_j01" position={[0, 129.12, -207.15]} rotation={[0.31, -0.05, 0.02]}>
                                                          <group name="tail_g_ctrl_k01" position={[0, -129.12, 207.15]}>
                                                            <group name="tail_ctrl_k01" position={[0, 130.11, -220.03]} rotation={[0.31, -0.05, 0.02]}>
                                                              <group name="tail_g_ctrl_l01" position={[0, -130.11, 220.03]}>
                                                                <group name="tail_ctrl_l01" position={[0, 132.16, -232.89]} rotation={[0.31, -0.05, 0.02]} />
                                                              </group>
                                                            </group>
                                                          </group>
                                                        </group>
                                                      </group>
                                                    </group>
                                                  </group>
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </RigidBody>
    </group>
  )
}


/**
 * Mountain Lion
 * @param mode
 * @param lockKeyboard
 * @param setHintMessage
 * @param hintMessage
 * @param setShowHintBox
 * @param playerPosition
 * @param setPlayerPosition
 * @param scale
 * @param rotation
 * @param position
 * @param setShowInfoModal
 * @param pointerControls
 * @param setHasObject
 * @param hasObject
 * @param night
 * @constructor
 */
export function LionNew({ mode, lockKeyboard, setHintMessage, hintMessage, setShowHintBox, playerPosition, setPlayerPosition, scale, rotation, position, setShowInfoModal, pointerControls, setHasObject, hasObject, night }: any) {

  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/lion-transformed.glb`;

  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  // @ts-ignore

  return (
    <LionBase night={night}
      nodes={nodes} materials={materials} animations={animations} group={group}
      mode={mode} lockKeyboard={lockKeyboard} setHintMessage={setHintMessage} hintMessage={hintMessage}
      setShowHintBox={setShowHintBox} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}
      scale={scale} rotation={rotation} position={position} setShowInfoModal={setShowInfoModal}
      pointerControls={pointerControls} setHasObject={setHasObject} hasObject={hasObject}
    />
  )
}



/**
 * Mountain Lion
 * @param mode
 * @param lockKeyboard
 * @param setHintMessage
 * @param hintMessage
 * @param setShowHintBox
 * @param playerPosition
 * @param setPlayerPosition
 * @param scale
 * @param rotation
 * @param position
 * @param setShowInfoModal
 * @param pointerControls
 * @param setHasObject
 * @param hasObject
 * @param night
 * @constructor
 */
export function LionDark({ mode, lockKeyboard, setHintMessage, hintMessage, setShowHintBox, playerPosition, setPlayerPosition, scale, rotation, position, setShowInfoModal, pointerControls, setHasObject, hasObject, night }: any) {

  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/lion-dark-transformed.glb`;

  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  // @ts-ignore

  return (
    <LionBase night={night}
      nodes={nodes} materials={materials} animations={animations} group={group}
      mode={mode} lockKeyboard={lockKeyboard} setHintMessage={setHintMessage} hintMessage={hintMessage}
      setShowHintBox={setShowHintBox} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}
      scale={scale} rotation={rotation} position={position} setShowInfoModal={setShowInfoModal}
      pointerControls={pointerControls} setHasObject={setHasObject} hasObject={hasObject}
    />
  )
}
