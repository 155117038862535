import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {ReactThreeFiber} from "@react-three/fiber";
import {InstancedBufferAttribute, InstancedMesh, Object3D} from "three";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // TODO UPDATE TO ALLOW NULL ON TYPES
      instancedMesh: ReactThreeFiber.Object3DNode<InstancedMesh, typeof InstancedMesh>
      instancedBufferAttribute: ReactThreeFiber.Object3DNode<InstancedBufferAttribute, typeof InstancedBufferAttribute>
    }
  }
}

type GLTFResult = GLTF & {
  nodes: {
    ['03_Flower_1']: THREE.Mesh
  }
  materials: {
    Mat_3: THREE.MeshStandardMaterial
  }
}

export function TinyFlowersBase({ nodes, materials, spreadDistance, scale, count, rotation, geometryName, materialName, y }: any) {
  count = Math.round(count);
  // console.log('count', count)

  const ref = useRef(null!);
  const dummy = new Object3D();
  // @ts-ignore

  useEffect(() => {
    // @ts-ignore
    // geometry.computeVertexNormals();

    let allGrass: any = [];

    // create grass in quadrant 1
    for (let i = 1; i <= count; i++) {
      for (let j = 1; j <= count; j++) {
        allGrass.push({position: [i * Math.pow(Math.random() * spreadDistance, 2), j * Math.pow(Math.random() * spreadDistance,2)]});
      }
    }
    // create grass in quadrant 2
    for (let i = 1; i <= count; i++) {
      for (let j = 1; j <= count; j++) {
        allGrass.push({position: [-i * Math.pow(Math.random() * spreadDistance, 2), -j * Math.pow(Math.random() * spreadDistance,2)]});
      }
    }
    // create grass in quadrant 3
    for (let i = 1; i <= count; i++) {
      for (let j = 1; j <= count; j++) {
        allGrass.push({position: [-i * Math.pow(Math.random() * spreadDistance, 2), j * Math.pow(Math.random() * spreadDistance,2)]});
      }
    }
    // create grass in quadrant 4
    for (let i = 1; i <= count; i++) {
      for (let j = 1; j <= count; j++) {
        allGrass.push({position: [i * Math.pow(Math.random() * spreadDistance, 2), -j * Math.pow(Math.random() * spreadDistance,2)]});
      }
    }
    // TODO CHECK HOW MANY THERE ACTUALLY ARE
    // console.log('this many grasses', allGrass);

    // pow 2 because value comes in as a sqrt
    allGrass.forEach((grass: any, index: number) => {
      // dummy.position.set(Math.random()*10*i,0, Math.random()*10*i);
      dummy.position.set(grass.position[0], y, grass.position[1]);
      dummy.rotation.set(0, Math.random(), 0);
      // dummy.position.set(0, 2, 0);
      // dummy.scale.setScalar(1.5+Math.random()+Math.random());
      dummy.updateMatrix();
      // @ts-ignore
      ref.current.setMatrixAt(index, dummy.matrix);
    });
    // @ts-ignore
    ref.current.instanceMatrix.needsUpdate = true;
  }, [count]);  // todo may need to re-add geometry (and then move geometry into variable above)

  // Math.pow(count,2)*4], because there are 4 quadrants each squared
  return (
    <instancedMesh ref={ref} args={[nodes[geometryName].geometry, materials[materialName], Math.pow(count,2)*4]} scale={scale} rotation={rotation}>
    </instancedMesh>
  )
}

export function TinyFlowers1({ spreadDistance, scale, count, rotation }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/flower1-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <TinyFlowersBase y={-0.8} geometryName={'03_Flower_1'} materialName={'Mat_3'} nodes={nodes} materials={materials} spreadDistance={spreadDistance} scale={scale} count={count} rotation={rotation} />
  )
}

export function TinyFlowers2({ spreadDistance, scale, count, rotation }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/flower2-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <TinyFlowersBase y={0} geometryName={'03_Flower_2'} materialName={'Mat_3'} nodes={nodes} materials={materials} spreadDistance={spreadDistance} scale={scale} count={count} rotation={rotation} />
  )
}

export function TinyFlowers3({ spreadDistance, scale, count, rotation }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/flower3-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <TinyFlowersBase y={-0.8} geometryName={'03_Flower_3'} materialName={'Mat_3'} nodes={nodes} materials={materials} spreadDistance={spreadDistance} scale={scale} count={count} rotation={rotation} />
  )
}

