import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    Vox_LightedCandle03_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle02_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle01_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle00_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle21_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle20_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle23_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle19_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle18_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle17_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle16_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle15_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle14_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle13_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle12_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle11_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle10_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle22_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle09_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle08_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle07_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle06_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle05_Mat_Vox_Fire00_0: THREE.Mesh
    Vox_LightedCandle04_Mat_Vox_Fire00_0: THREE.Mesh
  }
  materials: {
    Mat_Vox_Fire00: THREE.MeshBasicMaterial
  }
}

type ActionName = 'To Burn Bright'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Candle({ launch, position, scale, rotation }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/candle-transformed.glb`;

  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group)

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useFrame(() => {
    if (launch) {
      // setLocalScale(3);
      group.current.position.y += 0.05; // change on candle too
    }
  })

  // todo fix this to make animations work on fire
  useEffect(() => {
    if (launch) {
      mixer.clipAction(animations[0], group.current).setDuration(0.2)
      mixer.clipAction(animations[0], group.current).play()
      // for (let i: number = 0; i < 24; i++) {
      //   // console.log(actions)
      //   // @ts-ignore
      //   // mixer.clipAction(animations[i], group.current).startAt(15);
      //   // @ts-ignore
      //   // mixer.clipAction(animations[i], group.current).setDuration();
      //   // @ts-ignore
      //   mixer.clipAction(animations[0].tracks[i], group.current).play()
      // }
    }
  }, [launch]);


  useFrame((scene, delta) => {
    mixer?.update(delta)
  });

  return (
    <group ref={group} position={position} scale={scale} rotation={rotation} dispose={null} name="Candle" >
      <group name="Object_102" scale={0}>
        <group name="frame_3" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode020">
            <group name="Vox_LightedCandle03">
              <mesh name="Vox_LightedCandle03_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle03_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_107" scale={0}>
        <group name="frame_2" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode021">
            <group name="Vox_LightedCandle02">
              <mesh name="Vox_LightedCandle02_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle02_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_112" scale={0}>
        <group name="frame_1" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode022">
            <group name="Vox_LightedCandle01">
              <mesh name="Vox_LightedCandle01_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle01_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_117">
        <group name="frame_0" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode023">
            <group name="Vox_LightedCandle00">
              <mesh name="Vox_LightedCandle00_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle00_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_12" scale={0}>
        <group name="frame_21" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode002">
            <group name="Vox_LightedCandle21">
              <mesh name="Vox_LightedCandle21_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle21_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_17" scale={0}>
        <group name="frame_20" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode003">
            <group name="Vox_LightedCandle20">
              <mesh name="Vox_LightedCandle20_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle20_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_2" scale={0}>
        <group name="frame_23" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode">
            <group name="Vox_LightedCandle23">
              <mesh name="Vox_LightedCandle23_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle23_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_22" scale={0}>
        <group name="frame_19" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode004">
            <group name="Vox_LightedCandle19">
              <mesh name="Vox_LightedCandle19_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle19_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_27" scale={0}>
        <group name="frame_18" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode005">
            <group name="Vox_LightedCandle18">
              <mesh name="Vox_LightedCandle18_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle18_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_32" scale={0}>
        <group name="frame_17" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode006">
            <group name="Vox_LightedCandle17">
              <mesh name="Vox_LightedCandle17_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle17_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_37" scale={0}>
        <group name="frame_16" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode007">
            <group name="Vox_LightedCandle16">
              <mesh name="Vox_LightedCandle16_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle16_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_42" scale={0}>
        <group name="frame_15" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode008">
            <group name="Vox_LightedCandle15">
              <mesh name="Vox_LightedCandle15_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle15_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_47" scale={0}>
        <group name="frame_14" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode009">
            <group name="Vox_LightedCandle14">
              <mesh name="Vox_LightedCandle14_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle14_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_52" scale={0}>
        <group name="frame_13" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode010">
            <group name="Vox_LightedCandle13">
              <mesh name="Vox_LightedCandle13_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle13_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_57" scale={0}>
        <group name="frame_12" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode011">
            <group name="Vox_LightedCandle12">
              <mesh name="Vox_LightedCandle12_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle12_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_62" scale={0}>
        <group name="frame_11" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode012">
            <group name="Vox_LightedCandle11">
              <mesh name="Vox_LightedCandle11_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle11_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_67" scale={0}>
        <group name="frame_10" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode013">
            <group name="Vox_LightedCandle10">
              <mesh name="Vox_LightedCandle10_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle10_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_7" scale={0}>
        <group name="frame_22" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode001">
            <group name="Vox_LightedCandle22">
              <mesh name="Vox_LightedCandle22_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle22_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_72" scale={0}>
        <group name="frame_9" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode014">
            <group name="Vox_LightedCandle09">
              <mesh name="Vox_LightedCandle09_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle09_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_77" scale={0}>
        <group name="frame_8" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode015">
            <group name="Vox_LightedCandle08">
              <mesh name="Vox_LightedCandle08_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle08_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_82" scale={0}>
        <group name="frame_7" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode016">
            <group name="Vox_LightedCandle07">
              <mesh name="Vox_LightedCandle07_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle07_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_87" scale={0}>
        <group name="frame_6" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode017">
            <group name="Vox_LightedCandle06">
              <mesh name="Vox_LightedCandle06_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle06_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_92" scale={0}>
        <group name="frame_5" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode018">
            <group name="Vox_LightedCandle05">
              <mesh name="Vox_LightedCandle05_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle05_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
      <group name="Object_97" scale={0}>
        <group name="frame_4" rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode019">
            <group name="Vox_LightedCandle04">
              <mesh name="Vox_LightedCandle04_Mat_Vox_Fire00_0" geometry={nodes.Vox_LightedCandle04_Mat_Vox_Fire00_0.geometry} material={materials.Mat_Vox_Fire00} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}
