import {
  RocketsBlack, RocketsBlue,
  RocketsBrown, RocketsGreen,
  RocketsOrange,
  RocketsPink,
  RocketsPurple,
  RocketsRed, RocketsWhite,
  RocketsYellow
} from "./Rockets";
import React from "react";

export function FieldRockets({searchedObject, objectArrays}: any) {
  return (
    <>

      {/*todo make a green rocket*/}

      <RocketsBlack objectArray={objectArrays.blackObjectArray} searchedObject={searchedObject} />
      <RocketsRed objectArray={objectArrays.redObjectArray} searchedObject={searchedObject} />
      <RocketsBrown objectArray={objectArrays.brownObjectArray} searchedObject={searchedObject} />
      <RocketsYellow objectArray={objectArrays.yellowObjectArray} searchedObject={searchedObject} />
      <RocketsOrange objectArray={objectArrays.orangeObjectArray} searchedObject={searchedObject} />
      <RocketsPurple objectArray={objectArrays.purpleObjectArray} searchedObject={searchedObject} />
      <RocketsPink objectArray={objectArrays.pinkObjectArray} searchedObject={searchedObject} />
      <RocketsWhite objectArray={objectArrays.whiteObjectArray} searchedObject={searchedObject} />
      <RocketsBlue objectArray={objectArrays.blueObjectArray} searchedObject={searchedObject} />
      <RocketsGreen objectArray={objectArrays.greenObjectArray} searchedObject={searchedObject} />
    </>
  )
}
