/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {RigidBody} from "@react-three/rapier";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/sandbags-transformed.glb`;


type GLTFResult = GLTF & {
  nodes: {
    Sandbags: THREE.Mesh
  }
  materials: {
    Sandbags: THREE.MeshStandardMaterial
  }
}

export function Sandbags({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>(null!)
  //@ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <RigidBody type="fixed" colliders="cuboid">
        <mesh castShadow receiveShadow geometry={nodes.Sandbags.geometry} material={materials.Sandbags} />
      </RigidBody>
    </group>
  )
}
