import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {getRoseSize} from "../../helpers/helperFunctions";
import {useWeb3React} from "@web3-react/core";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--red-transformed.glb`;

type GLTFResult = GLTF & {
  nodes: {
    Rose: THREE.Mesh
  }
  materials: {
    Rose: THREE.MeshStandardMaterial
  }
}

export default function PlacedRose({ placedObject, objectColor, roseSizings, donationAmount }: any) {
  const { chainId } = useWeb3React();
  const group = useRef<THREE.Group>(null)
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  const [placedObjectColor, setPlacedObjectColor] = React.useState(objectColor);
  const [localScale, setLocalScale] = React.useState(1);

  React.useEffect(() => {
    if (objectColor === 'brown') {
      setPlacedObjectColor('#4d3210');
    } else {
      setPlacedObjectColor(objectColor);
    }
  }, [objectColor]);

  React.useEffect(() => {
    const roseSize = getRoseSize(chainId, donationAmount, roseSizings);
    setLocalScale(roseSize);
  }, [donationAmount]);

  // console.log(`placed rose: ${JSON.stringify(placedObject)}`)
  return (
    <group ref={group} dispose={null} position={[placedObject.x, 0.3, placedObject.z]}>
      <mesh geometry={nodes.Rose.geometry} material={materials.Rose} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]} scale={localScale}>
        <meshStandardMaterial color={placedObjectColor} />
      </mesh>
      <mesh>
        <cylinderGeometry attach="geometry" args={[0.01, 0.01, 100, 32]}>
          <meshBasicMaterial
            attach="material"
            opacity={0.5}
          />
        </cylinderGeometry>
      </mesh>
    </group>
  )
}
