import * as THREE from 'three'
import React, { useRef } from 'react'
import {useFrame} from "@react-three/fiber";
import {interactivePlayerPosition} from "../../../helpers/helperFunctions";
import { useKeyboardControls } from "@react-three/drei"
import Note from './Note';

export default function ObjectHighlight(
  { lockKeyboard, searchedObject, setSlideData, setShowSlideModal, setSlideMessage, pointerControls,
    playerPosition, setSlideModalCharacter }: any)
{
  const group = useRef<THREE.Group>(null)

  const [, get] = useKeyboardControls();
  const [interacting, setInteracting] = React.useState(false);
  const myPosition = {x: searchedObject.position.x, y: searchedObject.position.y, z: searchedObject.position.z}

  useFrame((state) => {
    const { action } = get();
    if (action && !interacting && !lockKeyboard) {

      if (interactivePlayerPosition(playerPosition, myPosition, 0, 1.5) && searchedObject.note !== "") {
        setInteracting(true);
        setSlideModalCharacter('note');
        setSlideMessage('object-note');
        setSlideData(`${searchedObject.note}${(searchedObject.messageFrom) ? ` From: ${searchedObject.messageFrom}` : ''}`)
        setShowSlideModal(true);
        setTimeout(() => {
          pointerControls.current.unlock();
        }, 100);
        setTimeout(() => {
          setInteracting(false);
        }, 100);
      }
    }
  });

  return (
    <group ref={group} dispose={null} position={[searchedObject.position.x, searchedObject.position.y, searchedObject.position.z]}>
      { searchedObject.note !== "" && (
        <Note />
      )}

      <mesh>
        <cylinderGeometry attach="geometry" args={[0.3, 0.3, 500, 32]}>
          {/*<meshBasicMaterial*/}
          {/*  transparent*/}
          {/*  attach="material"*/}
          {/*  opacity={0.5}*/}
          {/*/>*/}
        </cylinderGeometry>
        <meshPhongMaterial color="#ff0000" opacity={0.1} transparent />
      </mesh>
      <mesh>
        <cylinderGeometry attach="geometry" args={[0.01, 0.01, 500, 32]}>
        </cylinderGeometry>
        <meshBasicMaterial
          attach="material"
          opacity={0.5}
        />
      </mesh>
    </group>
  )
}
