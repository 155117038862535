/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    F5_Yellow_0: THREE.Mesh
    F5001_Blue_0: THREE.Mesh
    F5002_Red_0: THREE.Mesh
    F5003_Green_0: THREE.Mesh
    F5004_Yellow_0: THREE.Mesh
    F5005_Yellow_0: THREE.Mesh
    F5006_Yellow_0: THREE.Mesh
    F5007_Yellow_0: THREE.Mesh
    F5008_Yellow_0: THREE.Mesh
    F5009_Yellow_0: THREE.Mesh
    F5010_Blue_0: THREE.Mesh
    F5011_Green_0: THREE.Mesh
    particle000_Yellow_0: THREE.Mesh
    particle001_Yellow_0: THREE.Mesh
    particle002_Yellow_0: THREE.Mesh
    particle003_Yellow_0: THREE.Mesh
    particle004_Yellow_0: THREE.Mesh
    particle005_Yellow_0: THREE.Mesh
    particle006_Yellow_0: THREE.Mesh
    particle007_Yellow_0: THREE.Mesh
    particle008_Yellow_0: THREE.Mesh
    particle009_Yellow_0: THREE.Mesh
    particle010_Yellow_0: THREE.Mesh
    particle011_Yellow_0: THREE.Mesh
    particle012_Yellow_0: THREE.Mesh
    particle013_Yellow_0: THREE.Mesh
    particle014_Yellow_0: THREE.Mesh
    particle015_Yellow_0: THREE.Mesh
    particle016_Yellow_0: THREE.Mesh
    particle017_Yellow_0: THREE.Mesh
    particle018_Yellow_0: THREE.Mesh
    particle019_Yellow_0: THREE.Mesh
    particle020_Yellow_0: THREE.Mesh
    particle021_Yellow_0: THREE.Mesh
    particle022_Yellow_0: THREE.Mesh
    particle023_Yellow_0: THREE.Mesh
    particle024_Yellow_0: THREE.Mesh
    particle025_Yellow_0: THREE.Mesh
    particle026_Yellow_0: THREE.Mesh
    particle027_Yellow_0: THREE.Mesh
    particle028_Yellow_0: THREE.Mesh
    particle029_Yellow_0: THREE.Mesh
    particle030_Yellow_0: THREE.Mesh
    particle031_Yellow_0: THREE.Mesh
    particle032_Yellow_0: THREE.Mesh
    particle033_Yellow_0: THREE.Mesh
    particle034_Yellow_0: THREE.Mesh
    particle035_Yellow_0: THREE.Mesh
    particle036_Yellow_0: THREE.Mesh
    particle037_Yellow_0: THREE.Mesh
    particle038_Yellow_0: THREE.Mesh
    particle039_Yellow_0: THREE.Mesh
    particle040_Yellow_0: THREE.Mesh
    particle041_Yellow_0: THREE.Mesh
    particle042_Blue_0: THREE.Mesh
    particle043_Blue_0: THREE.Mesh
    particle044_Blue_0: THREE.Mesh
    particle045_Blue_0: THREE.Mesh
    particle046_Blue_0: THREE.Mesh
    particle047_Blue_0: THREE.Mesh
    particle048_Blue_0: THREE.Mesh
    particle049_Blue_0: THREE.Mesh
    particle050_Blue_0: THREE.Mesh
    particle051_Blue_0: THREE.Mesh
    particle519_Yellow_0: THREE.Mesh
    particle520_Yellow_0: THREE.Mesh
    particle521_Yellow_0: THREE.Mesh
    particle522_Yellow_0: THREE.Mesh
    particle523_Yellow_0: THREE.Mesh
    particle524_Yellow_0: THREE.Mesh
    particle525_Yellow_0: THREE.Mesh
    particle526_Yellow_0: THREE.Mesh
    particle527_Yellow_0: THREE.Mesh
    particle528_Yellow_0: THREE.Mesh
    particle529_Green_0: THREE.Mesh
    particle530_Green_0: THREE.Mesh
    particle531_Green_0: THREE.Mesh
    particle532_Green_0: THREE.Mesh
    particle533_Green_0: THREE.Mesh
    particle534_Green_0: THREE.Mesh
    particle535_Green_0: THREE.Mesh
    particle536_Green_0: THREE.Mesh
    particle537_Green_0: THREE.Mesh
    particle538_Green_0: THREE.Mesh
    particle052_Yellow_0: THREE.Mesh
    particle053_Yellow_0: THREE.Mesh
    particle054_Yellow_0: THREE.Mesh
    particle055_Yellow_0: THREE.Mesh
    particle056_Yellow_0: THREE.Mesh
    particle057_Yellow_0: THREE.Mesh
    particle058_Yellow_0: THREE.Mesh
    particle059_Yellow_0: THREE.Mesh
    particle060_Yellow_0: THREE.Mesh
    particle061_Yellow_0: THREE.Mesh
    particle062_Yellow_0: THREE.Mesh
    particle063_Yellow_0: THREE.Mesh
    particle064_Yellow_0: THREE.Mesh
    particle065_Yellow_0: THREE.Mesh
    particle066_Yellow_0: THREE.Mesh
    particle067_Yellow_0: THREE.Mesh
    particle068_Yellow_0: THREE.Mesh
    particle069_Yellow_0: THREE.Mesh
    particle070_Yellow_0: THREE.Mesh
    particle071_Yellow_0: THREE.Mesh
    particle072_Yellow_0: THREE.Mesh
    particle073_Yellow_0: THREE.Mesh
    particle074_Yellow_0: THREE.Mesh
    particle075_Yellow_0: THREE.Mesh
    particle076_Yellow_0: THREE.Mesh
    particle077_Yellow_0: THREE.Mesh
    particle078_Yellow_0: THREE.Mesh
    particle079_Yellow_0: THREE.Mesh
    particle080_Yellow_0: THREE.Mesh
    particle081_Yellow_0: THREE.Mesh
    particle082_Yellow_0: THREE.Mesh
    particle083_Yellow_0: THREE.Mesh
    particle084_Yellow_0: THREE.Mesh
    particle085_Yellow_0: THREE.Mesh
    particle086_Yellow_0: THREE.Mesh
    particle087_Yellow_0: THREE.Mesh
    particle088_Yellow_0: THREE.Mesh
    particle089_Yellow_0: THREE.Mesh
    particle090_Yellow_0: THREE.Mesh
    particle091_Yellow_0: THREE.Mesh
    particle092_Yellow_0: THREE.Mesh
    particle093_Yellow_0: THREE.Mesh
    particle094_Yellow_0: THREE.Mesh
    particle095_Yellow_0: THREE.Mesh
    particle096_Yellow_0: THREE.Mesh
    particle097_Yellow_0: THREE.Mesh
    particle098_Yellow_0: THREE.Mesh
    particle099_Yellow_0: THREE.Mesh
    particle100_Yellow_0: THREE.Mesh
    particle101_Yellow_0: THREE.Mesh
    particle102_Yellow_0: THREE.Mesh
    particle103_Yellow_0: THREE.Mesh
    particle104_Yellow_0: THREE.Mesh
    particle105_Yellow_0: THREE.Mesh
    particle106_Yellow_0: THREE.Mesh
    particle107_Yellow_0: THREE.Mesh
    particle108_Yellow_0: THREE.Mesh
    particle109_Yellow_0: THREE.Mesh
    particle110_Yellow_0: THREE.Mesh
    particle111_Yellow_0: THREE.Mesh
    particle112_Yellow_0: THREE.Mesh
    particle113_Yellow_0: THREE.Mesh
    particle435_Blue_0: THREE.Mesh
    particle436_Blue_0: THREE.Mesh
    particle437_Blue_0: THREE.Mesh
    particle438_Blue_0: THREE.Mesh
    particle439_Blue_0: THREE.Mesh
    particle440_Blue_0: THREE.Mesh
    particle441_Blue_0: THREE.Mesh
    particle442_Blue_0: THREE.Mesh
    particle443_Blue_0: THREE.Mesh
    particle444_Blue_0: THREE.Mesh
    particle445_Blue_0: THREE.Mesh
    particle446_Blue_0: THREE.Mesh
    particle447_Blue_0: THREE.Mesh
    particle448_Blue_0: THREE.Mesh
    particle449_Blue_0: THREE.Mesh
    particle450_Blue_0: THREE.Mesh
    particle451_Blue_0: THREE.Mesh
    particle452_Blue_0: THREE.Mesh
    particle453_Blue_0: THREE.Mesh
    particle454_Blue_0: THREE.Mesh
    particle455_Blue_0: THREE.Mesh
    particle456_Blue_0: THREE.Mesh
    particle457_Blue_0: THREE.Mesh
    particle458_Blue_0: THREE.Mesh
    particle459_Blue_0: THREE.Mesh
    particle460_Blue_0: THREE.Mesh
    particle461_Blue_0: THREE.Mesh
    particle462_Blue_0: THREE.Mesh
    particle463_Blue_0: THREE.Mesh
    particle464_Blue_0: THREE.Mesh
    particle465_Blue_0: THREE.Mesh
    particle466_Blue_0: THREE.Mesh
    particle467_Blue_0: THREE.Mesh
    particle468_Blue_0: THREE.Mesh
    particle469_Blue_0: THREE.Mesh
    particle470_Blue_0: THREE.Mesh
    particle471_Blue_0: THREE.Mesh
    particle472_Blue_0: THREE.Mesh
    particle473_Blue_0: THREE.Mesh
    particle474_Blue_0: THREE.Mesh
    particle475_Blue_0: THREE.Mesh
    particle476_Blue_0: THREE.Mesh
    particle477_Green_0: THREE.Mesh
    particle478_Green_0: THREE.Mesh
    particle479_Green_0: THREE.Mesh
    particle480_Green_0: THREE.Mesh
    particle481_Green_0: THREE.Mesh
    particle482_Green_0: THREE.Mesh
    particle483_Green_0: THREE.Mesh
    particle484_Green_0: THREE.Mesh
    particle485_Green_0: THREE.Mesh
    particle486_Green_0: THREE.Mesh
    particle487_Green_0: THREE.Mesh
    particle488_Green_0: THREE.Mesh
    particle489_Green_0: THREE.Mesh
    particle490_Green_0: THREE.Mesh
    particle491_Green_0: THREE.Mesh
    particle492_Green_0: THREE.Mesh
    particle493_Green_0: THREE.Mesh
    particle494_Green_0: THREE.Mesh
    particle495_Green_0: THREE.Mesh
    particle496_Green_0: THREE.Mesh
    particle497_Green_0: THREE.Mesh
    particle498_Green_0: THREE.Mesh
    particle499_Green_0: THREE.Mesh
    particle500_Green_0: THREE.Mesh
    particle501_Green_0: THREE.Mesh
    particle502_Green_0: THREE.Mesh
    particle503_Green_0: THREE.Mesh
    particle504_Green_0: THREE.Mesh
    particle505_Green_0: THREE.Mesh
    particle506_Green_0: THREE.Mesh
    particle507_Green_0: THREE.Mesh
    particle508_Green_0: THREE.Mesh
    particle509_Green_0: THREE.Mesh
    particle510_Green_0: THREE.Mesh
    particle511_Green_0: THREE.Mesh
    particle512_Green_0: THREE.Mesh
    particle513_Green_0: THREE.Mesh
    particle514_Green_0: THREE.Mesh
    particle515_Green_0: THREE.Mesh
    particle516_Green_0: THREE.Mesh
    particle517_Green_0: THREE.Mesh
    particle518_Green_0: THREE.Mesh
    particle114_Red_0: THREE.Mesh
    particle394_Red_0: THREE.Mesh
    particle395_Red_0: THREE.Mesh
    particle396_Red_0: THREE.Mesh
    particle397_Red_0: THREE.Mesh
    particle398_Red_0: THREE.Mesh
    particle399_Red_0: THREE.Mesh
    particle400_Red_0: THREE.Mesh
    particle401_Red_0: THREE.Mesh
    particle402_Red_0: THREE.Mesh
    particle403_Red_0: THREE.Mesh
    particle404_Red_0: THREE.Mesh
    particle405_Red_0: THREE.Mesh
    particle406_Red_0: THREE.Mesh
    particle407_Red_0: THREE.Mesh
    particle408_Red_0: THREE.Mesh
    particle409_Red_0: THREE.Mesh
    particle410_Red_0: THREE.Mesh
    particle411_Red_0: THREE.Mesh
    particle412_Red_0: THREE.Mesh
    particle413_Red_0: THREE.Mesh
    particle414_Red_0: THREE.Mesh
    particle415_Red_0: THREE.Mesh
    particle416_Red_0: THREE.Mesh
    particle417_Red_0: THREE.Mesh
    particle418_Red_0: THREE.Mesh
    particle419_Red_0: THREE.Mesh
    particle420_Red_0: THREE.Mesh
    particle421_Red_0: THREE.Mesh
    particle422_Red_0: THREE.Mesh
    particle423_Red_0: THREE.Mesh
    particle424_Red_0: THREE.Mesh
    particle425_Red_0: THREE.Mesh
    particle426_Red_0: THREE.Mesh
    particle427_Red_0: THREE.Mesh
    particle428_Red_0: THREE.Mesh
    particle429_Red_0: THREE.Mesh
    particle430_Red_0: THREE.Mesh
    particle431_Red_0: THREE.Mesh
    particle432_Red_0: THREE.Mesh
    particle433_Red_0: THREE.Mesh
    particle434_Red_0: THREE.Mesh
    particle115_Yellow_0: THREE.Mesh
    particle116_Yellow_0: THREE.Mesh
    particle117_Yellow_0: THREE.Mesh
    particle118_Yellow_0: THREE.Mesh
    particle119_Yellow_0: THREE.Mesh
    particle120_Yellow_0: THREE.Mesh
    particle121_Yellow_0: THREE.Mesh
    particle122_Yellow_0: THREE.Mesh
    particle123_Yellow_0: THREE.Mesh
    particle124_Yellow_0: THREE.Mesh
    particle125_Yellow_0: THREE.Mesh
    particle126_Yellow_0: THREE.Mesh
    particle127_Yellow_0: THREE.Mesh
    particle128_Yellow_0: THREE.Mesh
    particle129_Yellow_0: THREE.Mesh
    particle130_Yellow_0: THREE.Mesh
    particle131_Yellow_0: THREE.Mesh
    particle132_Yellow_0: THREE.Mesh
    particle133_Yellow_0: THREE.Mesh
    particle134_Yellow_0: THREE.Mesh
    particle135_Yellow_0: THREE.Mesh
    particle136_Yellow_0: THREE.Mesh
    particle137_Yellow_0: THREE.Mesh
    particle138_Yellow_0: THREE.Mesh
    particle139_Yellow_0: THREE.Mesh
    particle140_Yellow_0: THREE.Mesh
    particle141_Yellow_0: THREE.Mesh
    particle142_Yellow_0: THREE.Mesh
    particle143_Yellow_0: THREE.Mesh
    particle144_Yellow_0: THREE.Mesh
    particle145_Yellow_0: THREE.Mesh
    particle146_Yellow_0: THREE.Mesh
    particle147_Yellow_0: THREE.Mesh
    particle148_Yellow_0: THREE.Mesh
    particle149_Yellow_0: THREE.Mesh
    particle150_Yellow_0: THREE.Mesh
    particle151_Yellow_0: THREE.Mesh
    particle152_Yellow_0: THREE.Mesh
    particle153_Yellow_0: THREE.Mesh
    particle154_Yellow_0: THREE.Mesh
    particle155_Yellow_0: THREE.Mesh
    particle156_Yellow_0: THREE.Mesh
    particle157_Yellow_0: THREE.Mesh
    particle158_Yellow_0: THREE.Mesh
    particle159_Yellow_0: THREE.Mesh
    particle160_Yellow_0: THREE.Mesh
    particle161_Yellow_0: THREE.Mesh
    particle162_Yellow_0: THREE.Mesh
    particle163_Yellow_0: THREE.Mesh
    particle164_Yellow_0: THREE.Mesh
    particle165_Yellow_0: THREE.Mesh
    particle166_Yellow_0: THREE.Mesh
    particle167_Yellow_0: THREE.Mesh
    particle168_Yellow_0: THREE.Mesh
    particle169_Yellow_0: THREE.Mesh
    particle170_Yellow_0: THREE.Mesh
    particle171_Yellow_0: THREE.Mesh
    particle172_Yellow_0: THREE.Mesh
    particle173_Yellow_0: THREE.Mesh
    particle174_Yellow_0: THREE.Mesh
    particle175_Yellow_0: THREE.Mesh
    particle176_Yellow_0: THREE.Mesh
    particle177_Yellow_0: THREE.Mesh
    particle178_Yellow_0: THREE.Mesh
    particle179_Yellow_0: THREE.Mesh
    particle180_Yellow_0: THREE.Mesh
    particle181_Yellow_0: THREE.Mesh
    particle182_Yellow_0: THREE.Mesh
    particle183_Yellow_0: THREE.Mesh
    particle184_Yellow_0: THREE.Mesh
    particle185_Yellow_0: THREE.Mesh
    particle186_Yellow_0: THREE.Mesh
    particle187_Yellow_0: THREE.Mesh
    particle188_Yellow_0: THREE.Mesh
    particle189_Yellow_0: THREE.Mesh
    particle190_Yellow_0: THREE.Mesh
    particle191_Yellow_0: THREE.Mesh
    particle192_Yellow_0: THREE.Mesh
    particle193_Yellow_0: THREE.Mesh
    particle194_Yellow_0: THREE.Mesh
    particle195_Yellow_0: THREE.Mesh
    particle196_Yellow_0: THREE.Mesh
    particle197_Yellow_0: THREE.Mesh
    particle198_Yellow_0: THREE.Mesh
    particle199_Yellow_0: THREE.Mesh
    particle200_Yellow_0: THREE.Mesh
    particle201_Yellow_0: THREE.Mesh
    particle202_Yellow_0: THREE.Mesh
    particle203_Yellow_0: THREE.Mesh
    particle204_Yellow_0: THREE.Mesh
    particle205_Yellow_0: THREE.Mesh
    particle206_Yellow_0: THREE.Mesh
    particle207_Yellow_0: THREE.Mesh
    particle208_Yellow_0: THREE.Mesh
    particle209_Yellow_0: THREE.Mesh
    particle210_Yellow_0: THREE.Mesh
    particle211_Yellow_0: THREE.Mesh
    particle212_Yellow_0: THREE.Mesh
    particle213_Yellow_0: THREE.Mesh
    particle214_Yellow_0: THREE.Mesh
    particle215_Yellow_0: THREE.Mesh
    particle216_Yellow_0: THREE.Mesh
    particle217_Yellow_0: THREE.Mesh
    particle218_Yellow_0: THREE.Mesh
    particle219_Yellow_0: THREE.Mesh
    particle220_Yellow_0: THREE.Mesh
    particle221_Yellow_0: THREE.Mesh
    particle222_Yellow_0: THREE.Mesh
    particle223_Yellow_0: THREE.Mesh
    particle224_Yellow_0: THREE.Mesh
    particle225_Yellow_0: THREE.Mesh
    particle226_Yellow_0: THREE.Mesh
    particle227_Yellow_0: THREE.Mesh
    particle228_Yellow_0: THREE.Mesh
    particle229_Yellow_0: THREE.Mesh
    particle230_Yellow_0: THREE.Mesh
    particle231_Yellow_0: THREE.Mesh
    particle232_Yellow_0: THREE.Mesh
    particle233_Yellow_0: THREE.Mesh
    particle234_Yellow_0: THREE.Mesh
    particle235_Yellow_0: THREE.Mesh
    particle236_Yellow_0: THREE.Mesh
    particle237_Yellow_0: THREE.Mesh
    particle238_Yellow_0: THREE.Mesh
    particle239_Yellow_0: THREE.Mesh
    particle240_Yellow_0: THREE.Mesh
    particle241_Yellow_0: THREE.Mesh
    particle242_Yellow_0: THREE.Mesh
    particle243_Yellow_0: THREE.Mesh
    particle244_Yellow_0: THREE.Mesh
    particle245_Yellow_0: THREE.Mesh
    particle246_Yellow_0: THREE.Mesh
    particle247_Yellow_0: THREE.Mesh
    particle248_Yellow_0: THREE.Mesh
    particle249_Yellow_0: THREE.Mesh
    particle250_Yellow_0: THREE.Mesh
    particle251_Yellow_0: THREE.Mesh
    particle252_Yellow_0: THREE.Mesh
    particle253_Yellow_0: THREE.Mesh
    particle254_Yellow_0: THREE.Mesh
    particle255_Yellow_0: THREE.Mesh
    particle256_Yellow_0: THREE.Mesh
    particle257_Yellow_0: THREE.Mesh
    particle258_Yellow_0: THREE.Mesh
    particle259_Yellow_0: THREE.Mesh
    particle260_Yellow_0: THREE.Mesh
    particle261_Yellow_0: THREE.Mesh
    particle262_Yellow_0: THREE.Mesh
    particle263_Yellow_0: THREE.Mesh
    particle264_Yellow_0: THREE.Mesh
    particle265_Yellow_0: THREE.Mesh
    particle266_Yellow_0: THREE.Mesh
    particle267_Yellow_0: THREE.Mesh
    particle268_Yellow_0: THREE.Mesh
    particle269_Yellow_0: THREE.Mesh
    particle270_Yellow_0: THREE.Mesh
    particle271_Yellow_0: THREE.Mesh
    particle272_Yellow_0: THREE.Mesh
    particle273_Yellow_0: THREE.Mesh
    particle274_Yellow_0: THREE.Mesh
    particle275_Yellow_0: THREE.Mesh
    particle276_Yellow_0: THREE.Mesh
    particle277_Yellow_0: THREE.Mesh
    particle278_Yellow_0: THREE.Mesh
    particle279_Yellow_0: THREE.Mesh
    particle280_Yellow_0: THREE.Mesh
    particle281_Yellow_0: THREE.Mesh
    particle282_Yellow_0: THREE.Mesh
    particle283_Yellow_0: THREE.Mesh
    particle284_Yellow_0: THREE.Mesh
    particle285_Yellow_0: THREE.Mesh
    particle286_Yellow_0: THREE.Mesh
    particle287_Yellow_0: THREE.Mesh
    particle288_Yellow_0: THREE.Mesh
    particle289_Yellow_0: THREE.Mesh
    particle290_Yellow_0: THREE.Mesh
    particle291_Yellow_0: THREE.Mesh
    particle292_Yellow_0: THREE.Mesh
    particle293_Yellow_0: THREE.Mesh
    particle294_Yellow_0: THREE.Mesh
    particle295_Yellow_0: THREE.Mesh
    particle296_Yellow_0: THREE.Mesh
    particle297_Yellow_0: THREE.Mesh
    particle298_Yellow_0: THREE.Mesh
    particle299_Yellow_0: THREE.Mesh
    particle300_Yellow_0: THREE.Mesh
    particle301_Yellow_0: THREE.Mesh
    particle302_Yellow_0: THREE.Mesh
    particle303_Yellow_0: THREE.Mesh
    particle304_Yellow_0: THREE.Mesh
    particle305_Yellow_0: THREE.Mesh
    particle306_Yellow_0: THREE.Mesh
    particle307_Yellow_0: THREE.Mesh
    particle308_Yellow_0: THREE.Mesh
    particle309_Yellow_0: THREE.Mesh
    particle310_Yellow_0: THREE.Mesh
    particle311_Yellow_0: THREE.Mesh
    particle312_Yellow_0: THREE.Mesh
    particle313_Yellow_0: THREE.Mesh
    particle314_Yellow_0: THREE.Mesh
    particle315_Yellow_0: THREE.Mesh
    particle316_Yellow_0: THREE.Mesh
    particle317_Yellow_0: THREE.Mesh
    particle318_Yellow_0: THREE.Mesh
    particle319_Yellow_0: THREE.Mesh
    particle320_Yellow_0: THREE.Mesh
    particle321_Yellow_0: THREE.Mesh
    particle322_Yellow_0: THREE.Mesh
    particle323_Yellow_0: THREE.Mesh
    particle324_Yellow_0: THREE.Mesh
    particle325_Yellow_0: THREE.Mesh
    particle326_Yellow_0: THREE.Mesh
    particle327_Yellow_0: THREE.Mesh
    particle328_Yellow_0: THREE.Mesh
    particle329_Yellow_0: THREE.Mesh
    particle330_Yellow_0: THREE.Mesh
    particle331_Yellow_0: THREE.Mesh
    particle332_Yellow_0: THREE.Mesh
    particle333_Yellow_0: THREE.Mesh
    particle334_Yellow_0: THREE.Mesh
    particle335_Yellow_0: THREE.Mesh
    particle336_Yellow_0: THREE.Mesh
    particle337_Yellow_0: THREE.Mesh
    particle338_Yellow_0: THREE.Mesh
    particle339_Yellow_0: THREE.Mesh
    particle340_Yellow_0: THREE.Mesh
    particle341_Yellow_0: THREE.Mesh
    particle342_Yellow_0: THREE.Mesh
    particle343_Yellow_0: THREE.Mesh
    particle344_Yellow_0: THREE.Mesh
    particle345_Yellow_0: THREE.Mesh
    particle346_Yellow_0: THREE.Mesh
    particle347_Yellow_0: THREE.Mesh
    particle348_Yellow_0: THREE.Mesh
    particle349_Yellow_0: THREE.Mesh
    particle350_Yellow_0: THREE.Mesh
    particle351_Yellow_0: THREE.Mesh
    particle352_Yellow_0: THREE.Mesh
    particle353_Yellow_0: THREE.Mesh
    particle354_Yellow_0: THREE.Mesh
    particle355_Yellow_0: THREE.Mesh
    particle356_Yellow_0: THREE.Mesh
    particle357_Yellow_0: THREE.Mesh
    particle358_Yellow_0: THREE.Mesh
    particle359_Yellow_0: THREE.Mesh
    particle360_Yellow_0: THREE.Mesh
    particle361_Yellow_0: THREE.Mesh
    particle362_Yellow_0: THREE.Mesh
    particle363_Yellow_0: THREE.Mesh
    particle364_Yellow_0: THREE.Mesh
    particle365_Yellow_0: THREE.Mesh
    particle366_Yellow_0: THREE.Mesh
    particle367_Yellow_0: THREE.Mesh
    particle368_Yellow_0: THREE.Mesh
    particle369_Yellow_0: THREE.Mesh
    particle370_Yellow_0: THREE.Mesh
    particle371_Yellow_0: THREE.Mesh
    particle372_Yellow_0: THREE.Mesh
    particle373_Yellow_0: THREE.Mesh
    particle374_Yellow_0: THREE.Mesh
    particle375_Yellow_0: THREE.Mesh
    particle376_Yellow_0: THREE.Mesh
    particle377_Yellow_0: THREE.Mesh
    particle378_Yellow_0: THREE.Mesh
    particle379_Yellow_0: THREE.Mesh
    particle380_Yellow_0: THREE.Mesh
    particle381_Yellow_0: THREE.Mesh
    particle382_Yellow_0: THREE.Mesh
    particle383_Yellow_0: THREE.Mesh
    particle384_Yellow_0: THREE.Mesh
    particle385_Yellow_0: THREE.Mesh
    particle386_Yellow_0: THREE.Mesh
    particle387_Yellow_0: THREE.Mesh
    particle388_Yellow_0: THREE.Mesh
    particle389_Yellow_0: THREE.Mesh
    particle390_Yellow_0: THREE.Mesh
    particle391_Yellow_0: THREE.Mesh
    particle392_Yellow_0: THREE.Mesh
    particle393_Yellow_0: THREE.Mesh
  }
  materials: {
    Yellow: THREE.MeshBasicMaterial
    Blue: THREE.MeshBasicMaterial
  }
}

type ActionName = 'Scene'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Fireworks({ position, scale, rotation, duration }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/fireworks-transformed.glb`;

  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group)


  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => {
    mixer.clipAction(animations[0], group.current).setDuration(duration);
    mixer.clipAction(animations[0], group.current).play();
  }, [animations])

  useFrame((scene, delta) => {
    mixer?.update(delta)
  });

  return (
    <group ref={group} position={position} rotation={rotation} scale={scale} dispose={null}>
      <group name="Scene">
        <group name="RootNode">
          <group name="Empty001" position={[1.83, 2155.58, -39.63]} rotation={[-1.59, 0, 0]} scale={100}>
            <group name="F5" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5_Yellow_0" geometry={nodes.F5_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty002" position={[-48.58, 2309.27, 303.79]} rotation={[-1.44, 0.01, -0.01]} scale={100}>
            <group name="F5001" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5001_Blue_0" geometry={nodes.F5001_Blue_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty003" position={[356.14, 1981.92, -208.47]} rotation={[-1.68, 0.17, 0.02]} scale={100}>
            <group name="F5002" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5002_Red_0" geometry={nodes.F5002_Red_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty004" position={[598.83, 2590.97, -337.29]} rotation={[-1.73, 0.24, -0.87]} scale={100}>
            <group name="F5003" position={[0, 0, 23.55]} rotation={[-Math.PI / 2, -0.03, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5003_Green_0" geometry={nodes.F5003_Green_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty005" position={[1.03, 1808.97, 375.46]} rotation={[-1.37, 0, 0]} scale={100}>
            <group name="F5004" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5004_Yellow_0" geometry={nodes.F5004_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty006" position={[-14.88, 2144.51, -37.32]} rotation={[-1.59, 0, 0]} scale={100}>
            <group name="F5005" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5005_Yellow_0" geometry={nodes.F5005_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty007" position={[2.65, 1973.5, 163.94]} rotation={[-1.49, 0, 0]} scale={100}>
            <group name="F5006" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5006_Yellow_0" geometry={nodes.F5006_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty008" position={[2.65, 1973.5, 163.94]} rotation={[-1.49, 0, 0]} scale={100}>
            <group name="F5007" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5007_Yellow_0" geometry={nodes.F5007_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty009" position={[-43.11, 2458.3, 535.01]} rotation={[-1.36, 0, 0]} scale={100}>
            <group name="F5008" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5008_Yellow_0" geometry={nodes.F5008_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty010" position={[1.45, 2049.99, 80.37]} rotation={[-1.53, 0, 0]} scale={100}>
            <group name="F5009" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5009_Yellow_0" geometry={nodes.F5009_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty011" position={[7.86, 1671.62, 713.21]} rotation={[-1.16, 0, 0]} scale={100}>
            <group name="F5010" position={[0, 0, -21.27]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5010_Blue_0" geometry={nodes.F5010_Blue_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="Empty012" position={[-465.94, 2240.73, -286.22]} rotation={[-1.69, -0.2, -1.73]} scale={100}>
            <group name="F5011" position={[0, 0, 23.55]} rotation={[-Math.PI / 2, -0.03, -Math.PI / 2]} scale={0.01}>
              <mesh name="F5011_Green_0" geometry={nodes.F5011_Green_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="F1" position={[2.65, 1973.5, 163.94]} rotation={[-Math.PI / 2, 0, 0]} scale={168.42}>
            <group name="particle000" position={[0.74, 0.24, 0]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle000_Yellow_0" geometry={nodes.particle000_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle001" position={[0.66, 0, -0.41]} rotation={[0, 0.55, 0]} scale={0.01}>
              <mesh name="particle001_Yellow_0" geometry={nodes.particle001_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle002" position={[0.33, 0.24, -0.66]} rotation={[-0.36, 1.02, 0.63]} scale={0.01}>
              <mesh name="particle002_Yellow_0" geometry={nodes.particle002_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle003" position={[0.2, 0.63, -0.41]} rotation={[-0.41, 0.55, 1.26]} scale={0.01}>
              <mesh name="particle003_Yellow_0" geometry={nodes.particle003_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle004" position={[-0.13, 0.39, -0.66]} rotation={[-0.77, -1.02, 1.88]} scale={0.01}>
              <mesh name="particle004_Yellow_0" geometry={nodes.particle004_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle005" position={[-0.13, -0.39, -0.66]} rotation={[0.77, -1.02, -1.88]} scale={0.01}>
              <mesh name="particle005_Yellow_0" geometry={nodes.particle005_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle006" position={[0.33, -0.24, -0.66]} rotation={[0.36, 1.02, -0.63]} scale={0.01}>
              <mesh name="particle006_Yellow_0" geometry={nodes.particle006_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle007" position={[0.2, -0.63, -0.41]} rotation={[0.41, 0.55, -1.26]} scale={0.01}>
              <mesh name="particle007_Yellow_0" geometry={nodes.particle007_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle008" position={[0.13, -0.39, 0.66]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle008_Yellow_0" geometry={nodes.particle008_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle009" position={[-0.33, -0.24, 0.66]} rotation={[-0.36, 1.02, -2.51]} scale={0.01}>
              <mesh name="particle009_Yellow_0" geometry={nodes.particle009_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle010" position={[0.13, 0.39, 0.66]} rotation={[0.77, -1.02, 1.26]} scale={0.01}>
              <mesh name="particle010_Yellow_0" geometry={nodes.particle010_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle011" position={[-0.2, 0.63, 0.41]} rotation={[0.41, 0.55, 1.88]} scale={0.01}>
              <mesh name="particle011_Yellow_0" geometry={nodes.particle011_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle012" position={[-0.66, 0, 0.41]} rotation={[0, 0.55, -Math.PI]} scale={0.01}>
              <mesh name="particle012_Yellow_0" geometry={nodes.particle012_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle013" position={[-0.41, 0, -0.66]} rotation={[0, -1.02, Math.PI]} scale={0.01}>
              <mesh name="particle013_Yellow_0" geometry={nodes.particle013_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle014" position={[-0.53, -0.39, -0.41]} rotation={[0.18, -0.55, -2.51]} scale={0.01}>
              <mesh name="particle014_Yellow_0" geometry={nodes.particle014_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle015" position={[-0.53, 0.39, -0.41]} rotation={[-0.18, -0.55, 2.51]} scale={0.01}>
              <mesh name="particle015_Yellow_0" geometry={nodes.particle015_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle016" position={[0.74, -0.24, 0]} rotation={[0, 0, -0.31]} scale={0.01}>
              <mesh name="particle016_Yellow_0" geometry={nodes.particle016_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle017" position={[0, -0.77, 0]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle017_Yellow_0" geometry={nodes.particle017_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle018" position={[0.45, -0.63, 0]} rotation={[0, 0, -0.94]} scale={0.01}>
              <mesh name="particle018_Yellow_0" geometry={nodes.particle018_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle019" position={[-0.74, -0.24, 0]} rotation={[0, 0, -2.83]} scale={0.01}>
              <mesh name="particle019_Yellow_0" geometry={nodes.particle019_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle020" position={[-0.45, -0.63, 0]} rotation={[0, 0, -2.2]} scale={0.01}>
              <mesh name="particle020_Yellow_0" geometry={nodes.particle020_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle021" position={[-0.45, 0.63, 0]} rotation={[0, 0, 2.2]} scale={0.01}>
              <mesh name="particle021_Yellow_0" geometry={nodes.particle021_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle022" position={[-0.74, 0.24, 0]} rotation={[0, 0, 2.83]} scale={0.01}>
              <mesh name="particle022_Yellow_0" geometry={nodes.particle022_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle023" position={[0.45, 0.63, 0]} rotation={[0, 0, 0.94]} scale={0.01}>
              <mesh name="particle023_Yellow_0" geometry={nodes.particle023_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle024" position={[0, 0.77, 0]} rotation={[0, 0, Math.PI / 2]} scale={0.01}>
              <mesh name="particle024_Yellow_0" geometry={nodes.particle024_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle025" position={[0.53, -0.39, 0.41]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle025_Yellow_0" geometry={nodes.particle025_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle026" position={[-0.2, -0.63, 0.41]} rotation={[-0.41, 0.55, -1.88]} scale={0.01}>
              <mesh name="particle026_Yellow_0" geometry={nodes.particle026_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle027" position={[0.53, 0.39, 0.41]} rotation={[0.18, -0.55, Math.PI / 5]} scale={0.01}>
              <mesh name="particle027_Yellow_0" geometry={nodes.particle027_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle028" position={[0.41, 0, 0.66]} rotation={[0, -1.02, 0]} scale={0.01}>
              <mesh name="particle028_Yellow_0" geometry={nodes.particle028_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle029" position={[-0.33, 0.24, 0.66]} rotation={[0.36, 1.02, 2.51]} scale={0.01}>
              <mesh name="particle029_Yellow_0" geometry={nodes.particle029_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle030" position={[0, 0, 0.77]} rotation={[-Math.PI, 1.57, 0]} scale={0.01}>
              <mesh name="particle030_Yellow_0" geometry={nodes.particle030_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle031" position={[0.69, 0, 0.35]} rotation={[0, -0.46, 0]} scale={0.01}>
              <mesh name="particle031_Yellow_0" geometry={nodes.particle031_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle032" position={[0.21, 0.66, 0.35]} rotation={[0.34, -0.46, 1.26]} scale={0.01}>
              <mesh name="particle032_Yellow_0" geometry={nodes.particle032_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle033" position={[-0.56, 0.41, 0.35]} rotation={[0.15, 0.46, 2.51]} scale={0.01}>
              <mesh name="particle033_Yellow_0" geometry={nodes.particle033_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle034" position={[-0.56, -0.41, 0.35]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle034_Yellow_0" geometry={nodes.particle034_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle035" position={[0.21, -0.66, 0.35]} rotation={[-0.34, -0.46, -1.26]} scale={0.01}>
              <mesh name="particle035_Yellow_0" geometry={nodes.particle035_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle036" position={[0.56, 0.41, -0.35]} rotation={[-0.15, 0.46, 0.63]} scale={0.01}>
              <mesh name="particle036_Yellow_0" geometry={nodes.particle036_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle037" position={[-0.21, 0.66, -0.35]} rotation={[-0.34, -0.46, 1.88]} scale={0.01}>
              <mesh name="particle037_Yellow_0" geometry={nodes.particle037_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle038" position={[-0.69, 0, -0.35]} rotation={[0, -0.46, -Math.PI]} scale={0.01}>
              <mesh name="particle038_Yellow_0" geometry={nodes.particle038_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle039" position={[-0.21, -0.66, -0.35]} rotation={[0.34, -0.46, -1.88]} scale={0.01}>
              <mesh name="particle039_Yellow_0" geometry={nodes.particle039_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle040" position={[0.56, -0.41, -0.35]} rotation={[0.15, 0.46, -0.63]} scale={0.01}>
              <mesh name="particle040_Yellow_0" geometry={nodes.particle040_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle041" position={[0, 0, -0.77]} rotation={[-Math.PI, -1.57, 0]} scale={0.01}>
              <mesh name="particle041_Yellow_0" geometry={nodes.particle041_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="F2" position={[7.86, 1671.62, 713.21]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group name="particle042" position={[2.41, 0.81, -0.11]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle042_Blue_0" geometry={nodes.particle042_Blue_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle043" position={[-0.02, 1.22, -1.97]} rotation={[-0.77, -1.02, 1.88]} scale={0.01}>
              <mesh name="particle043_Blue_0" geometry={nodes.particle043_Blue_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle044" position={[0.69, -0.96, 1.74]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle044_Blue_0" geometry={nodes.particle044_Blue_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle045" position={[-0.82, 0.13, -1.97]} rotation={[0, -1.02, -Math.PI]} scale={0.01}>
              <mesh name="particle045_Blue_0" geometry={nodes.particle045_Blue_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle046" position={[0.33, -2.05, -0.11]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle046_Blue_0" geometry={nodes.particle046_Blue_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle047" position={[-0.95, 1.9, -0.11]} rotation={[0, 0, 2.2]} scale={0.01}>
              <mesh name="particle047_Blue_0" geometry={nodes.particle047_Blue_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle048" position={[1.83, -0.96, 1.03]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle048_Blue_0" geometry={nodes.particle048_Blue_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle049" position={[-0.6, 0.81, 1.74]} rotation={[0.36, 1.02, 2.51]} scale={0.01}>
              <mesh name="particle049_Blue_0" geometry={nodes.particle049_Blue_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle050" position={[-1.25, -1.02, 0.86]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle050_Blue_0" geometry={nodes.particle050_Blue_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle051" position={[-1.62, 0.13, -1.09]} rotation={[0, -0.46, -Math.PI]} scale={0.01}>
              <mesh name="particle051_Blue_0" geometry={nodes.particle051_Blue_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="F2001" position={[-44.71, 2474.65, 551.51]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group name="particle519" position={[2.41, 0.81, -0.11]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle519_Yellow_0" geometry={nodes.particle519_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle520" position={[-0.02, 1.22, -1.97]} rotation={[-0.77, -1.02, 1.88]} scale={0.01}>
              <mesh name="particle520_Yellow_0" geometry={nodes.particle520_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle521" position={[0.69, -0.96, 1.74]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle521_Yellow_0" geometry={nodes.particle521_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle522" position={[-0.82, 0.13, -1.97]} rotation={[0, -1.02, -Math.PI]} scale={0.01}>
              <mesh name="particle522_Yellow_0" geometry={nodes.particle522_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle523" position={[0.33, -2.05, -0.11]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle523_Yellow_0" geometry={nodes.particle523_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle524" position={[-0.95, 1.9, -0.11]} rotation={[0, 0, 2.2]} scale={0.01}>
              <mesh name="particle524_Yellow_0" geometry={nodes.particle524_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle525" position={[1.83, -0.96, 1.03]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle525_Yellow_0" geometry={nodes.particle525_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle526" position={[-0.6, 0.81, 1.74]} rotation={[0.36, 1.02, 2.51]} scale={0.01}>
              <mesh name="particle526_Yellow_0" geometry={nodes.particle526_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle527" position={[-1.25, -1.02, 0.86]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle527_Yellow_0" geometry={nodes.particle527_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle528" position={[-1.62, 0.13, -1.09]} rotation={[0, -0.46, -Math.PI]} scale={0.01}>
              <mesh name="particle528_Yellow_0" geometry={nodes.particle528_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="F2002" position={[598.83, 2590.97, -337.29]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group name="particle529" position={[2.41, 0.81, -0.11]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle529_Green_0" geometry={nodes.particle529_Green_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle530" position={[-0.02, 1.22, -1.97]} rotation={[-0.77, -1.02, 1.88]} scale={0.01}>
              <mesh name="particle530_Green_0" geometry={nodes.particle530_Green_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle531" position={[0.69, -0.96, 1.74]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle531_Green_0" geometry={nodes.particle531_Green_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle532" position={[-0.82, 0.13, -1.97]} rotation={[0, -1.02, -Math.PI]} scale={0.01}>
              <mesh name="particle532_Green_0" geometry={nodes.particle532_Green_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle533" position={[0.33, -2.05, -0.11]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle533_Green_0" geometry={nodes.particle533_Green_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle534" position={[-0.95, 1.9, -0.11]} rotation={[0, 0, 2.2]} scale={0.01}>
              <mesh name="particle534_Green_0" geometry={nodes.particle534_Green_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle535" position={[1.83, -0.96, 1.03]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle535_Green_0" geometry={nodes.particle535_Green_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle536" position={[-0.6, 0.81, 1.74]} rotation={[0.36, 1.02, 2.51]} scale={0.01}>
              <mesh name="particle536_Green_0" geometry={nodes.particle536_Green_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle537" position={[-1.25, -1.02, 0.86]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle537_Green_0" geometry={nodes.particle537_Green_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle538" position={[-1.62, 0.13, -1.09]} rotation={[0, -0.46, -Math.PI]} scale={0.01}>
              <mesh name="particle538_Green_0" geometry={nodes.particle538_Green_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="F3" position={[1.45, 2049.99, 80.37]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group name="particle052" position={[0.03, 0.05, 0.06]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle052_Yellow_0" geometry={nodes.particle052_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle053" position={[0.03, 0.04, 0.03]} rotation={[0, 0.55, 0]} scale={0.01}>
              <mesh name="particle053_Yellow_0" geometry={nodes.particle053_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle054" position={[0.01, 0.05, 0.02]} rotation={[-0.36, 1.02, 0.63]} scale={0.01}>
              <mesh name="particle054_Yellow_0" geometry={nodes.particle054_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle055" position={[0, 0.07, 0.03]} rotation={[-0.41, 0.55, 1.26]} scale={0.01}>
              <mesh name="particle055_Yellow_0" geometry={nodes.particle055_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle056" position={[-0.02, 0.06, 0.02]} rotation={[-0.77, -1.02, 1.88]} scale={0.01}>
              <mesh name="particle056_Yellow_0" geometry={nodes.particle056_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle057" position={[-0.02, 0.01, 0.02]} rotation={[0.77, -1.02, -1.88]} scale={0.01}>
              <mesh name="particle057_Yellow_0" geometry={nodes.particle057_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle058" position={[0.01, 0.02, 0.02]} rotation={[0.36, 1.02, -0.63]} scale={0.01}>
              <mesh name="particle058_Yellow_0" geometry={nodes.particle058_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle059" position={[0, 0, 0.03]} rotation={[0.41, 0.55, -1.26]} scale={0.01}>
              <mesh name="particle059_Yellow_0" geometry={nodes.particle059_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle060" position={[-0.01, 0.01, 0.1]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle060_Yellow_0" geometry={nodes.particle060_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle061" position={[-0.03, 0.02, 0.1]} rotation={[-0.36, 1.02, -2.51]} scale={0.01}>
              <mesh name="particle061_Yellow_0" geometry={nodes.particle061_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle062" position={[-0.01, 0.06, 0.1]} rotation={[0.77, -1.02, 1.26]} scale={0.01}>
              <mesh name="particle062_Yellow_0" geometry={nodes.particle062_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle063" position={[-0.03, 0.07, 0.08]} rotation={[0.41, 0.55, 1.88]} scale={0.01}>
              <mesh name="particle063_Yellow_0" geometry={nodes.particle063_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle064" position={[-0.06, 0.04, 0.08]} rotation={[0, 0.55, Math.PI]} scale={0.01}>
              <mesh name="particle064_Yellow_0" geometry={nodes.particle064_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle065" position={[-0.04, 0.04, 0.02]} rotation={[0, -1.02, Math.PI]} scale={0.01}>
              <mesh name="particle065_Yellow_0" geometry={nodes.particle065_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle066" position={[-0.05, 0.01, 0.03]} rotation={[0.18, -0.55, -2.51]} scale={0.01}>
              <mesh name="particle066_Yellow_0" geometry={nodes.particle066_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle067" position={[-0.05, 0.06, 0.03]} rotation={[-0.18, -0.55, 2.51]} scale={0.01}>
              <mesh name="particle067_Yellow_0" geometry={nodes.particle067_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle068" position={[0.03, 0.02, 0.06]} rotation={[0, 0, -0.31]} scale={0.01}>
              <mesh name="particle068_Yellow_0" geometry={nodes.particle068_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle069" position={[-0.01, -0.01, 0.06]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle069_Yellow_0" geometry={nodes.particle069_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle070" position={[0.01, 0, 0.06]} rotation={[0, 0, -0.94]} scale={0.01}>
              <mesh name="particle070_Yellow_0" geometry={nodes.particle070_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle071" position={[-0.06, 0.02, 0.06]} rotation={[0, 0, -2.83]} scale={0.01}>
              <mesh name="particle071_Yellow_0" geometry={nodes.particle071_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle072" position={[-0.04, 0, 0.06]} rotation={[0, 0, -2.2]} scale={0.01}>
              <mesh name="particle072_Yellow_0" geometry={nodes.particle072_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle073" position={[-0.04, 0.07, 0.06]} rotation={[0, 0, 2.2]} scale={0.01}>
              <mesh name="particle073_Yellow_0" geometry={nodes.particle073_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle074" position={[-0.06, 0.05, 0.06]} rotation={[0, 0, 2.83]} scale={0.01}>
              <mesh name="particle074_Yellow_0" geometry={nodes.particle074_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle075" position={[0.01, 0.07, 0.06]} rotation={[0, 0, 0.94]} scale={0.01}>
              <mesh name="particle075_Yellow_0" geometry={nodes.particle075_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle076" position={[-0.01, 0.08, 0.06]} rotation={[0, 0, Math.PI / 2]} scale={0.01}>
              <mesh name="particle076_Yellow_0" geometry={nodes.particle076_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle077" position={[0.02, 0.01, 0.08]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle077_Yellow_0" geometry={nodes.particle077_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle078" position={[-0.03, 0, 0.08]} rotation={[-0.41, 0.55, -1.88]} scale={0.01}>
              <mesh name="particle078_Yellow_0" geometry={nodes.particle078_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle079" position={[0.02, 0.06, 0.08]} rotation={[0.18, -0.55, Math.PI / 5]} scale={0.01}>
              <mesh name="particle079_Yellow_0" geometry={nodes.particle079_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle080" position={[0.01, 0.04, 0.1]} rotation={[0, -1.02, 0]} scale={0.01}>
              <mesh name="particle080_Yellow_0" geometry={nodes.particle080_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle081" position={[-0.03, 0.05, 0.1]} rotation={[0.36, 1.02, 2.51]} scale={0.01}>
              <mesh name="particle081_Yellow_0" geometry={nodes.particle081_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle082" position={[-0.01, 0.04, 0.1]} rotation={[Math.PI, 1.57, 0]} scale={0.01}>
              <mesh name="particle082_Yellow_0" geometry={nodes.particle082_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle083" position={[0.03, 0.04, 0.08]} rotation={[0, -0.46, 0]} scale={0.01}>
              <mesh name="particle083_Yellow_0" geometry={nodes.particle083_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle084" position={[0, 0.08, 0.08]} rotation={[0.34, -0.46, 1.26]} scale={0.01}>
              <mesh name="particle084_Yellow_0" geometry={nodes.particle084_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle085" position={[-0.05, 0.06, 0.08]} rotation={[0.15, 0.46, 2.51]} scale={0.01}>
              <mesh name="particle085_Yellow_0" geometry={nodes.particle085_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle086" position={[-0.05, 0.01, 0.08]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle086_Yellow_0" geometry={nodes.particle086_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle087" position={[0, -0.01, 0.08]} rotation={[-0.34, -0.46, -1.26]} scale={0.01}>
              <mesh name="particle087_Yellow_0" geometry={nodes.particle087_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle088" position={[0.02, 0.06, 0.03]} rotation={[-0.15, 0.46, 0.63]} scale={0.01}>
              <mesh name="particle088_Yellow_0" geometry={nodes.particle088_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle089" position={[-0.03, 0.08, 0.03]} rotation={[-0.34, -0.46, 1.88]} scale={0.01}>
              <mesh name="particle089_Yellow_0" geometry={nodes.particle089_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle090" position={[-0.06, 0.04, 0.03]} rotation={[0, -0.46, -Math.PI]} scale={0.01}>
              <mesh name="particle090_Yellow_0" geometry={nodes.particle090_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle091" position={[-0.03, -0.01, 0.03]} rotation={[0.34, -0.46, -1.88]} scale={0.01}>
              <mesh name="particle091_Yellow_0" geometry={nodes.particle091_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle092" position={[0.02, 0.01, 0.03]} rotation={[0.15, 0.46, -0.63]} scale={0.01}>
              <mesh name="particle092_Yellow_0" geometry={nodes.particle092_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle093" position={[-0.01, 0.04, 0.01]} rotation={[Math.PI, -1.57, 0]} scale={0.01}>
              <mesh name="particle093_Yellow_0" geometry={nodes.particle093_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="F4" position={[-33.7, 87.45, -29.49]} rotation={[-1.62, 0, 0]} scale={100}>
            <group name="particle094" position={[0.02, 0, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle094_Yellow_0" geometry={nodes.particle094_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle095" position={[-0.01, 0.02, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle095_Yellow_0" geometry={nodes.particle095_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle096" position={[-0.01, -0.01, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle096_Yellow_0" geometry={nodes.particle096_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle097" position={[-0.02, 0.02, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle097_Yellow_0" geometry={nodes.particle097_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle098" position={[-0.03, -0.02, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle098_Yellow_0" geometry={nodes.particle098_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle099" position={[0, 0.03, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle099_Yellow_0" geometry={nodes.particle099_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle100" position={[0.02, -0.03, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle100_Yellow_0" geometry={nodes.particle100_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle101" position={[-0.03, 0.02, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle101_Yellow_0" geometry={nodes.particle101_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle102" position={[0, 0.01, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle102_Yellow_0" geometry={nodes.particle102_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle103" position={[-0.01, 0.01, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle103_Yellow_0" geometry={nodes.particle103_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle104" position={[-0.01, -0.03, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle104_Yellow_0" geometry={nodes.particle104_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle105" position={[0.01, 0.02, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle105_Yellow_0" geometry={nodes.particle105_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle106" position={[0, -0.02, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle106_Yellow_0" geometry={nodes.particle106_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle107" position={[0.02, 0.01, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle107_Yellow_0" geometry={nodes.particle107_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle108" position={[0.02, 0, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle108_Yellow_0" geometry={nodes.particle108_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle109" position={[0.02, -0.02, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle109_Yellow_0" geometry={nodes.particle109_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle110" position={[0.02, 0.02, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle110_Yellow_0" geometry={nodes.particle110_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle111" position={[0.01, -0.03, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle111_Yellow_0" geometry={nodes.particle111_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle112" position={[-0.03, -0.01, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle112_Yellow_0" geometry={nodes.particle112_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle113" position={[0.02, -0.01, -0.32]} rotation={[-Math.PI / 2, 0.71, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle113_Yellow_0" geometry={nodes.particle113_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
          <group name="F6Blue" position={[-48.58, 2309.27, 303.79]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group name="particle435" position={[0.03, 0, 0.13]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle435_Blue_0" geometry={nodes.particle435_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle436" position={[0.02, -0.01, 0.1]} rotation={[0, 0.55, 0]} scale={0.01}>
              <mesh name="particle436_Blue_0" geometry={nodes.particle436_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle437" position={[0, 0, 0.09]} rotation={[-0.36, 1.02, 0.63]} scale={0.01}>
              <mesh name="particle437_Blue_0" geometry={nodes.particle437_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle438" position={[-0.01, 0.03, 0.1]} rotation={[-0.41, 0.55, 1.26]} scale={0.01}>
              <mesh name="particle438_Blue_0" geometry={nodes.particle438_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle439" position={[-0.03, 0.01, 0.09]} rotation={[-0.77, -1.02, 1.88]} scale={0.01}>
              <mesh name="particle439_Blue_0" geometry={nodes.particle439_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle440" position={[-0.03, -0.04, 0.09]} rotation={[0.77, -1.02, -1.88]} scale={0.01}>
              <mesh name="particle440_Blue_0" geometry={nodes.particle440_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle441" position={[0, -0.03, 0.09]} rotation={[0.36, 1.02, -0.63]} scale={0.01}>
              <mesh name="particle441_Blue_0" geometry={nodes.particle441_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle442" position={[-0.01, -0.05, 0.1]} rotation={[0.41, 0.55, -1.26]} scale={0.01}>
              <mesh name="particle442_Blue_0" geometry={nodes.particle442_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle443" position={[-0.01, -0.04, 0.17]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle443_Blue_0" geometry={nodes.particle443_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle444" position={[-0.04, -0.03, 0.17]} rotation={[-0.36, 1.02, -2.51]} scale={0.01}>
              <mesh name="particle444_Blue_0" geometry={nodes.particle444_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle445" position={[-0.01, 0.01, 0.17]} rotation={[0.77, -1.02, 1.26]} scale={0.01}>
              <mesh name="particle445_Blue_0" geometry={nodes.particle445_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle446" position={[-0.03, 0.03, 0.15]} rotation={[0.41, 0.55, 1.88]} scale={0.01}>
              <mesh name="particle446_Blue_0" geometry={nodes.particle446_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle447" position={[-0.06, -0.01, 0.15]} rotation={[0, 0.55, -Math.PI]} scale={0.01}>
              <mesh name="particle447_Blue_0" geometry={nodes.particle447_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle448" position={[-0.04, -0.01, 0.09]} rotation={[0, -1.02, -Math.PI]} scale={0.01}>
              <mesh name="particle448_Blue_0" geometry={nodes.particle448_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle449" position={[-0.05, -0.04, 0.1]} rotation={[0.18, -0.55, -2.51]} scale={0.01}>
              <mesh name="particle449_Blue_0" geometry={nodes.particle449_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle450" position={[-0.05, 0.01, 0.1]} rotation={[-0.18, -0.55, 2.51]} scale={0.01}>
              <mesh name="particle450_Blue_0" geometry={nodes.particle450_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle451" position={[0.03, -0.03, 0.13]} rotation={[0, 0, -0.31]} scale={0.01}>
              <mesh name="particle451_Blue_0" geometry={nodes.particle451_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle452" position={[-0.02, -0.06, 0.13]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle452_Blue_0" geometry={nodes.particle452_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle453" position={[0.01, -0.05, 0.13]} rotation={[0, 0, -0.94]} scale={0.01}>
              <mesh name="particle453_Blue_0" geometry={nodes.particle453_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle454" position={[-0.06, -0.03, 0.13]} rotation={[0, 0, -2.83]} scale={0.01}>
              <mesh name="particle454_Blue_0" geometry={nodes.particle454_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle455" position={[-0.05, -0.05, 0.13]} rotation={[0, 0, -2.2]} scale={0.01}>
              <mesh name="particle455_Blue_0" geometry={nodes.particle455_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle456" position={[-0.05, 0.03, 0.13]} rotation={[0, 0, 2.2]} scale={0.01}>
              <mesh name="particle456_Blue_0" geometry={nodes.particle456_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle457" position={[-0.06, 0, 0.13]} rotation={[0, 0, 2.83]} scale={0.01}>
              <mesh name="particle457_Blue_0" geometry={nodes.particle457_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle458" position={[0.01, 0.03, 0.13]} rotation={[0, 0, 0.94]} scale={0.01}>
              <mesh name="particle458_Blue_0" geometry={nodes.particle458_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle459" position={[-0.02, 0.04, 0.13]} rotation={[0, 0, Math.PI / 2]} scale={0.01}>
              <mesh name="particle459_Blue_0" geometry={nodes.particle459_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle460" position={[0.01, -0.04, 0.15]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle460_Blue_0" geometry={nodes.particle460_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle461" position={[-0.03, -0.05, 0.15]} rotation={[-0.41, 0.55, -1.88]} scale={0.01}>
              <mesh name="particle461_Blue_0" geometry={nodes.particle461_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle462" position={[0.01, 0.01, 0.15]} rotation={[0.18, -0.55, Math.PI / 5]} scale={0.01}>
              <mesh name="particle462_Blue_0" geometry={nodes.particle462_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle463" position={[0.01, -0.01, 0.17]} rotation={[0, -1.02, 0]} scale={0.01}>
              <mesh name="particle463_Blue_0" geometry={nodes.particle463_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle464" position={[-0.04, 0, 0.17]} rotation={[0.36, 1.02, 2.51]} scale={0.01}>
              <mesh name="particle464_Blue_0" geometry={nodes.particle464_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle465" position={[-0.02, -0.01, 0.18]} rotation={[Math.PI, Math.PI / 2, 0]} scale={0.01}>
              <mesh name="particle465_Blue_0" geometry={nodes.particle465_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle466" position={[0.02, -0.01, 0.15]} rotation={[0, -0.46, 0]} scale={0.01}>
              <mesh name="particle466_Blue_0" geometry={nodes.particle466_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle467" position={[-0.01, 0.03, 0.15]} rotation={[0.34, -0.46, 1.26]} scale={0.01}>
              <mesh name="particle467_Blue_0" geometry={nodes.particle467_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle468" position={[-0.05, 0.01, 0.15]} rotation={[0.15, 0.46, 2.51]} scale={0.01}>
              <mesh name="particle468_Blue_0" geometry={nodes.particle468_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle469" position={[-0.05, -0.04, 0.15]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle469_Blue_0" geometry={nodes.particle469_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle470" position={[-0.01, -0.05, 0.15]} rotation={[-0.34, -0.46, -1.26]} scale={0.01}>
              <mesh name="particle470_Blue_0" geometry={nodes.particle470_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle471" position={[0.02, 0.01, 0.11]} rotation={[-0.15, 0.46, 0.63]} scale={0.01}>
              <mesh name="particle471_Blue_0" geometry={nodes.particle471_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle472" position={[-0.03, 0.03, 0.11]} rotation={[-0.34, -0.46, 1.88]} scale={0.01}>
              <mesh name="particle472_Blue_0" geometry={nodes.particle472_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle473" position={[-0.06, -0.01, 0.11]} rotation={[0, -0.46, -Math.PI]} scale={0.01}>
              <mesh name="particle473_Blue_0" geometry={nodes.particle473_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle474" position={[-0.03, -0.05, 0.11]} rotation={[0.34, -0.46, -1.88]} scale={0.01}>
              <mesh name="particle474_Blue_0" geometry={nodes.particle474_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle475" position={[0.02, -0.04, 0.11]} rotation={[0.15, 0.46, -0.63]} scale={0.01}>
              <mesh name="particle475_Blue_0" geometry={nodes.particle475_Blue_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle476" position={[-0.02, -0.01, 0.08]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={0.01}>
              <mesh name="particle476_Blue_0" geometry={nodes.particle476_Blue_0.geometry} material={materials.Blue} />
            </group>
          </group>
          <group name="F6Green" position={[-465.94, 2240.73, -286.22]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group name="particle477" position={[0.03, 0, 0.13]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle477_Green_0" geometry={nodes.particle477_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle478" position={[0.02, -0.01, 0.1]} rotation={[0, 0.55, 0]} scale={0.01}>
              <mesh name="particle478_Green_0" geometry={nodes.particle478_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle479" position={[0, 0, 0.09]} rotation={[-0.36, 1.02, 0.63]} scale={0.01}>
              <mesh name="particle479_Green_0" geometry={nodes.particle479_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle480" position={[-0.01, 0.03, 0.1]} rotation={[-0.41, 0.55, 1.26]} scale={0.01}>
              <mesh name="particle480_Green_0" geometry={nodes.particle480_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle481" position={[-0.03, 0.01, 0.09]} rotation={[-0.77, -1.02, 1.88]} scale={0.01}>
              <mesh name="particle481_Green_0" geometry={nodes.particle481_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle482" position={[-0.03, -0.04, 0.09]} rotation={[0.77, -1.02, -1.88]} scale={0.01}>
              <mesh name="particle482_Green_0" geometry={nodes.particle482_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle483" position={[0, -0.03, 0.09]} rotation={[0.36, 1.02, -0.63]} scale={0.01}>
              <mesh name="particle483_Green_0" geometry={nodes.particle483_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle484" position={[-0.01, -0.05, 0.1]} rotation={[0.41, 0.55, -1.26]} scale={0.01}>
              <mesh name="particle484_Green_0" geometry={nodes.particle484_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle485" position={[-0.01, -0.04, 0.17]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle485_Green_0" geometry={nodes.particle485_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle486" position={[-0.04, -0.03, 0.17]} rotation={[-0.36, 1.02, -2.51]} scale={0.01}>
              <mesh name="particle486_Green_0" geometry={nodes.particle486_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle487" position={[-0.01, 0.01, 0.17]} rotation={[0.77, -1.02, 1.26]} scale={0.01}>
              <mesh name="particle487_Green_0" geometry={nodes.particle487_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle488" position={[-0.03, 0.03, 0.15]} rotation={[0.41, 0.55, 1.88]} scale={0.01}>
              <mesh name="particle488_Green_0" geometry={nodes.particle488_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle489" position={[-0.06, -0.01, 0.15]} rotation={[0, 0.55, -Math.PI]} scale={0.01}>
              <mesh name="particle489_Green_0" geometry={nodes.particle489_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle490" position={[-0.04, -0.01, 0.09]} rotation={[0, -1.02, -Math.PI]} scale={0.01}>
              <mesh name="particle490_Green_0" geometry={nodes.particle490_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle491" position={[-0.05, -0.04, 0.1]} rotation={[0.18, -0.55, -2.51]} scale={0.01}>
              <mesh name="particle491_Green_0" geometry={nodes.particle491_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle492" position={[-0.05, 0.01, 0.1]} rotation={[-0.18, -0.55, 2.51]} scale={0.01}>
              <mesh name="particle492_Green_0" geometry={nodes.particle492_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle493" position={[0.03, -0.03, 0.13]} rotation={[0, 0, -0.31]} scale={0.01}>
              <mesh name="particle493_Green_0" geometry={nodes.particle493_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle494" position={[-0.02, -0.06, 0.13]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle494_Green_0" geometry={nodes.particle494_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle495" position={[0.01, -0.05, 0.13]} rotation={[0, 0, -0.94]} scale={0.01}>
              <mesh name="particle495_Green_0" geometry={nodes.particle495_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle496" position={[-0.06, -0.03, 0.13]} rotation={[0, 0, -2.83]} scale={0.01}>
              <mesh name="particle496_Green_0" geometry={nodes.particle496_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle497" position={[-0.05, -0.05, 0.13]} rotation={[0, 0, -2.2]} scale={0.01}>
              <mesh name="particle497_Green_0" geometry={nodes.particle497_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle498" position={[-0.05, 0.03, 0.13]} rotation={[0, 0, 2.2]} scale={0.01}>
              <mesh name="particle498_Green_0" geometry={nodes.particle498_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle499" position={[-0.06, 0, 0.13]} rotation={[0, 0, 2.83]} scale={0.01}>
              <mesh name="particle499_Green_0" geometry={nodes.particle499_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle500" position={[0.01, 0.03, 0.13]} rotation={[0, 0, 0.94]} scale={0.01}>
              <mesh name="particle500_Green_0" geometry={nodes.particle500_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle501" position={[-0.02, 0.04, 0.13]} rotation={[0, 0, Math.PI / 2]} scale={0.01}>
              <mesh name="particle501_Green_0" geometry={nodes.particle501_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle502" position={[0.01, -0.04, 0.15]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle502_Green_0" geometry={nodes.particle502_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle503" position={[-0.03, -0.05, 0.15]} rotation={[-0.41, 0.55, -1.88]} scale={0.01}>
              <mesh name="particle503_Green_0" geometry={nodes.particle503_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle504" position={[0.01, 0.01, 0.15]} rotation={[0.18, -0.55, Math.PI / 5]} scale={0.01}>
              <mesh name="particle504_Green_0" geometry={nodes.particle504_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle505" position={[0.01, -0.01, 0.17]} rotation={[0, -1.02, 0]} scale={0.01}>
              <mesh name="particle505_Green_0" geometry={nodes.particle505_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle506" position={[-0.04, 0, 0.17]} rotation={[0.36, 1.02, 2.51]} scale={0.01}>
              <mesh name="particle506_Green_0" geometry={nodes.particle506_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle507" position={[-0.02, -0.01, 0.18]} rotation={[Math.PI, Math.PI / 2, 0]} scale={0.01}>
              <mesh name="particle507_Green_0" geometry={nodes.particle507_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle508" position={[0.02, -0.01, 0.15]} rotation={[0, -0.46, 0]} scale={0.01}>
              <mesh name="particle508_Green_0" geometry={nodes.particle508_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle509" position={[-0.01, 0.03, 0.15]} rotation={[0.34, -0.46, 1.26]} scale={0.01}>
              <mesh name="particle509_Green_0" geometry={nodes.particle509_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle510" position={[-0.05, 0.01, 0.15]} rotation={[0.15, 0.46, 2.51]} scale={0.01}>
              <mesh name="particle510_Green_0" geometry={nodes.particle510_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle511" position={[-0.05, -0.04, 0.15]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle511_Green_0" geometry={nodes.particle511_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle512" position={[-0.01, -0.05, 0.15]} rotation={[-0.34, -0.46, -1.26]} scale={0.01}>
              <mesh name="particle512_Green_0" geometry={nodes.particle512_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle513" position={[0.02, 0.01, 0.11]} rotation={[-0.15, 0.46, 0.63]} scale={0.01}>
              <mesh name="particle513_Green_0" geometry={nodes.particle513_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle514" position={[-0.03, 0.03, 0.11]} rotation={[-0.34, -0.46, 1.88]} scale={0.01}>
              <mesh name="particle514_Green_0" geometry={nodes.particle514_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle515" position={[-0.06, -0.01, 0.11]} rotation={[0, -0.46, -Math.PI]} scale={0.01}>
              <mesh name="particle515_Green_0" geometry={nodes.particle515_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle516" position={[-0.03, -0.05, 0.11]} rotation={[0.34, -0.46, -1.88]} scale={0.01}>
              <mesh name="particle516_Green_0" geometry={nodes.particle516_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle517" position={[0.02, -0.04, 0.11]} rotation={[0.15, 0.46, -0.63]} scale={0.01}>
              <mesh name="particle517_Green_0" geometry={nodes.particle517_Green_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle518" position={[-0.02, -0.01, 0.08]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={0.01}>
              <mesh name="particle518_Green_0" geometry={nodes.particle518_Green_0.geometry} material={materials.Blue} />
            </group>
          </group>
          <group name="F6Red" position={[356.14, 1981.92, -208.47]} rotation={[-Math.PI / 2, 0, 0]} scale={163.94}>
            <group name="particle114" position={[0.03, 0, 0.13]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle114_Red_0" geometry={nodes.particle114_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle394" position={[0.02, -0.01, 0.1]} rotation={[0, 0.55, 0]} scale={0.01}>
              <mesh name="particle394_Red_0" geometry={nodes.particle394_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle395" position={[0, 0, 0.09]} rotation={[-0.36, 1.02, 0.63]} scale={0.01}>
              <mesh name="particle395_Red_0" geometry={nodes.particle395_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle396" position={[-0.01, 0.03, 0.1]} rotation={[-0.41, 0.55, 1.26]} scale={0.01}>
              <mesh name="particle396_Red_0" geometry={nodes.particle396_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle397" position={[-0.03, 0.01, 0.09]} rotation={[-0.77, -1.02, 1.88]} scale={0.01}>
              <mesh name="particle397_Red_0" geometry={nodes.particle397_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle398" position={[-0.03, -0.04, 0.09]} rotation={[0.77, -1.02, -1.88]} scale={0.01}>
              <mesh name="particle398_Red_0" geometry={nodes.particle398_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle399" position={[0, -0.03, 0.09]} rotation={[0.36, 1.02, -0.63]} scale={0.01}>
              <mesh name="particle399_Red_0" geometry={nodes.particle399_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle400" position={[-0.01, -0.05, 0.1]} rotation={[0.41, 0.55, -1.26]} scale={0.01}>
              <mesh name="particle400_Red_0" geometry={nodes.particle400_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle401" position={[-0.01, -0.04, 0.17]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle401_Red_0" geometry={nodes.particle401_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle402" position={[-0.04, -0.03, 0.17]} rotation={[-0.36, 1.02, -2.51]} scale={0.01}>
              <mesh name="particle402_Red_0" geometry={nodes.particle402_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle403" position={[-0.01, 0.01, 0.17]} rotation={[0.77, -1.02, 1.26]} scale={0.01}>
              <mesh name="particle403_Red_0" geometry={nodes.particle403_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle404" position={[-0.03, 0.03, 0.15]} rotation={[0.41, 0.55, 1.88]} scale={0.01}>
              <mesh name="particle404_Red_0" geometry={nodes.particle404_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle405" position={[-0.06, -0.01, 0.15]} rotation={[0, 0.55, -Math.PI]} scale={0.01}>
              <mesh name="particle405_Red_0" geometry={nodes.particle405_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle406" position={[-0.04, -0.01, 0.09]} rotation={[0, -1.02, -Math.PI]} scale={0.01}>
              <mesh name="particle406_Red_0" geometry={nodes.particle406_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle407" position={[-0.05, -0.04, 0.1]} rotation={[0.18, -0.55, -2.51]} scale={0.01}>
              <mesh name="particle407_Red_0" geometry={nodes.particle407_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle408" position={[-0.05, 0.01, 0.1]} rotation={[-0.18, -0.55, 2.51]} scale={0.01}>
              <mesh name="particle408_Red_0" geometry={nodes.particle408_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle409" position={[0.03, -0.03, 0.13]} rotation={[0, 0, -0.31]} scale={0.01}>
              <mesh name="particle409_Red_0" geometry={nodes.particle409_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle410" position={[-0.02, -0.06, 0.13]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle410_Red_0" geometry={nodes.particle410_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle411" position={[0.01, -0.05, 0.13]} rotation={[0, 0, -0.94]} scale={0.01}>
              <mesh name="particle411_Red_0" geometry={nodes.particle411_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle412" position={[-0.06, -0.03, 0.13]} rotation={[0, 0, -2.83]} scale={0.01}>
              <mesh name="particle412_Red_0" geometry={nodes.particle412_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle413" position={[-0.05, -0.05, 0.13]} rotation={[0, 0, -2.2]} scale={0.01}>
              <mesh name="particle413_Red_0" geometry={nodes.particle413_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle414" position={[-0.05, 0.03, 0.13]} rotation={[0, 0, 2.2]} scale={0.01}>
              <mesh name="particle414_Red_0" geometry={nodes.particle414_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle415" position={[-0.06, 0, 0.13]} rotation={[0, 0, 2.83]} scale={0.01}>
              <mesh name="particle415_Red_0" geometry={nodes.particle415_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle416" position={[0.01, 0.03, 0.13]} rotation={[0, 0, 0.94]} scale={0.01}>
              <mesh name="particle416_Red_0" geometry={nodes.particle416_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle417" position={[-0.02, 0.04, 0.13]} rotation={[0, 0, Math.PI / 2]} scale={0.01}>
              <mesh name="particle417_Red_0" geometry={nodes.particle417_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle418" position={[0.01, -0.04, 0.15]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle418_Red_0" geometry={nodes.particle418_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle419" position={[-0.03, -0.05, 0.15]} rotation={[-0.41, 0.55, -1.88]} scale={0.01}>
              <mesh name="particle419_Red_0" geometry={nodes.particle419_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle420" position={[0.01, 0.01, 0.15]} rotation={[0.18, -0.55, Math.PI / 5]} scale={0.01}>
              <mesh name="particle420_Red_0" geometry={nodes.particle420_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle421" position={[0.01, -0.01, 0.17]} rotation={[0, -1.02, 0]} scale={0.01}>
              <mesh name="particle421_Red_0" geometry={nodes.particle421_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle422" position={[-0.04, 0, 0.17]} rotation={[0.36, 1.02, 2.51]} scale={0.01}>
              <mesh name="particle422_Red_0" geometry={nodes.particle422_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle423" position={[-0.02, -0.01, 0.18]} rotation={[Math.PI, Math.PI / 2, 0]} scale={0.01}>
              <mesh name="particle423_Red_0" geometry={nodes.particle423_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle424" position={[0.02, -0.01, 0.15]} rotation={[0, -0.46, 0]} scale={0.01}>
              <mesh name="particle424_Red_0" geometry={nodes.particle424_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle425" position={[-0.01, 0.03, 0.15]} rotation={[0.34, -0.46, 1.26]} scale={0.01}>
              <mesh name="particle425_Red_0" geometry={nodes.particle425_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle426" position={[-0.05, 0.01, 0.15]} rotation={[0.15, 0.46, 2.51]} scale={0.01}>
              <mesh name="particle426_Red_0" geometry={nodes.particle426_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle427" position={[-0.05, -0.04, 0.15]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle427_Red_0" geometry={nodes.particle427_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle428" position={[-0.01, -0.05, 0.15]} rotation={[-0.34, -0.46, -1.26]} scale={0.01}>
              <mesh name="particle428_Red_0" geometry={nodes.particle428_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle429" position={[0.02, 0.01, 0.11]} rotation={[-0.15, 0.46, 0.63]} scale={0.01}>
              <mesh name="particle429_Red_0" geometry={nodes.particle429_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle430" position={[-0.03, 0.03, 0.11]} rotation={[-0.34, -0.46, 1.88]} scale={0.01}>
              <mesh name="particle430_Red_0" geometry={nodes.particle430_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle431" position={[-0.06, -0.01, 0.11]} rotation={[0, -0.46, -Math.PI]} scale={0.01}>
              <mesh name="particle431_Red_0" geometry={nodes.particle431_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle432" position={[-0.03, -0.05, 0.11]} rotation={[0.34, -0.46, -1.88]} scale={0.01}>
              <mesh name="particle432_Red_0" geometry={nodes.particle432_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle433" position={[0.02, -0.04, 0.11]} rotation={[0.15, 0.46, -0.63]} scale={0.01}>
              <mesh name="particle433_Red_0" geometry={nodes.particle433_Red_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle434" position={[-0.02, -0.01, 0.08]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={0.01}>
              <mesh name="particle434_Red_0" geometry={nodes.particle434_Red_0.geometry} material={materials.Blue} />
            </group>
          </group>
          <group name="F6Yellow" position={[1.83, 2155.58, -39.63]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group name="particle115" position={[0.03, 0, 0.13]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle115_Yellow_0" geometry={nodes.particle115_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle116" position={[0.02, -0.01, 0.1]} rotation={[0, 0.55, 0]} scale={0.01}>
              <mesh name="particle116_Yellow_0" geometry={nodes.particle116_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle117" position={[0, 0, 0.09]} rotation={[-0.36, 1.02, 0.63]} scale={0.01}>
              <mesh name="particle117_Yellow_0" geometry={nodes.particle117_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle118" position={[-0.01, 0.03, 0.1]} rotation={[-0.41, 0.55, 1.26]} scale={0.01}>
              <mesh name="particle118_Yellow_0" geometry={nodes.particle118_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle119" position={[-0.03, 0.01, 0.09]} rotation={[-0.77, -1.02, 1.88]} scale={0.01}>
              <mesh name="particle119_Yellow_0" geometry={nodes.particle119_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle120" position={[-0.03, -0.04, 0.09]} rotation={[0.77, -1.02, -1.88]} scale={0.01}>
              <mesh name="particle120_Yellow_0" geometry={nodes.particle120_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle121" position={[0, -0.03, 0.09]} rotation={[0.36, 1.02, -0.63]} scale={0.01}>
              <mesh name="particle121_Yellow_0" geometry={nodes.particle121_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle122" position={[-0.01, -0.05, 0.1]} rotation={[0.41, 0.55, -1.26]} scale={0.01}>
              <mesh name="particle122_Yellow_0" geometry={nodes.particle122_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle123" position={[-0.01, -0.04, 0.17]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle123_Yellow_0" geometry={nodes.particle123_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle124" position={[-0.04, -0.03, 0.17]} rotation={[-0.36, 1.02, -2.51]} scale={0.01}>
              <mesh name="particle124_Yellow_0" geometry={nodes.particle124_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle125" position={[-0.01, 0.01, 0.17]} rotation={[0.77, -1.02, 1.26]} scale={0.01}>
              <mesh name="particle125_Yellow_0" geometry={nodes.particle125_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle126" position={[-0.03, 0.03, 0.15]} rotation={[0.41, 0.55, 1.88]} scale={0.01}>
              <mesh name="particle126_Yellow_0" geometry={nodes.particle126_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle127" position={[-0.06, -0.01, 0.15]} rotation={[0, 0.55, -Math.PI]} scale={0.01}>
              <mesh name="particle127_Yellow_0" geometry={nodes.particle127_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle128" position={[-0.04, -0.01, 0.09]} rotation={[0, -1.02, -Math.PI]} scale={0.01}>
              <mesh name="particle128_Yellow_0" geometry={nodes.particle128_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle129" position={[-0.05, -0.04, 0.1]} rotation={[0.18, -0.55, -2.51]} scale={0.01}>
              <mesh name="particle129_Yellow_0" geometry={nodes.particle129_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle130" position={[-0.05, 0.01, 0.1]} rotation={[-0.18, -0.55, 2.51]} scale={0.01}>
              <mesh name="particle130_Yellow_0" geometry={nodes.particle130_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle131" position={[0.03, -0.03, 0.13]} rotation={[0, 0, -0.31]} scale={0.01}>
              <mesh name="particle131_Yellow_0" geometry={nodes.particle131_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle132" position={[-0.02, -0.06, 0.13]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle132_Yellow_0" geometry={nodes.particle132_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle133" position={[0.01, -0.05, 0.13]} rotation={[0, 0, -0.94]} scale={0.01}>
              <mesh name="particle133_Yellow_0" geometry={nodes.particle133_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle134" position={[-0.06, -0.03, 0.13]} rotation={[0, 0, -2.83]} scale={0.01}>
              <mesh name="particle134_Yellow_0" geometry={nodes.particle134_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle135" position={[-0.05, -0.05, 0.13]} rotation={[0, 0, -2.2]} scale={0.01}>
              <mesh name="particle135_Yellow_0" geometry={nodes.particle135_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle136" position={[-0.05, 0.03, 0.13]} rotation={[0, 0, 2.2]} scale={0.01}>
              <mesh name="particle136_Yellow_0" geometry={nodes.particle136_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle137" position={[-0.06, 0, 0.13]} rotation={[0, 0, 2.83]} scale={0.01}>
              <mesh name="particle137_Yellow_0" geometry={nodes.particle137_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle138" position={[0.01, 0.03, 0.13]} rotation={[0, 0, 0.94]} scale={0.01}>
              <mesh name="particle138_Yellow_0" geometry={nodes.particle138_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle139" position={[-0.02, 0.04, 0.13]} rotation={[0, 0, Math.PI / 2]} scale={0.01}>
              <mesh name="particle139_Yellow_0" geometry={nodes.particle139_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle140" position={[0.01, -0.04, 0.15]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle140_Yellow_0" geometry={nodes.particle140_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle141" position={[-0.03, -0.05, 0.15]} rotation={[-0.41, 0.55, -1.88]} scale={0.01}>
              <mesh name="particle141_Yellow_0" geometry={nodes.particle141_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle142" position={[0.01, 0.01, 0.15]} rotation={[0.18, -0.55, Math.PI / 5]} scale={0.01}>
              <mesh name="particle142_Yellow_0" geometry={nodes.particle142_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle143" position={[0.01, -0.01, 0.17]} rotation={[0, -1.02, 0]} scale={0.01}>
              <mesh name="particle143_Yellow_0" geometry={nodes.particle143_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle144" position={[-0.04, 0, 0.17]} rotation={[0.36, 1.02, 2.51]} scale={0.01}>
              <mesh name="particle144_Yellow_0" geometry={nodes.particle144_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle145" position={[-0.02, -0.01, 0.18]} rotation={[Math.PI, Math.PI / 2, 0]} scale={0.01}>
              <mesh name="particle145_Yellow_0" geometry={nodes.particle145_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle146" position={[0.02, -0.01, 0.15]} rotation={[0, -0.46, 0]} scale={0.01}>
              <mesh name="particle146_Yellow_0" geometry={nodes.particle146_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle147" position={[-0.01, 0.03, 0.15]} rotation={[0.34, -0.46, 1.26]} scale={0.01}>
              <mesh name="particle147_Yellow_0" geometry={nodes.particle147_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle148" position={[-0.05, 0.01, 0.15]} rotation={[0.15, 0.46, 2.51]} scale={0.01}>
              <mesh name="particle148_Yellow_0" geometry={nodes.particle148_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle149" position={[-0.05, -0.04, 0.15]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle149_Yellow_0" geometry={nodes.particle149_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle150" position={[-0.01, -0.05, 0.15]} rotation={[-0.34, -0.46, -1.26]} scale={0.01}>
              <mesh name="particle150_Yellow_0" geometry={nodes.particle150_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle151" position={[0.02, 0.01, 0.11]} rotation={[-0.15, 0.46, 0.63]} scale={0.01}>
              <mesh name="particle151_Yellow_0" geometry={nodes.particle151_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle152" position={[-0.03, 0.03, 0.11]} rotation={[-0.34, -0.46, 1.88]} scale={0.01}>
              <mesh name="particle152_Yellow_0" geometry={nodes.particle152_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle153" position={[-0.06, -0.01, 0.11]} rotation={[0, -0.46, -Math.PI]} scale={0.01}>
              <mesh name="particle153_Yellow_0" geometry={nodes.particle153_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle154" position={[-0.03, -0.05, 0.11]} rotation={[0.34, -0.46, -1.88]} scale={0.01}>
              <mesh name="particle154_Yellow_0" geometry={nodes.particle154_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle155" position={[0.02, -0.04, 0.11]} rotation={[0.15, 0.46, -0.63]} scale={0.01}>
              <mesh name="particle155_Yellow_0" geometry={nodes.particle155_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle156" position={[-0.02, -0.01, 0.08]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={0.01}>
              <mesh name="particle156_Yellow_0" geometry={nodes.particle156_Yellow_0.geometry} material={materials.Blue} />
            </group>
          </group>
          <group name="F7" position={[1.03, 1808.97, 375.46]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group name="particle157" position={[0.04, 0.04, -0.02]} rotation={[0, 0, 0.31]} scale={0.01}>
              <mesh name="particle157_Yellow_0" geometry={nodes.particle157_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle158" position={[0, 0, 0.02]} rotation={[-0.77, -1.02, -1.26]} scale={0.01}>
              <mesh name="particle158_Yellow_0" geometry={nodes.particle158_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle159" position={[-0.01, -0.03, -0.02]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle159_Yellow_0" geometry={nodes.particle159_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle160" position={[0.02, 0, 0.01]} rotation={[-0.18, -0.55, -Math.PI / 5]} scale={0.01}>
              <mesh name="particle160_Yellow_0" geometry={nodes.particle160_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle161" position={[-0.04, 0, 0]} rotation={[-0.15, 0.46, -2.51]} scale={0.01}>
              <mesh name="particle161_Yellow_0" geometry={nodes.particle161_Yellow_0.geometry} material={materials.Blue} />
            </group>
          </group>
          <group name="F8" position={[0, 2189.6, -4.11]} rotation={[-1.71, 0.17, 0.07]} scale={192.88}>
            <group name="particle162" position={[0, 0.75, 0]} rotation={[0, 0, Math.PI / 2]} scale={0.01}>
              <mesh name="particle162_Yellow_0" geometry={nodes.particle162_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle163" position={[-0.15, 0.74, 0]} rotation={[0, 0, 1.77]} scale={0.01}>
              <mesh name="particle163_Yellow_0" geometry={nodes.particle163_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle164" position={[-0.29, 0.69, 0]} rotation={[0, 0, 1.96]} scale={0.01}>
              <mesh name="particle164_Yellow_0" geometry={nodes.particle164_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle165" position={[-0.42, 0.62, 0]} rotation={[0, 0, 2.16]} scale={0.01}>
              <mesh name="particle165_Yellow_0" geometry={nodes.particle165_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle166" position={[-0.53, 0.53, 0]} rotation={[0, 0, 2.36]} scale={0.01}>
              <mesh name="particle166_Yellow_0" geometry={nodes.particle166_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle167" position={[-0.62, 0.42, 0]} rotation={[0, 0, 2.55]} scale={0.01}>
              <mesh name="particle167_Yellow_0" geometry={nodes.particle167_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle168" position={[-0.69, 0.29, 0]} rotation={[0, 0, 2.75]} scale={0.01}>
              <mesh name="particle168_Yellow_0" geometry={nodes.particle168_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle169" position={[-0.74, 0.15, 0]} rotation={[0, 0, 2.95]} scale={0.01}>
              <mesh name="particle169_Yellow_0" geometry={nodes.particle169_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle170" position={[-0.75, 0, 0]} rotation={[0, 0, Math.PI]} scale={0.01}>
              <mesh name="particle170_Yellow_0" geometry={nodes.particle170_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle171" position={[-0.74, -0.15, 0]} rotation={[0, 0, -2.95]} scale={0.01}>
              <mesh name="particle171_Yellow_0" geometry={nodes.particle171_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle172" position={[-0.69, -0.29, 0]} rotation={[0, 0, -2.75]} scale={0.01}>
              <mesh name="particle172_Yellow_0" geometry={nodes.particle172_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle173" position={[-0.62, -0.42, 0]} rotation={[0, 0, -2.55]} scale={0.01}>
              <mesh name="particle173_Yellow_0" geometry={nodes.particle173_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle174" position={[-0.53, -0.53, 0]} rotation={[0, 0, -2.36]} scale={0.01}>
              <mesh name="particle174_Yellow_0" geometry={nodes.particle174_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle175" position={[-0.42, -0.62, 0]} rotation={[0, 0, -2.16]} scale={0.01}>
              <mesh name="particle175_Yellow_0" geometry={nodes.particle175_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle176" position={[-0.29, -0.69, 0]} rotation={[0, 0, -1.96]} scale={0.01}>
              <mesh name="particle176_Yellow_0" geometry={nodes.particle176_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle177" position={[-0.15, -0.74, 0]} rotation={[0, 0, -1.77]} scale={0.01}>
              <mesh name="particle177_Yellow_0" geometry={nodes.particle177_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle178" position={[0, -0.75, 0]} rotation={[0, 0, -Math.PI / 2]} scale={0.01}>
              <mesh name="particle178_Yellow_0" geometry={nodes.particle178_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle179" position={[0.15, -0.74, 0]} rotation={[0, 0, -1.37]} scale={0.01}>
              <mesh name="particle179_Yellow_0" geometry={nodes.particle179_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle180" position={[0.29, -0.69, 0]} rotation={[0, 0, -1.18]} scale={0.01}>
              <mesh name="particle180_Yellow_0" geometry={nodes.particle180_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle181" position={[0.42, -0.62, 0]} rotation={[0, 0, -0.98]} scale={0.01}>
              <mesh name="particle181_Yellow_0" geometry={nodes.particle181_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle182" position={[0.53, -0.53, 0]} rotation={[0, 0, -Math.PI / 4]} scale={0.01}>
              <mesh name="particle182_Yellow_0" geometry={nodes.particle182_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle183" position={[0.62, -0.42, 0]} rotation={[0, 0, -0.59]} scale={0.01}>
              <mesh name="particle183_Yellow_0" geometry={nodes.particle183_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle184" position={[0.69, -0.29, 0]} rotation={[0, 0, -Math.PI / 8]} scale={0.01}>
              <mesh name="particle184_Yellow_0" geometry={nodes.particle184_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle185" position={[0.74, -0.15, 0]} rotation={[0, 0, -0.2]} scale={0.01}>
              <mesh name="particle185_Yellow_0" geometry={nodes.particle185_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle186" position={[0.75, 0, 0]} scale={0.01}>
              <mesh name="particle186_Yellow_0" geometry={nodes.particle186_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle187" position={[0.74, 0.15, 0]} rotation={[0, 0, 0.2]} scale={0.01}>
              <mesh name="particle187_Yellow_0" geometry={nodes.particle187_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle188" position={[0.69, 0.29, 0]} rotation={[0, 0, Math.PI / 8]} scale={0.01}>
              <mesh name="particle188_Yellow_0" geometry={nodes.particle188_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle189" position={[0.62, 0.42, 0]} rotation={[0, 0, 0.59]} scale={0.01}>
              <mesh name="particle189_Yellow_0" geometry={nodes.particle189_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle190" position={[0.53, 0.53, 0]} rotation={[0, 0, Math.PI / 4]} scale={0.01}>
              <mesh name="particle190_Yellow_0" geometry={nodes.particle190_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle191" position={[0.42, 0.62, 0]} rotation={[0, 0, 0.98]} scale={0.01}>
              <mesh name="particle191_Yellow_0" geometry={nodes.particle191_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle192" position={[0.29, 0.69, 0]} rotation={[0, 0, 1.18]} scale={0.01}>
              <mesh name="particle192_Yellow_0" geometry={nodes.particle192_Yellow_0.geometry} material={materials.Blue} />
            </group>
            <group name="particle193" position={[0.15, 0.74, 0]} rotation={[0, 0, 1.37]} scale={0.01}>
              <mesh name="particle193_Yellow_0" geometry={nodes.particle193_Yellow_0.geometry} material={materials.Blue} />
            </group>
          </group>
          <group name="F9" position={[5.96, 0, -0.61]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group name="particle194" rotation={[0.71, -1.52, 0.78]} scale={0.81}>
              <mesh name="particle194_Yellow_0" geometry={nodes.particle194_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle195" position={[-0.15, -0.06, 0]} rotation={[2.38, -1.5, 2.26]} scale={0.01}>
              <mesh name="particle195_Yellow_0" geometry={nodes.particle195_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle196" position={[-0.15, -0.17, 0]} rotation={[-1.04, -1.47, -0.94]} scale={0.01}>
              <mesh name="particle196_Yellow_0" geometry={nodes.particle196_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle197" position={[0.06, -0.11, 0]} rotation={[0.04, -1.46, -0.05]} scale={0.01}>
              <mesh name="particle197_Yellow_0" geometry={nodes.particle197_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle198" position={[-0.16, 0.07, 0]} rotation={[1.54, -1.51, 1.62]} scale={0.01}>
              <mesh name="particle198_Yellow_0" geometry={nodes.particle198_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle199" position={[-0.15, -0.14, 0.55]} rotation={[1.91, -1.5, 2.03]} scale={0.01}>
              <mesh name="particle199_Yellow_0" geometry={nodes.particle199_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle200" position={[-0.13, 0.05, 0.65]} rotation={[-1.73, 0.3, -1.5]} scale={0.01}>
              <mesh name="particle200_Yellow_0" geometry={nodes.particle200_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle201" position={[-0.18, 0.03, 0.77]} rotation={[-1.71, -0.86, -1.89]} scale={0.01}>
              <mesh name="particle201_Yellow_0" geometry={nodes.particle201_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle202" position={[-0.01, -0.12, 0.88]} rotation={[1.5, 0.84, 1.63]} scale={0.01}>
              <mesh name="particle202_Yellow_0" geometry={nodes.particle202_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle203" position={[-0.13, -0.16, 0.98]} rotation={[1.45, 0.49, 1.69]} scale={0.01}>
              <mesh name="particle203_Yellow_0" geometry={nodes.particle203_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle204" position={[-0.14, -0.08, 1.09]} rotation={[1.74, 0.25, 1.47]} scale={0.01}>
              <mesh name="particle204_Yellow_0" geometry={nodes.particle204_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle205" position={[-0.1, -0.14, 1.18]} rotation={[-2.26, 1.35, -0.77]} scale={0.01}>
              <mesh name="particle205_Yellow_0" geometry={nodes.particle205_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle206" position={[-0.06, -0.03, 1.31]} rotation={[-1.44, 0.81, -1.74]} scale={0.01}>
              <mesh name="particle206_Yellow_0" geometry={nodes.particle206_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle207" position={[0, -0.1, 1.42]} rotation={[-1.41, -0.91, -1.53]} scale={0.01}>
              <mesh name="particle207_Yellow_0" geometry={nodes.particle207_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle208" position={[-0.08, 0, 1.53]} rotation={[-1.71, -0.67, -1.56]} scale={0.01}>
              <mesh name="particle208_Yellow_0" geometry={nodes.particle208_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle209" position={[-0.04, -0.13, 1.63]} rotation={[-1.7, 0.75, -1.62]} scale={0.01}>
              <mesh name="particle209_Yellow_0" geometry={nodes.particle209_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle210" position={[-0.07, -0.04, 1.75]} rotation={[1.52, 0.86, 1.65]} scale={0.01}>
              <mesh name="particle210_Yellow_0" geometry={nodes.particle210_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle211" position={[-0.04, 0.03, 1.86]} rotation={[-1.35, -0.72, -1.55]} scale={0.01}>
              <mesh name="particle211_Yellow_0" geometry={nodes.particle211_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle212" position={[-0.03, -0.12, 1.97]} rotation={[-1.59, 1.1, -1.58]} scale={0.01}>
              <mesh name="particle212_Yellow_0" geometry={nodes.particle212_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle213" position={[0, -0.04, 2.08]} rotation={[1.24, 1.21, 2.06]} scale={0.01}>
              <mesh name="particle213_Yellow_0" geometry={nodes.particle213_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle214" position={[-0.11, -0.15, 2.18]} rotation={[-1.55, 0.37, -1.59]} scale={0.01}>
              <mesh name="particle214_Yellow_0" geometry={nodes.particle214_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle215" position={[-0.19, -0.08, 2.29]} rotation={[2.02, 1.25, 1.21]} scale={0.01}>
              <mesh name="particle215_Yellow_0" geometry={nodes.particle215_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle216" position={[0.03, -0.08, 2.41]} rotation={[1.39, -0.68, 1.3]} scale={0.01}>
              <mesh name="particle216_Yellow_0" geometry={nodes.particle216_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle217" position={[-0.11, -0.04, 2.51]} rotation={[-1.61, 0.01, -1.64]} scale={0.01}>
              <mesh name="particle217_Yellow_0" geometry={nodes.particle217_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle218" position={[-0.05, -0.01, 2.61]} rotation={[-1.56, 0, -1.37]} scale={0.01}>
              <mesh name="particle218_Yellow_0" geometry={nodes.particle218_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle219" position={[-0.11, -0.09, 2.73]} rotation={[-1.59, 0.4, -1.51]} scale={0.01}>
              <mesh name="particle219_Yellow_0" geometry={nodes.particle219_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle220" position={[-0.1, -0.11, 2.83]} rotation={[-1.65, -0.11, -1.78]} scale={0.01}>
              <mesh name="particle220_Yellow_0" geometry={nodes.particle220_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle221" position={[-0.16, 0.02, 2.96]} rotation={[-1.61, 0.18, -1.49]} scale={0.01}>
              <mesh name="particle221_Yellow_0" geometry={nodes.particle221_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle222" position={[-0.13, -0.11, 3.07]} rotation={[-1.53, 0.98, -1.62]} scale={0.01}>
              <mesh name="particle222_Yellow_0" geometry={nodes.particle222_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle223" position={[-0.1, -0.13, 3.17]} rotation={[-1.21, 1, -1.93]} scale={0.01}>
              <mesh name="particle223_Yellow_0" geometry={nodes.particle223_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle224" position={[-0.12, -0.13, 3.28]} rotation={[-1.73, -0.66, -1.83]} scale={0.01}>
              <mesh name="particle224_Yellow_0" geometry={nodes.particle224_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle225" position={[-0.08, -0.07, 3.39]} rotation={[1.59, 1.38, 1.62]} scale={0.01}>
              <mesh name="particle225_Yellow_0" geometry={nodes.particle225_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle226" position={[-0.09, -0.01, 3.49]} rotation={[-1.38, -0.94, -1.54]} scale={0.01}>
              <mesh name="particle226_Yellow_0" geometry={nodes.particle226_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle227" position={[0.05, 0.01, 3.61]} rotation={[-0.35, 1.49, -2.72]} scale={0.01}>
              <mesh name="particle227_Yellow_0" geometry={nodes.particle227_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle228" position={[-0.01, 0.04, 3.71]} rotation={[-1.88, -1.16, -1.79]} scale={0.01}>
              <mesh name="particle228_Yellow_0" geometry={nodes.particle228_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle229" position={[-0.12, 0.06, 3.81]} rotation={[1.7, 0.79, 1.49]} scale={0.01}>
              <mesh name="particle229_Yellow_0" geometry={nodes.particle229_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle230" position={[0.04, 0.03, 3.92]} rotation={[-2.23, -1.23, -2.19]} scale={0.01}>
              <mesh name="particle230_Yellow_0" geometry={nodes.particle230_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle231" position={[-0.13, -0.03, 4.04]} rotation={[-0.97, -1.34, -0.77]} scale={0.01}>
              <mesh name="particle231_Yellow_0" geometry={nodes.particle231_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle232" position={[-0.15, 0, 4.14]} rotation={[1.61, 1.01, 1.52]} scale={0.01}>
              <mesh name="particle232_Yellow_0" geometry={nodes.particle232_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle233" position={[0.05, 0.01, 4.27]} rotation={[-1.9, -1.11, -1.83]} scale={0.01}>
              <mesh name="particle233_Yellow_0" geometry={nodes.particle233_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle234" position={[0.05, -0.06, 4.37]} rotation={[1, -1.28, 1.02]} scale={0.01}>
              <mesh name="particle234_Yellow_0" geometry={nodes.particle234_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle235" position={[-0.02, -0.01, 4.48]} rotation={[2.17, -1.28, 2.32]} scale={0.01}>
              <mesh name="particle235_Yellow_0" geometry={nodes.particle235_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle236" position={[-0.07, 0.07, 4.6]} rotation={[-1.62, -0.46, -1.65]} scale={0.01}>
              <mesh name="particle236_Yellow_0" geometry={nodes.particle236_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle237" position={[-0.04, -0.13, 4.7]} rotation={[1.19, -1.27, 1.34]} scale={0.01}>
              <mesh name="particle237_Yellow_0" geometry={nodes.particle237_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle238" position={[0.01, -0.06, 4.79]} rotation={[0.76, 1.43, 2.43]} scale={0.01}>
              <mesh name="particle238_Yellow_0" geometry={nodes.particle238_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle239" position={[0.03, 0.04, 4.91]} rotation={[1.59, -0.99, 1.61]} scale={0.01}>
              <mesh name="particle239_Yellow_0" geometry={nodes.particle239_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle240" position={[-0.1, 0, 5.03]} rotation={[-1.64, -0.64, -1.82]} scale={0.01}>
              <mesh name="particle240_Yellow_0" geometry={nodes.particle240_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle241" position={[-0.18, -0.12, 5.13]} rotation={[1.71, -1, 1.65]} scale={0.01}>
              <mesh name="particle241_Yellow_0" geometry={nodes.particle241_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle242" position={[-0.18, -0.01, 5.23]} rotation={[-2.09, 1.32, -0.91]} scale={0.01}>
              <mesh name="particle242_Yellow_0" geometry={nodes.particle242_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle243" position={[-0.05, -0.08, 5.26]} rotation={[-1.18, -1.41, -1.16]} scale={0.01}>
              <mesh name="particle243_Yellow_0" geometry={nodes.particle243_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle244" position={[0, -0.11, 5.45]} rotation={[-1.88, 1, -1.46]} scale={0.01}>
              <mesh name="particle244_Yellow_0" geometry={nodes.particle244_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle245" position={[-0.19, -0.14, 5.57]} rotation={[1.63, -0.5, 1.76]} scale={0.01}>
              <mesh name="particle245_Yellow_0" geometry={nodes.particle245_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle246" position={[-0.1, -0.09, 5.69]} rotation={[1.59, -0.19, 1.57]} scale={0.01}>
              <mesh name="particle246_Yellow_0" geometry={nodes.particle246_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle247" position={[-0.13, 0.08, 5.8]} rotation={[1.4, -0.25, 1.71]} scale={0.01}>
              <mesh name="particle247_Yellow_0" geometry={nodes.particle247_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle248" position={[0.01, -0.02, 5.89]} rotation={[-1.55, 0.78, -1.55]} scale={0.01}>
              <mesh name="particle248_Yellow_0" geometry={nodes.particle248_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle249" position={[0.02, -0.16, 6.01]} rotation={[1.43, 0, 1.49]} scale={0.01}>
              <mesh name="particle249_Yellow_0" geometry={nodes.particle249_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle250" position={[-0.04, -0.07, 6.13]} rotation={[1.2, -1.23, 1.28]} scale={0.01}>
              <mesh name="particle250_Yellow_0" geometry={nodes.particle250_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle251" position={[0.03, -0.1, 6.23]} rotation={[1.73, 0.09, 1.51]} scale={0.01}>
              <mesh name="particle251_Yellow_0" geometry={nodes.particle251_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle252" position={[-0.12, 0.03, 6.35]} rotation={[1.75, 0.9, 1.6]} scale={0.01}>
              <mesh name="particle252_Yellow_0" geometry={nodes.particle252_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle253" position={[0.03, -0.04, 6.46]} rotation={[1.45, 0.86, 1.61]} scale={0.01}>
              <mesh name="particle253_Yellow_0" geometry={nodes.particle253_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle254" position={[-0.17, 0, 6.56]} rotation={[1.47, 0.32, 1.53]} scale={0.01}>
              <mesh name="particle254_Yellow_0" geometry={nodes.particle254_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle255" position={[-0.02, 0.07, 6.68]} rotation={[1.7, 0.04, 1.45]} scale={0.01}>
              <mesh name="particle255_Yellow_0" geometry={nodes.particle255_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle256" position={[-0.17, 0.02, 6.77]} rotation={[-1.66, -0.31, -1.47]} scale={0.01}>
              <mesh name="particle256_Yellow_0" geometry={nodes.particle256_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle257" position={[-0.12, -0.1, 6.88]} rotation={[1.5, 0.8, 1.61]} scale={0.01}>
              <mesh name="particle257_Yellow_0" geometry={nodes.particle257_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle258" position={[0.04, -0.16, 7]} rotation={[1.3, 0.72, 1.69]} scale={0.01}>
              <mesh name="particle258_Yellow_0" geometry={nodes.particle258_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle259" position={[0, -0.01, 7.09]} rotation={[1.87, 1.12, 1.14]} scale={0.01}>
              <mesh name="particle259_Yellow_0" geometry={nodes.particle259_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle260" position={[-0.09, 0.01, 7.23]} rotation={[-1.55, -0.94, -1.46]} scale={0.01}>
              <mesh name="particle260_Yellow_0" geometry={nodes.particle260_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle261" position={[-0.14, -0.05, 7.32]} rotation={[-1.47, -0.74, -1.47]} scale={0.01}>
              <mesh name="particle261_Yellow_0" geometry={nodes.particle261_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle262" position={[-0.14, -0.1, 7.44]} rotation={[1.72, -0.06, 1.69]} scale={0.01}>
              <mesh name="particle262_Yellow_0" geometry={nodes.particle262_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle263" position={[-0.14, 0.04, 7.55]} rotation={[-1.67, -0.25, -1.6]} scale={0.01}>
              <mesh name="particle263_Yellow_0" geometry={nodes.particle263_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle264" position={[-0.1, 0.05, 7.64]} rotation={[-1.34, -0.7, -1.37]} scale={0.01}>
              <mesh name="particle264_Yellow_0" geometry={nodes.particle264_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle265" position={[-0.12, -0.03, 7.77]} rotation={[0.14, 1.41, -3.12]} scale={0.01}>
              <mesh name="particle265_Yellow_0" geometry={nodes.particle265_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle266" position={[-0.02, 0, 7.86]} rotation={[-1.58, -1.09, -1.47]} scale={0.01}>
              <mesh name="particle266_Yellow_0" geometry={nodes.particle266_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle267" position={[-0.04, 0, 7.99]} rotation={[-1.62, 1.17, -1.6]} scale={0.01}>
              <mesh name="particle267_Yellow_0" geometry={nodes.particle267_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle268" position={[-0.05, -0.14, 8.1]} rotation={[-1.45, -0.58, -1.49]} scale={0.01}>
              <mesh name="particle268_Yellow_0" geometry={nodes.particle268_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle269" position={[0.07, -0.05, 8.2]} rotation={[-1.03, 1.17, -2.16]} scale={0.01}>
              <mesh name="particle269_Yellow_0" geometry={nodes.particle269_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle270" position={[0.04, 0.06, 8.32]} rotation={[-1.78, 1.12, -1.56]} scale={0.01}>
              <mesh name="particle270_Yellow_0" geometry={nodes.particle270_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle271" position={[-0.07, -0.02, 8.41]} rotation={[-2.03, -1.52, -1.94]} scale={0.01}>
              <mesh name="particle271_Yellow_0" geometry={nodes.particle271_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle272" position={[-0.09, -0.12, 8.54]} rotation={[1.47, 1.06, 1.54]} scale={0.01}>
              <mesh name="particle272_Yellow_0" geometry={nodes.particle272_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle273" position={[-0.16, -0.01, 8.62]} rotation={[1.48, 0.36, 1.56]} scale={0.01}>
              <mesh name="particle273_Yellow_0" geometry={nodes.particle273_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle274" position={[-0.01, -0.1, 8.75]} rotation={[-1.74, 0.61, -1.34]} scale={0.01}>
              <mesh name="particle274_Yellow_0" geometry={nodes.particle274_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle275" position={[-0.17, -0.03, 8.86]} rotation={[-1.63, 0.44, -1.52]} scale={0.01}>
              <mesh name="particle275_Yellow_0" geometry={nodes.particle275_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle276" position={[-0.05, -0.02, 8.98]} rotation={[-2.01, 1.09, -1.26]} scale={0.01}>
              <mesh name="particle276_Yellow_0" geometry={nodes.particle276_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle277" position={[-0.09, -0.1, 9.08]} rotation={[-1.49, 0.51, -1.47]} scale={0.01}>
              <mesh name="particle277_Yellow_0" geometry={nodes.particle277_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle278" position={[0.06, -0.16, 9.19]} rotation={[-1.48, 0.38, -1.69]} scale={0.01}>
              <mesh name="particle278_Yellow_0" geometry={nodes.particle278_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle279" position={[-0.13, -0.1, 9.29]} rotation={[1.72, -0.79, 1.73]} scale={0.01}>
              <mesh name="particle279_Yellow_0" geometry={nodes.particle279_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle280" position={[-0.05, 0.05, 9.41]} rotation={[-1.58, -0.03, -1.72]} scale={0.01}>
              <mesh name="particle280_Yellow_0" geometry={nodes.particle280_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle281" position={[0.03, -0.14, 9.52]} rotation={[0.34, 1.46, 2.73]} scale={0.01}>
              <mesh name="particle281_Yellow_0" geometry={nodes.particle281_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle282" position={[0.04, 0.06, 9.61]} rotation={[-1.9, 1.08, -1.23]} scale={0.01}>
              <mesh name="particle282_Yellow_0" geometry={nodes.particle282_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle283" position={[-0.17, -0.05, 9.73]} rotation={[1.61, -0.39, 1.37]} scale={0.01}>
              <mesh name="particle283_Yellow_0" geometry={nodes.particle283_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle284" position={[-0.16, -0.06, 9.85]} rotation={[-1.56, 0.26, -1.47]} scale={0.01}>
              <mesh name="particle284_Yellow_0" geometry={nodes.particle284_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle285" position={[-0.15, -0.03, 9.94]} rotation={[-1.66, 0.81, -1.63]} scale={0.01}>
              <mesh name="particle285_Yellow_0" geometry={nodes.particle285_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle286" position={[-0.19, 0.05, 10.07]} rotation={[-1.56, -0.47, -1.41]} scale={0.01}>
              <mesh name="particle286_Yellow_0" geometry={nodes.particle286_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle287" position={[-0.12, -0.05, 10.15]} rotation={[1.57, 0.14, 1.59]} scale={0.01}>
              <mesh name="particle287_Yellow_0" geometry={nodes.particle287_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle288" position={[-0.05, -0.05, 10.28]} rotation={[-1.79, -0.68, -1.77]} scale={0.01}>
              <mesh name="particle288_Yellow_0" geometry={nodes.particle288_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle289" position={[0.03, -0.05, 10.39]} rotation={[-1.44, -0.47, -1.52]} scale={0.01}>
              <mesh name="particle289_Yellow_0" geometry={nodes.particle289_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle290" position={[-0.09, 0.04, 10.51]} rotation={[-1.9, -0.82, -1.9]} scale={0.01}>
              <mesh name="particle290_Yellow_0" geometry={nodes.particle290_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle291" position={[-0.12, -0.16, 10.6]} rotation={[-1.59, 0.62, -1.45]} scale={0.01}>
              <mesh name="particle291_Yellow_0" geometry={nodes.particle291_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle292" position={[-0.17, -0.13, 10.73]} rotation={[-1.41, -0.53, -1.58]} scale={0.01}>
              <mesh name="particle292_Yellow_0" geometry={nodes.particle292_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle293" position={[-0.09, 0.07, 10.82]} rotation={[-1.57, -0.24, -1.48]} scale={0.01}>
              <mesh name="particle293_Yellow_0" geometry={nodes.particle293_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle294" position={[-0.01, -0.14, 10.95]} rotation={[-2.13, -1.38, -2.06]} scale={0.01}>
              <mesh name="particle294_Yellow_0" geometry={nodes.particle294_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle295" position={[0.06, -0.09, 11.06]} rotation={[-1.92, -1.14, -1.88]} scale={0.01}>
              <mesh name="particle295_Yellow_0" geometry={nodes.particle295_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle296" position={[0.05, 0.08, 11.17]} rotation={[-1.39, 0.17, -1.49]} scale={0.01}>
              <mesh name="particle296_Yellow_0" geometry={nodes.particle296_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle297" position={[-0.12, 0.08, 11.25]} rotation={[1.39, 0.98, 1.88]} scale={0.01}>
              <mesh name="particle297_Yellow_0" geometry={nodes.particle297_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle298" position={[-0.03, 0.05, 11.37]} rotation={[1.61, 0.99, 1.48]} scale={0.01}>
              <mesh name="particle298_Yellow_0" geometry={nodes.particle298_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle299" position={[-0.06, -0.04, 11.47]} rotation={[1.69, 0.72, 1.67]} scale={0.01}>
              <mesh name="particle299_Yellow_0" geometry={nodes.particle299_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle300" position={[-0.15, -0.16, 11.6]} rotation={[-1.57, -0.39, -1.67]} scale={0.01}>
              <mesh name="particle300_Yellow_0" geometry={nodes.particle300_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle301" position={[0.04, 0, 11.7]} rotation={[1.47, 1.22, 1.66]} scale={0.01}>
              <mesh name="particle301_Yellow_0" geometry={nodes.particle301_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle302" position={[-0.05, -0.04, 11.8]} rotation={[-1.77, -0.18, -1.46]} scale={0.01}>
              <mesh name="particle302_Yellow_0" geometry={nodes.particle302_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle303" position={[-0.04, -0.1, 11.93]} rotation={[1.63, -0.89, 1.56]} scale={0.01}>
              <mesh name="particle303_Yellow_0" geometry={nodes.particle303_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle304" position={[-0.09, -0.04, 12.02]} rotation={[-1.28, 1.1, -1.67]} scale={0.01}>
              <mesh name="particle304_Yellow_0" geometry={nodes.particle304_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle305" position={[-0.01, -0.05, 12.14]} rotation={[1.56, -0.64, 1.63]} scale={0.01}>
              <mesh name="particle305_Yellow_0" geometry={nodes.particle305_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle306" position={[0.02, 0.04, 12.25]} rotation={[-1.58, 1.11, -1.55]} scale={0.01}>
              <mesh name="particle306_Yellow_0" geometry={nodes.particle306_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle307" position={[-0.11, 0, 12.36]} rotation={[1.85, -0.75, 1.63]} scale={0.01}>
              <mesh name="particle307_Yellow_0" geometry={nodes.particle307_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle308" position={[0.04, -0.13, 12.47]} rotation={[1.6, -0.77, 1.65]} scale={0.01}>
              <mesh name="particle308_Yellow_0" geometry={nodes.particle308_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle309" position={[-0.13, 0, 12.59]} rotation={[1.4, -0.31, 1.45]} scale={0.01}>
              <mesh name="particle309_Yellow_0" geometry={nodes.particle309_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle310" position={[0.07, -0.17, 12.7]} rotation={[-0.75, -1.38, -0.8]} scale={0.01}>
              <mesh name="particle310_Yellow_0" geometry={nodes.particle310_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle311" position={[-0.09, -0.09, 12.8]} rotation={[1.41, -0.39, 1.37]} scale={0.01}>
              <mesh name="particle311_Yellow_0" geometry={nodes.particle311_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle312" position={[-0.12, -0.15, 12.9]} rotation={[-1.68, 1.33, -1.63]} scale={0.01}>
              <mesh name="particle312_Yellow_0" geometry={nodes.particle312_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle313" position={[0.01, -0.08, 13.03]} rotation={[1.67, -0.04, 1.64]} scale={0.01}>
              <mesh name="particle313_Yellow_0" geometry={nodes.particle313_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle314" position={[-0.06, -0.14, 13.13]} rotation={[1.55, -0.04, 1.36]} scale={0.01}>
              <mesh name="particle314_Yellow_0" geometry={nodes.particle314_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle315" position={[-0.12, -0.07, 13.22]} rotation={[-1.57, 0.9, -1.52]} scale={0.01}>
              <mesh name="particle315_Yellow_0" geometry={nodes.particle315_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle316" position={[-0.17, -0.02, 13.34]} rotation={[-1.62, 0.36, -1.65]} scale={0.01}>
              <mesh name="particle316_Yellow_0" geometry={nodes.particle316_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle317" position={[-0.02, -0.13, 13.47]} rotation={[1.44, 0.4, 1.76]} scale={0.01}>
              <mesh name="particle317_Yellow_0" geometry={nodes.particle317_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle318" position={[-0.16, -0.07, 13.55]} rotation={[-1.62, 0.72, -1.6]} scale={0.01}>
              <mesh name="particle318_Yellow_0" geometry={nodes.particle318_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle319" position={[0.04, -0.07, 13.68]} rotation={[1.7, 0.39, 1.67]} scale={0.01}>
              <mesh name="particle319_Yellow_0" geometry={nodes.particle319_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle320" position={[0.02, 0.02, 13.78]} rotation={[1.47, -1.15, 1.49]} scale={0.01}>
              <mesh name="particle320_Yellow_0" geometry={nodes.particle320_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle321" position={[-0.08, -0.16, 13.9]} rotation={[1.9, 0.8, 1.3]} scale={0.01}>
              <mesh name="particle321_Yellow_0" geometry={nodes.particle321_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle322" position={[-0.05, 0.03, 14]} rotation={[-1.51, -0.07, -1.51]} scale={0.01}>
              <mesh name="particle322_Yellow_0" geometry={nodes.particle322_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle323" position={[-0.12, -0.08, 14.11]} rotation={[1.21, 1, 1.82]} scale={0.01}>
              <mesh name="particle323_Yellow_0" geometry={nodes.particle323_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle324" position={[-0.06, -0.09, 14.23]} rotation={[1.82, 1.05, 1.31]} scale={0.01}>
              <mesh name="particle324_Yellow_0" geometry={nodes.particle324_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle325" position={[-0.11, 0.04, 14.34]} rotation={[1.29, 1.08, 1.99]} scale={0.01}>
              <mesh name="particle325_Yellow_0" geometry={nodes.particle325_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle326" position={[0.06, -0.14, 14.44]} rotation={[-1.52, -0.73, -1.48]} scale={0.01}>
              <mesh name="particle326_Yellow_0" geometry={nodes.particle326_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle327" position={[-0.02, 0.02, 14.54]} rotation={[-1.5, -1.08, -1.7]} scale={0.01}>
              <mesh name="particle327_Yellow_0" geometry={nodes.particle327_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle328" position={[-0.03, 0.07, 14.66]} rotation={[-2.16, 1.52, -0.95]} scale={0.01}>
              <mesh name="particle328_Yellow_0" geometry={nodes.particle328_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle329" position={[-0.03, 0.04, 14.78]} rotation={[1.55, 1.53, 1.37]} scale={0.01}>
              <mesh name="particle329_Yellow_0" geometry={nodes.particle329_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle330" position={[0.06, -0.03, 14.89]} rotation={[-1.58, 0.65, -1.6]} scale={0.01}>
              <mesh name="particle330_Yellow_0" geometry={nodes.particle330_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle331" position={[0.02, -0.09, 15]} rotation={[-2.36, 1.42, -0.62]} scale={0.01}>
              <mesh name="particle331_Yellow_0" geometry={nodes.particle331_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle332" position={[0, -0.17, 15.11]} rotation={[-1.36, 1.35, -1.92]} scale={0.01}>
              <mesh name="particle332_Yellow_0" geometry={nodes.particle332_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle333" position={[-0.08, -0.13, 15.2]} rotation={[-1.63, -0.74, -1.66]} scale={0.01}>
              <mesh name="particle333_Yellow_0" geometry={nodes.particle333_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle334" position={[-0.02, 0.05, 15.31]} rotation={[-1.73, -0.75, -1.68]} scale={0.01}>
              <mesh name="particle334_Yellow_0" geometry={nodes.particle334_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle335" position={[-0.06, 0, 15.43]} rotation={[-1.32, 0.73, -1.9]} scale={0.01}>
              <mesh name="particle335_Yellow_0" geometry={nodes.particle335_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle336" position={[-0.16, 0.01, 15.55]} rotation={[-1.56, 0.92, -1.72]} scale={0.01}>
              <mesh name="particle336_Yellow_0" geometry={nodes.particle336_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle337" position={[-0.17, -0.11, 15.65]} rotation={[-1.58, 0.97, -1.47]} scale={0.01}>
              <mesh name="particle337_Yellow_0" geometry={nodes.particle337_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle338" position={[-0.03, -0.08, 15.76]} rotation={[-1.52, 0.68, -1.75]} scale={0.01}>
              <mesh name="particle338_Yellow_0" geometry={nodes.particle338_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle339" position={[0.04, -0.16, 15.87]} rotation={[-1.52, 0.78, -1.59]} scale={0.01}>
              <mesh name="particle339_Yellow_0" geometry={nodes.particle339_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle340" position={[-0.1, 0.02, 15.98]} rotation={[-1.48, 0.42, -1.61]} scale={0.01}>
              <mesh name="particle340_Yellow_0" geometry={nodes.particle340_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle341" position={[0.06, 0.03, 16.08]} rotation={[-3.07, -1.33, -3.09]} scale={0.01}>
              <mesh name="particle341_Yellow_0" geometry={nodes.particle341_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle342" position={[-0.08, 0.08, 16.2]} rotation={[-1.67, 0.46, -1.46]} scale={0.01}>
              <mesh name="particle342_Yellow_0" geometry={nodes.particle342_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle343" position={[-0.02, -0.06, 16.31]} rotation={[-1.43, 0.43, -1.78]} scale={0.01}>
              <mesh name="particle343_Yellow_0" geometry={nodes.particle343_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle344" position={[0.05, -0.08, 16.42]} rotation={[-1.64, -0.25, -1.52]} scale={0.01}>
              <mesh name="particle344_Yellow_0" geometry={nodes.particle344_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle345" position={[0.05, -0.05, 16.51]} rotation={[1.84, -1.02, 1.7]} scale={0.01}>
              <mesh name="particle345_Yellow_0" geometry={nodes.particle345_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle346" position={[0.05, -0.12, 16.63]} rotation={[-1.5, -0.07, -1.56]} scale={0.01}>
              <mesh name="particle346_Yellow_0" geometry={nodes.particle346_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle347" position={[-0.04, -0.11, 16.75]} rotation={[-1.79, -0.25, -1.51]} scale={0.01}>
              <mesh name="particle347_Yellow_0" geometry={nodes.particle347_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle348" position={[-0.09, -0.15, 16.85]} rotation={[-1.67, -0.51, -1.74]} scale={0.01}>
              <mesh name="particle348_Yellow_0" geometry={nodes.particle348_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle349" position={[0.03, -0.17, 16.96]} rotation={[1.5, 0.41, 1.75]} scale={0.01}>
              <mesh name="particle349_Yellow_0" geometry={nodes.particle349_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle350" position={[-0.07, 0.01, 17.06]} rotation={[1.56, -0.6, 1.56]} scale={0.01}>
              <mesh name="particle350_Yellow_0" geometry={nodes.particle350_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle351" position={[-0.03, -0.15, 17.18]} rotation={[-1.49, 0.8, -1.4]} scale={0.01}>
              <mesh name="particle351_Yellow_0" geometry={nodes.particle351_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle352" position={[0, 0.06, 17.3]} rotation={[-1.53, -0.43, -1.63]} scale={0.01}>
              <mesh name="particle352_Yellow_0" geometry={nodes.particle352_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle353" position={[-0.03, 0.08, 17.4]} rotation={[-1.73, -0.54, -1.64]} scale={0.01}>
              <mesh name="particle353_Yellow_0" geometry={nodes.particle353_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle354" position={[-0.12, 0.01, 17.5]} rotation={[1.46, 0.47, 1.45]} scale={0.01}>
              <mesh name="particle354_Yellow_0" geometry={nodes.particle354_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle355" position={[-0.15, 0.02, 17.62]} rotation={[-1.41, -0.96, -1.51]} scale={0.01}>
              <mesh name="particle355_Yellow_0" geometry={nodes.particle355_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle356" position={[0, -0.16, 17.74]} rotation={[-1.6, -1.11, -1.47]} scale={0.01}>
              <mesh name="particle356_Yellow_0" geometry={nodes.particle356_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle357" position={[-0.15, -0.04, 17.84]} rotation={[-1.7, -1.17, -1.67]} scale={0.01}>
              <mesh name="particle357_Yellow_0" geometry={nodes.particle357_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle358" position={[-0.07, -0.14, 17.94]} rotation={[1.7, 0.09, 1.36]} scale={0.01}>
              <mesh name="particle358_Yellow_0" geometry={nodes.particle358_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle359" position={[-0.14, 0.08, 18.06]} rotation={[-1.78, -1.42, -1.75]} scale={0.01}>
              <mesh name="particle359_Yellow_0" geometry={nodes.particle359_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle360" position={[-0.16, -0.04, 18.17]} rotation={[0.48, -1.45, 0.27]} scale={0.01}>
              <mesh name="particle360_Yellow_0" geometry={nodes.particle360_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle361" position={[-0.14, -0.16, 18.28]} rotation={[-1.51, -1.44, -1.55]} scale={0.01}>
              <mesh name="particle361_Yellow_0" geometry={nodes.particle361_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle362" position={[0.06, -0.07, 18.38]} rotation={[1.54, 0.38, 1.7]} scale={0.01}>
              <mesh name="particle362_Yellow_0" geometry={nodes.particle362_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle363" position={[0.02, -0.06, 18.49]} rotation={[-1.41, -0.38, -1.57]} scale={0.01}>
              <mesh name="particle363_Yellow_0" geometry={nodes.particle363_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle364" position={[0.02, -0.14, 18.6]} rotation={[1.59, -0.75, 1.72]} scale={0.01}>
              <mesh name="particle364_Yellow_0" geometry={nodes.particle364_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle365" position={[0, -0.13, 18.28]} rotation={[3.09, -1.43, 3.04]} scale={0.01}>
              <mesh name="particle365_Yellow_0" geometry={nodes.particle365_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle366" position={[-0.15, -0.09, 18.83]} rotation={[1.56, -1.38, 1.71]} scale={0.01}>
              <mesh name="particle366_Yellow_0" geometry={nodes.particle366_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle367" position={[-0.13, -0.13, 18.93]} rotation={[1.39, 0.96, 1.62]} scale={0.01}>
              <mesh name="particle367_Yellow_0" geometry={nodes.particle367_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle368" position={[-0.18, -0.17, 19.05]} rotation={[-1.66, -0.3, -1.64]} scale={0.01}>
              <mesh name="particle368_Yellow_0" geometry={nodes.particle368_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle369" position={[-0.03, -0.15, 19.15]} rotation={[1.93, 1.45, 1.43]} scale={0.01}>
              <mesh name="particle369_Yellow_0" geometry={nodes.particle369_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle370" position={[-0.07, -0.08, 19.26]} rotation={[1.56, 1.43, 1.64]} scale={0.01}>
              <mesh name="particle370_Yellow_0" geometry={nodes.particle370_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle371" position={[0.06, -0.01, 19.37]} rotation={[-1.86, 1.06, -1.35]} scale={0.01}>
              <mesh name="particle371_Yellow_0" geometry={nodes.particle371_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle372" position={[-0.02, -0.09, 19.48]} rotation={[-1.26, 1.16, -1.7]} scale={0.01}>
              <mesh name="particle372_Yellow_0" geometry={nodes.particle372_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle373" position={[-0.06, 0.02, 19.6]} rotation={[1.5, -0.57, 1.59]} scale={0.01}>
              <mesh name="particle373_Yellow_0" geometry={nodes.particle373_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle374" position={[0.06, 0, 19.71]} rotation={[0.13, 1.53, 2.87]} scale={0.01}>
              <mesh name="particle374_Yellow_0" geometry={nodes.particle374_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle375" position={[-0.11, -0.17, 19.81]} rotation={[1.52, -0.47, 1.48]} scale={0.01}>
              <mesh name="particle375_Yellow_0" geometry={nodes.particle375_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle376" position={[-0.17, 0.06, 19.92]} rotation={[1.67, 0.33, 1.44]} scale={0.01}>
              <mesh name="particle376_Yellow_0" geometry={nodes.particle376_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle377" position={[-0.06, 0.05, 20.03]} rotation={[1.71, 0.15, 1.55]} scale={0.01}>
              <mesh name="particle377_Yellow_0" geometry={nodes.particle377_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle378" position={[-0.1, -0.07, 20.14]} rotation={[1.44, 0.15, 1.56]} scale={0.01}>
              <mesh name="particle378_Yellow_0" geometry={nodes.particle378_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle379" position={[0.01, -0.12, 20.25]} rotation={[-1.34, -0.56, -1.43]} scale={0.01}>
              <mesh name="particle379_Yellow_0" geometry={nodes.particle379_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle380" position={[-0.06, -0.01, 20.36]} rotation={[1.72, -0.04, 1.57]} scale={0.01}>
              <mesh name="particle380_Yellow_0" geometry={nodes.particle380_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle381" position={[-0.08, -0.13, 20.46]} rotation={[-1.39, 0.54, -1.64]} scale={0.01}>
              <mesh name="particle381_Yellow_0" geometry={nodes.particle381_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle382" position={[-0.07, -0.02, 20.58]} rotation={[-1.75, -0.05, -1.6]} scale={0.01}>
              <mesh name="particle382_Yellow_0" geometry={nodes.particle382_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle383" position={[-0.05, -0.12, 20.69]} rotation={[-1.79, 0.78, -1.22]} scale={0.01}>
              <mesh name="particle383_Yellow_0" geometry={nodes.particle383_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle384" position={[-0.13, -0.13, 20.79]} rotation={[1.53, -0.66, 1.57]} scale={0.01}>
              <mesh name="particle384_Yellow_0" geometry={nodes.particle384_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle385" position={[-0.02, -0.16, 20.9]} rotation={[-1.75, 0.38, -1.66]} scale={0.01}>
              <mesh name="particle385_Yellow_0" geometry={nodes.particle385_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle386" position={[-0.07, -0.12, 21.01]} rotation={[-1.6, 0.33, -1.48]} scale={0.01}>
              <mesh name="particle386_Yellow_0" geometry={nodes.particle386_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle387" position={[-0.14, -0.12, 21.12]} rotation={[-1.48, 0.13, -1.74]} scale={0.01}>
              <mesh name="particle387_Yellow_0" geometry={nodes.particle387_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle388" position={[-0.05, 0.04, 21.24]} rotation={[-1.56, -0.18, -1.6]} scale={0.01}>
              <mesh name="particle388_Yellow_0" geometry={nodes.particle388_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle389" position={[-0.08, -0.11, 21.34]} rotation={[-1.63, 0.14, -1.49]} scale={0.01}>
              <mesh name="particle389_Yellow_0" geometry={nodes.particle389_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle390" position={[-0.14, 0.01, 21.45]} rotation={[-1.44, -0.08, -1.71]} scale={0.01}>
              <mesh name="particle390_Yellow_0" geometry={nodes.particle390_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle391" position={[-0.15, 0.05, 21.56]} rotation={[-1.64, -0.35, -1.54]} scale={0.01}>
              <mesh name="particle391_Yellow_0" geometry={nodes.particle391_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle392" position={[0.06, 0.05, 21.68]} rotation={[2, 1.3, 0.97]} scale={0.01}>
              <mesh name="particle392_Yellow_0" geometry={nodes.particle392_Yellow_0.geometry} material={materials.Yellow} />
            </group>
            <group name="particle393" position={[-0.01, 0.07, 21.78]} rotation={[-1.53, 1.27, -1.61]} scale={0.01}>
              <mesh name="particle393_Yellow_0" geometry={nodes.particle393_Yellow_0.geometry} material={materials.Yellow} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}
