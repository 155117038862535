import {Box} from "@mui/material";
import React from "react";
import {HighlightOff as HighlightOffIcon} from "@mui/icons-material";

/**
 * HintBox
 *
 * @param mode
 * @param searchedObject
 * @param hintMessage
 * @param showHintBox
 * @param setShowHintBox
 * @constructor
 */
export default function HintBox (
  { mode, searchedObject, hintMessage, showHintBox, setShowHintBox }: any
) {

  const modalStyle = {
    zIndex: '99',
    position: 'fixed' as 'fixed',
    top: '20%',
    left: '0',
    maxWidth: '500px',
    width: 'auto',
    padding: '5px',
    height: 'auto',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    opacity: '0.8',
    border: '2px solid #000',
    fontSize: "14px",
  };

  return (
    <>
      { showHintBox && (
        <Box className={"hintBox infoModal__desktop-content"} component="div" sx={modalStyle}>
          <HighlightOffIcon className="closeModalButton" onClick={() => { setShowHintBox(false)}}/>


          { hintMessage === 'step-1' && (
            <div className="">
              <div>
                <ol>
                  <li>Walk up to the Lion using/holding the<br/><span className="bold-hint">[Arrow]</span> Keys</li>
                  <br/>
                  <li>Simultaneously guide self with <span className="bold-hint">[Mouse/Touchpad]</span></li>
                  <br/>
                  <li>Press <span className="bold-hint">[e]</span> whilst Very Close to the Lion to Interact</li>
                </ol>
                <p className="red">
                  Press <span className="bold-hint">[ESC]</span> to exit or <span className="bold-hint">[i]</span> for Info
                </p>
              </div>
            </div>
          )}

          { hintMessage === 'step-2' && (searchedObject == "" || typeof searchedObject === 'undefined') && (
            <div className="">
              <div>
                <ol>
                  <li>Walk into the field by holding the <span className="bold-hint">[Arrow]</span> Keys</li>
                  <br/>
                  <li>Run by holding <span className="bold-hint">[Shift]</span> whilst Moving</li>
                  <br/>
                  <li>Choose a location and press <span className="bold-hint">[p]</span> to place a {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</li>
                </ol>
                <p className="red">
                  Talk to the Lion to choose a different {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}
                </p>
              </div>
            </div>
          )}

          { hintMessage === 'step-2' && (searchedObject != "" || typeof searchedObject === 'undefined') && (
            <div className="">
              <div>
                <ol>
                  <li>Walk into the field by holding the <span className="bold-hint">[Arrow]</span> Keys</li>
                  <br/>
                  <li>Look for the <b>Highlighted {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b></li>
                  <br/>
                  <li>Press <span className="bold-hint"><b>[e]</b></span> on the <b>Message</b> to Read</li>
                </ol>
                <p className="red">
                  <b>Send or Plant</b> a <b>{`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b> at <b>{window.location.origin}</b>
                </p>
              </div>
            </div>
          )}

          { hintMessage === 'step-3' && (searchedObject == "" || typeof searchedObject === 'undefined') && (
            <div className="">
              <div>
                <ol>
                  <li>Step back to see {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}. Press <span className="bold-hint">[p]</span> to start over</li>
                  <br/>
                  <li>You can adjust the size/style of your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`} using the Overlays on the right (Press <span className="bold-hint">[ESC]</span> first)</li>
                  <br/>
                  <li>Go back to the Lion and Press <span className="bold-hint">[e]</span> to Talk/Plant</li>
                </ol>
                <p className="red">
                  Or press <span className="bold-hint">[ESC]</span> and <span className="bold-hint">[Click]</span> the Lion button
                </p>
              </div>
            </div>
          )}
        </Box>
      )}
    </>
  )
}
