import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    wing: THREE.SkinnedMesh
    Bone: THREE.Bone
  }
  materials: {
    wing: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Armature.001|AnimAll.001'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export function Wings({ position, scale, rotation }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/regular-version/wings-transformed.glb`;

  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group)

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => {
    mixer.clipAction(animations[0], group.current).setDuration(8);
    mixer.clipAction(animations[0], group.current).play();
  }, [animations])

  useFrame((scene, delta) => {
    mixer?.update(delta)
  });

  return (
    <group ref={group} position={position} scale={scale} rotation={rotation}>
      <group name="Scene">
        <group name="Armature001">
          <primitive object={nodes.Bone} />
          <skinnedMesh name="wing" geometry={nodes.wing.geometry} material={materials.wing} skeleton={nodes.wing.skeleton} />
        </group>
      </group>
    </group>
  )
}
