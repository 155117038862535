import {Slider} from "@mui/material";
import React, {MutableRefObject, useEffect, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import {ObjectColorPicker} from "../layout/ObjectColorPicker";
import Button from "@mui/material/Button";

/**
 * Size Slider
 *
 * @param pointerControls
 * @param mode
 * @param roseSizings
 * @param rocketSizings
 * @param placedObject
 * @param donationAmount
 * @param setDonationAmount
 * @param objectColor
 * @param setObjectColor
 * @param setShowInfoModal
 * @constructor
 */
export function SizeSlider(
  { pointerControls, mode, roseSizings, rocketSizings, placedObject, donationAmount, setDonationAmount, objectColor,
    setObjectColor, setShowInfoModal
  }: {
    pointerControls: MutableRefObject<any>, mode: string, roseSizings: any, rocketSizings: any,
    placedObject: any, donationAmount: number, setDonationAmount: Function, objectColor: string,
    setObjectColor: Function, setShowInfoModal: Function
  })
{
  const { chainId } = useWeb3React();

  let imgs = {
    lion: `${process.env.REACT_APP_ASSETS_URL}/lionImage.jpg`,
    lionDark: `${process.env.REACT_APP_ASSETS_URL}/lionDarkImage.jpg`,
  };

  /**
   * valuetext
   * @param value
   */
  function valuetext(value: number) {
    return `${value} ${chainId === 137 ? 'Matic' : 'Eth'}`;
  }

  /**
   * handleChangeDonationAmount
   * @param event
   */
  function handleChangeDonationAmount(event: any) {
    setDonationAmount(event.target.value)
  }

  /**
   * handleGuideButton
   * @param event
   */
  const handleGuideButton = (event: any) => {
    event.preventDefault(); // todo is this needed?
    setTimeout(() => {pointerControls.current.unlock()},100);
    setShowInfoModal(true);
  }

  const [marks, setMarks] = useState<any>([]);
  const [min, setMin] = useState<any>(0);
  const [max, setMax] = useState<any>(0);
  const [step, setStep] = useState<any>(0);

  /**
   *
   */
  useEffect(() => {
    let marksArray: { value: any; label: string; }[] = [];

    if (mode !== '' && (roseSizings || rocketSizings)) {
      if (chainId === 1 || chainId == null) {
        roseSizings.amounts['1'].map((amount: any, i: number) => {
          if (i !== 3) {
            marksArray.push({
              value: amount,
              label: `${amount} Eth`
            })
          }
        });
        setMin(0.01)
        setMax(1.5)
        setStep(0.01)
      } else if (chainId === 137) {
        roseSizings.amounts['137'].map((amount: any, i: number) => {
          if (i !== 3) {
            marksArray.push({
              value: amount,
              label: `${amount} Matic`
            })
          }
        });
        setMin(10)
        setMax(1500)
        setStep(10)
      }
    }
    setMarks(marksArray);
  }, [mode, chainId, placedObject]); // added placedObject so it gets re-triggered when rose or rocket is placed

  return (
    <>
      { placedObject && (
        <>
          <div className='slider-container' style={{position: 'fixed', right:'5%', height: '50vh', zIndex:'999', top: '30vh'}}>
            <Slider
              // size="small"
              getAriaLabel={() => 'Donation'}
              orientation="vertical"
              getAriaValueText={valuetext}
              value={donationAmount}
              onChange={(e:any) => handleChangeDonationAmount(e)}
              valueLabelDisplay="on"
              marks={marks}
              min={min}
              step={step}
              max={max}
              onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
            />
          </div>
          <div
            className='object-color-picker-container'
            style={{ width: '30%', height: '10px', position: 'fixed', right:'5%', zIndex:'999', top: '15vh'}}
          >
            <ObjectColorPicker
              mode={mode} objectColor={objectColor} pointerControls={pointerControls} setObjectColor={setObjectColor}
            />
          </div>
          <div
            style={{ position: 'fixed', right:'15%', zIndex:'999', top: '25vh'}}
          >
            <Button color="inherit" variant="text"
                    onClick={handleGuideButton}>
              <img src={mode === 'rose' ? imgs.lion : imgs.lionDark} style={{width: "90px", height: "90px", marginTop: "9px", border: '10px solid lime'}} />
            </Button>
          </div>
        </>
      )}
    </>
  )
}
