import {
  Button,
  Modal,
  Typography,
  Box,
  TextField,
  Tab,
  Tabs
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {LocalFlorist} from "@mui/icons-material";
import {useWeb3React} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";
import { validEmail } from "../../helpers/helperFunctions";
import Links from "../layout/Links";
import {ObjectColorPicker} from "../layout/ObjectColorPicker";

let imgs = {
  lion: `${process.env.REACT_APP_ASSETS_URL}/lionImage.jpg`,
  lionDark: `${process.env.REACT_APP_ASSETS_URL}/lionDarkImage.jpg`,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div" sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/**
 * Info Modal
 *
 * @param mode
 * @param yourOrganization
 * @param setYourOrganization
 * @param highlightedCommunity
 * @param lowGraphics
 * @param setLowGraphics
 * @param allFailed
 * @param transactionHash
 * @param objectUUID
 * @param allDone
 * @param setHasObject
 * @param messageFrom
 * @param setMessageFrom
 * @param sendToEmail
 * @param setSendToEmail
 * @param setShowPlantModal
 * @param setModalOpen
 * @param searchedObject
 * @param lockKeyboard
 * @param setLockKeyboard
 * @param setNoteMessage
 * @param noteMessage
 * @param hasObject
 * @param placedObject
 * @param setPlacedObject
 * @param setObjectColor
 * @param objectColor
 * @param pointerControls
 * @param showInfoModal
 * @param setShowInfoModal
 * @constructor
 */
export default function InfoModal (
  { mode, yourOrganization, setYourOrganization, highlightedCommunity, lowGraphics, setLowGraphics, allFailed,
    transactionHash, objectUUID, allDone, setHasObject, messageFrom, setMessageFrom, sendToEmail, setSendToEmail,
    setShowPlantModal, setModalOpen, searchedObject, lockKeyboard, setLockKeyboard, setNoteMessage, noteMessage,
    hasObject, placedObject, setPlacedObject, setObjectColor, objectColor, pointerControls, showInfoModal, setShowInfoModal
  }: any)
{
  const context = useWeb3React<Web3Provider>(); // todo check because this web3provider is from ethers
  const { active } = context;

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [localToEmail, setLocalToEmail] = useState("");
  const [validEmailAddress, setValidEmailAddress] = useState(false);

  /**
   * handleChangeSendToEmail
   * @param event
   */
  const handleChangeSendToEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {pointerControls.current.unlock()},100);
    setLockKeyboard(true);
    const newEmail = event.target.value;

    setLocalToEmail(newEmail)
    if (validEmail(newEmail)) {
      setValidEmailAddress(true);
      setSendToEmail(newEmail);
    } else {
      setValidEmailAddress(false);
      setSendToEmail("");
    }
  };

  /**
   * handleToggleGraphics
   */
  const handleToggleGraphics = () => {
    setLowGraphics(!lowGraphics);

    if (pointerControls.current !== null) {
      setTimeout(() => {
        pointerControls.current.unlock()
      }, 100)
    }
  };

  /**
   * handleChangeMessageFrom
   * @param event
   */
  const handleChangeMessageFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
    // todo still need to lock keyboard controls
    setTimeout(() => {pointerControls.current.unlock()},100);
    setLockKeyboard(true);
    const messageFrom = event.target.value;

    setMessageFrom(messageFrom);
  };

  /**
   * handleChangeYourOrganization
   * @param event
   */
  const handleChangeYourOrganization = (event: React.ChangeEvent<HTMLInputElement>) => {
    // todo still need to lock keyboard controls
    setTimeout(() => {pointerControls.current.unlock()},100);
    setLockKeyboard(true);
    const yourOrganization = event.target.value;

    setYourOrganization(yourOrganization);
  };

  /**
   * handleChangeTab
   * @param event
   * @param newValue
   */
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setLocalToEmail(""); // THIS IS REALLY IMPORTANT, AS THE EMAIL WILL STILL EXIST IF THE USER CHANGES THEIR MIND
    setSendToEmail(""); // THIS IS REALLY IMPORTANT, AS THE EMAIL WILL STILL EXIST IF THE USER CHANGES THEIR MIND
    setMessageFrom(""); // THIS IS REALLY IMPORTANT, AS THE EMAIL WILL STILL EXIST IF THE USER CHANGES THEIR MIND
    setSelectedTab(newValue);
  };

  /**
   * handleStartOver
   */
  const handleStartOver = () => {
    setSendToEmail(""); // THIS IS REALLY IMPORTANT, AS THE EMAIL WILL STILL EXIST IF THE USER CHANGES THEIR MIND
    setMessageFrom(""); // THIS IS REALLY IMPORTANT, AS THE EMAIL WILL STILL EXIST IF THE USER CHANGES THEIR MIND
    setNoteMessage(""); // THIS IS REALLY IMPORTANT, AS THE EMAIL WILL STILL EXIST IF THE USER CHANGES THEIR MIND
    setYourOrganization(""); // THIS IS REALLY IMPORTANT, AS THE EMAIL WILL STILL EXIST IF THE USER CHANGES THEIR MIND
    setPlacedObject(false);
    setHasObject(true);
  };

  /**
   *
   * @param index
   */
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  /**
   *
   */
  useEffect(() => {
    if (showInfoModal === true) {
      setLockKeyboard(true);
    } else {
      setLockKeyboard(false);
    }
  }, [showInfoModal]);

  /**
   * handleClose
   * TODO IF YOU USE THIS AS THE INTRO MODAL, NEED TO CHECK FOR POINTERCONTROLS.CURRENT === NULL AS THIS IS THE FIRST THING THE USER SEES, NEED TO MAKE SURE POINTERCONTROLS EXISTS OTHERWISE ERRORS
   */
  const handleClose = () => {
    setShowInfoModal(false);
    pointerControls.current.connect()
    setTimeout(() => {pointerControls.current.lock()},110) // this needs to be higher than the timeout on the modal
  };

  /**
   * beginPlant
   */
  const beginPlant = () => {
    setTimeout(() => {pointerControls.current.unlock()},100);
    // pointerControls.current.connect()

    if(!active) {
      setModalOpen(true);
    } else {
      setShowPlantModal(true);
    }
  };

  /**
   * handleChangeMessage
   * @param event
   */
  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // todo still need to lock keyboard controls
    setTimeout(() => {pointerControls.current.unlock()},100);
    setLockKeyboard(true);
    const newMessage = event.target.value;
    // if (newMessage >= min && newMessage <=  max) {
      setNoteMessage(newMessage);
    // }
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90vw',
    minWidth: '85vw',
    maxHeight: '90vh',
    // minHeight: '85vh',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      className="info-modal"
      open={showInfoModal}
      onClose={handleClose}
      onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}

      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
    >
      <Box className="infoModal" component="div" sx={modalStyle}>

        <div className="characterTitle">
          { mode == 'rose' && (
            <img src={imgs.lion} style={{width: "90px", height: "90px"}} />
          )}
          { mode == 'rocket' && (
            <img src={imgs.lionDark} style={{width: "90px", height: "90px"}} />
          )}

          <div>
            <h1>Lihui:</h1>
            {/*<div className="infoModal__desktop-content">*/}
            {/*  <span><b>Click my Icon</b>, speak to me, or press <b>[i]</b> to re-show this <b>Dialog</b>.</span>*/}
            {/*</div>*/}
          </div>
        </div>

        <div className="infoModal__mobile-content">
          <span>"Hi, I'm <b>Lihui</b> the {(mode === 'rose') ? 'Mountain Lion' : (mode === 'rocket') ? 'City Lion' : ''}!"</span>
          <p className='infoModal__mobile-warning'><b>"This MetaWorld is CURRENTLY DESKTOP ONLY!"</b></p>
          <p>"But you can still <b>Follow these Links</b> to learn about <b>Web4</b> and join <b>Web4</b> Communities:"</p>
          <Links />
        </div>

        <div className="infoModal__desktop-content">

          <br/>

          {/*todo why was this working before before the undefined check?*/}
          { hasObject && (searchedObject == "" || typeof searchedObject === 'undefined') && (
            <>
              <div className="">

                <div style={{display:"flex",flexDirection:"row"}}>
                  <span>"Remember, if things <b>Seem Slow</b>,<br/>try toggling the <b>Graphics Button</b>!"&nbsp;</span>
                  { lowGraphics && (
                    <Button onClick={handleToggleGraphics} variant="contained" color="success" className="lowGraphicsButton"><LocalFlorist /></Button>
                  )}

                  { !lowGraphics && (
                    <Button onClick={handleToggleGraphics} variant="outlined" color="success" className="lowGraphicsButton"><LocalFlorist /></Button>
                  )}
                </div>

                <br/>
                <hr/>
                <br/>
              </div>

              <p>"Now you are ready to go into the <b>[Field]</b> and <b>Place your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b>:"</p>
              { highlightedCommunity && (
                <><span style={{color:'gold'}}>Looks like your <b>Community</b> is highted! Look for the <b>Yellow Beacon</b> in the Field, and place a <b>{`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b> nearby!</span></>
              )}

              <ol>
                <li>"Walk into the <b>Field</b> (or run by holding <b>[Shift]</b> whilst <b>Moving</b>)."</li>
                <li>"Place <b>Your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b> in the <b>Field</b> with the <b>[p] Key</b>."</li>
                <li>"<b>Pick a {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`} Color</b> from below:"</li>
              </ol>

              <br/>

              <ObjectColorPicker objectColor={objectColor} setObjectColor={setObjectColor} mode={mode} pointerControls={pointerControls} />
            </>
          )}

          { (!allDone && !hasObject && (searchedObject == "" || typeof searchedObject === 'undefined')) && (
            <>
              { mode === 'rose' && (
                <p>"Choose between <b>Plant {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b> or <b>Send {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b>:"</p>
              )}
              { mode === 'rocket' && (
                <p>"Fill in the details to <b>Plant {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b>"</p>
              )}

              <Box component="div" sx={{ width: '100%' }}>
                <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="basic tabs example">

                    {mode === 'rose' && (
                      <Tab label="Plant Rose" {...a11yProps(0)} />
                    )}
                    {mode === 'rose' && (
                      <Tab label="Send Rose" {...a11yProps(1)} />
                    )}
                    {mode === 'rocket' && (
                      <Tab label="Plant Rocket" {...a11yProps(0)} />
                    )}
                    {/*{mode === 'rocket' && (*/}
                    {/*  <Tab label="Send Rocket" {...a11yProps(1)} />*/}
                    {/*)}*/}

                  </Tabs>
                </Box>
                <TabPanel value={selectedTab} index={0}>
                  <div style={{display: 'flex', flexDirection:'column'}}>
                    <TextField
                      style={{backgroundColor: 'lightgrey', borderRadius: '5px'}}
                      label="Tag your Your Organization (optional - may be displayed in future updates)"
                      value={yourOrganization}
                      onChange={handleChangeYourOrganization}
                      onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
                    />
                  </div>
                  <br/>
                  <hr/>

                  <p>"Do you want to add a <b>Message</b> to your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}?"<br/>"Your message will be accessible from a <b>Shareable Link</b> after Planting:
                    { mode == 'rose' && (
                      <a style={{float:'right'}} href={`${window.location.origin}?rose=rose1`} target="_blank">"Click for example (opens in new tab)!"</a>
                    )}
                    { mode == 'rocket' && (
                      <a style={{float:'right'}} href={`${window.location.origin}?rocket=rocket1`} target="_blank">"Click for example (opens in new tab)!"</a>
                    )}
                    <br/>
                  </p>

                  <div style={{display: 'flex', flexDirection:'column'}}>
                    <TextField
                      label="Note Message (optional)"
                      multiline
                      rows={4}
                      value={noteMessage}
                      onChange={handleChangeMessage}
                      onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
                    />
                    <br/>

                    {placedObject && (
                      <Button style={{alignSelf: 'flex-start'}} className="mintModalButton--large" variant="contained" color="success" onClick={beginPlant}>
                        <b>Plant {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b>
                      </Button>
                    )}
                  </div>
                </TabPanel>

                { mode == 'rose' && (
                  <TabPanel value={selectedTab} index={1}>
                    <div style={{display: 'flex', flexDirection:'column'}}>
                      <TextField
                        style={{backgroundColor: 'lightgrey', borderRadius: '5px'}}
                        label="Tag your Your Organization (optional - may be displayed in future updates)"
                        value={yourOrganization}
                        onChange={handleChangeYourOrganization}
                        onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
                      />
                    </div>
                    <br/>
                    <hr/>

                    <p>"Do you want to add a <b>Message</b> to your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}?"<br/>"Your <b>Shareable Link</b> will be sent after Planting:

                      { mode == 'rose' && (
                        <a style={{float:'right'}} href={`${window.location.origin}?rose=rose1`} target="_blank">"Click for example (opens in new tab)!"</a>
                      )}
                      { mode == 'rocket' && (
                        <a style={{float:'right'}} href={`${window.location.origin}?rocket=rocket1`} target="_blank">"Click for example (opens in new tab)!"</a>
                      )}
                      <br/>
                    </p>

                    <div style={{display: 'flex', flexDirection:'column'}}>
                      <TextField
                        className={validEmailAddress ? 'green-input' : 'red-input'}
                        // defaultValue={objectCount}
                        // inputProps={{ maxLength: 4 }}
                        label="To (required): Recipient's Email"
                        required
                        value={localToEmail}
                        onChange={handleChangeSendToEmail}
                        onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
                      />
                      <br/>
                      <TextField
                        label="Note Message (optional)"
                        multiline
                        rows={4}
                        value={noteMessage}
                        onChange={handleChangeMessage}
                        onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
                      />
                      <br/>
                      <TextField
                        // defaultValue={objectCount}
                        // inputProps={{ maxLength: 4 }}
                        label="From (optional)"
                        value={messageFrom}
                        onChange={handleChangeMessageFrom}
                        onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
                      />

                      <br/>

                      {placedObject && (
                        <Button disabled={!validEmail(sendToEmail) } style={{alignSelf: 'flex-start'}} className="mintModalButton--large" variant="contained" color="success" onClick={beginPlant}>
                          <b>Send {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b>
                        </Button>
                      )}
                    </div>

                  </TabPanel>
                )}
              </Box>
            </>
          )}

          {/*THIS ONE IS SHOWN BY TALKING AGAIN TO THE LION AT THE END AND IS A DUPLICATE OF SLIDEMODAL*/}
          { allDone && (
            <>
              { !allFailed && (
                <>
                  <h2 style={{marginTop:"0"}}>Congratulations!</h2>
                  <h3 className="green">"<b>Your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`} has been planted!</b><br/>With this link (make sure you save it), you can see or send your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}{noteMessage !== "" ? " and Message" : "" }:"<br/></h3>
                  <a href={`${window.location.origin}?${mode}=${objectUUID}`} target="_blank">{window.location.origin}?{mode}={objectUUID}</a><br/><br/></>
              )}
              { allFailed && (
                <h3 className="red">"<b>Something went wrong planting the {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}! Press [i] or click the (i) icon at the bottom left, and let us know this transaction hash: {transactionHash}!</b>"<br/><br/></h3>
              )}

              <hr/>
              <h2>Where Next:</h2>
              <Links/>
            </>
          )}
          {/*THIS ONE IS SHOWN BY TALKING AGAIN TO THE LION AT THE END AND IS A DUPLICATE OF SLIDEMODAL*/}

          {/*todo why was this working before and then going past here when searchedObject is undefined?*/}
          { (searchedObject !== "" && typeof searchedObject !== "undefined") && (
            <>
              <p>"You have a <b>{`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b>! It is highlighted with a <b>Beacon</b> in the <b>Field</b>."</p>
              <p style={{color:'green'}}>"Go to the <b>{ mode === 'rose' ? 'Well' : mode === 'rocket' ? 'Terminal' : ''}</b> to launch the <b>{ mode === 'rose' ? 'Butterfly' : mode === 'rocket' ? 'Rocket' : ''}</b>!"</p>

              {typeof searchedObject.note != "undefined" && searchedObject.note != "" && searchedObject.note != null && (
                <p>"Go right up to the <b>{`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b>, and press <b>[e]</b> to read your <b>Message</b>."</p>
              )}

              <p>"You can visit <b><a href={`${window.location.origin}`} target="_blank">Web4.garden</a></b> to <b>Plant or Send</b> your own <b>{`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b>."</p>
              <br/>
            </>
          )}

          <br/>
        </div>

        <div className={`${(placedObject) ? 'infoModal__button-container-between' : 'infoModal__button-container'}`}>

          <Button disabled={allDone} className={`${(hasObject) ? 'hidden closeInfoModalButton--large' : 'closeInfoModalButton--large'}`}  variant="contained" color="error" onClick={handleStartOver}>
            Reset
          </Button>

          <Button className="closeInfoModalButton--large" variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
