import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {ReactThreeFiber} from "@react-three/fiber";
import {InstancedBufferAttribute, InstancedMesh, Object3D} from "three";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/dystopian-skyscraper-transformed.glb`;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // TODO UPDATE TO ALLOW NULL ON TYPES
      instancedMesh: ReactThreeFiber.Object3DNode<InstancedMesh, typeof InstancedMesh>
      instancedBufferAttribute: ReactThreeFiber.Object3DNode<InstancedBufferAttribute, typeof InstancedBufferAttribute>
    }
  }
}

type GLTFResult = GLTF & {
  nodes: {
    defaultMaterial: THREE.Mesh
    defaultMaterial001: THREE.Mesh
    defaultMaterial005: THREE.Mesh
    defaultMaterial007: THREE.Mesh
  }
  materials: {
    ['glass_off.001']: THREE.MeshStandardMaterial
    ['frontwall.001']: THREE.MeshStandardMaterial
    ['sidewall.001']: THREE.MeshStandardMaterial
    ['roof.001']: THREE.MeshStandardMaterial
  }
}
export default function City({ divisionAmount, radius, ...props }: any) {

  // const DIVISION_AMOUNT = 7; // 10 will produce 36 skyscraper
  // const RADIUS = 380;
  const EXTRA_SKYSCRAPERS = 2; // so it gets updated in the count (these are the 2 big ones)
  const [skyscraperCount, setSkyscraperCount] = useState(360/divisionAmount);

  // useEffect(() => {
  //   if(weatherData) {
  //     setMountainCount(weatherData.weather.skyscraperCount);
  //     setSpreadDistance(weatherData.weather.spreadDistance);
  //     console.log('skyscraperCount', weatherData.weather.skyscraperCount)
  //     console.log('spreadDistance', weatherData.weather.spreadDistance)
  //   }
  // }, [weatherData])

  const ref1 = useRef(null!);
  const ref2 = useRef(null!);
  const ref3 = useRef(null!);
  const ref4 = useRef(null!);
  const dummy = new Object3D();
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  useEffect(() => {
    let allSkyscrapers: any = [];

    // https://stackoverflow.com/questions/43641798/how-to-find-x-and-y-coordinates-on-a-flipped-circle-using-javascript-methods#:~:text=Typically%2C%20to%20find%20the%20x,sin(degrees%E2%80%8E%C2%B0)).
    function circleXZ(radius: number, theta: number) {
      // Convert angle to radians
      theta = (theta-90) * Math.PI/180;
      return {x: radius*Math.cos(theta),
        z: -radius*Math.sin(theta)}
    }

    // the 2 big ones
    allSkyscrapers.push({position: [260, 80, -100], scale: 120})
    allSkyscrapers.push({position: [220,80,-160], scale: 100})

    for (let theta=0; theta<=360; theta += divisionAmount) {
      let answer = circleXZ(radius, theta);
      // console.log('(x, y) = ' + '(' + answer.x + ', ' + answer.y + ') for theta=' + theta);
      allSkyscrapers.push({position: [answer.x, 0, answer.z]});
    }

    // TODO CHECK HOW MANY THERE ACTUALLY ARE
    // console.log('allSkyscrapers', allSkyscrapers);

    // pow 2 because value comes in as a sqrt
    allSkyscrapers.forEach((skyscraper: any, index: number) => {
      // dummy.position.set(Math.random()*10*i,0, Math.random()*10*i);
      dummy.position.set(skyscraper.position[0], skyscraper.position[1], skyscraper.position[2]);
      dummy.rotation.set(0, Math.random(), 0);
      // dummy.position.set(0, 2, 0);
      // dummy.scale.setScalar(1.5+Math.random()+Math.random());

      if (skyscraper.scale) {
        dummy.scale.setScalar(skyscraper.scale);
      } else {
        dummy.scale.setScalar(50 + (Math.random() * 70) + (Math.random() * 50));
      }

      dummy.updateMatrix();
      // @ts-ignore
      ref1.current.setMatrixAt(index, dummy.matrix);
      // @ts-ignore
      ref2.current.setMatrixAt(index, dummy.matrix);
      // @ts-ignore
      ref3.current.setMatrixAt(index, dummy.matrix);
      // @ts-ignore
      ref4.current.setMatrixAt(index, dummy.matrix);
    });
    // @ts-ignore
    ref1.current.instanceMatrix.needsUpdate = true;
    // @ts-ignore
    ref2.current.instanceMatrix.needsUpdate = true;
    // @ts-ignore
    ref3.current.instanceMatrix.needsUpdate = true;
    // @ts-ignore
    ref4.current.instanceMatrix.needsUpdate = true;
  }, [skyscraperCount]);

  // Math.pow(count,2)*4], because there are 4 quadrants each squared
  // todo look into shadows
  return (
    <>
      <instancedMesh ref={ref1} args={[nodes.defaultMaterial.geometry, materials['glass_off.001'], skyscraperCount+EXTRA_SKYSCRAPERS]} >
      </instancedMesh>
      <instancedMesh ref={ref2} args={[nodes.defaultMaterial001.geometry, materials['frontwall.001'], skyscraperCount+EXTRA_SKYSCRAPERS]} >
      </instancedMesh>
      <instancedMesh ref={ref3} args={[nodes.defaultMaterial005.geometry, materials['sidewall.001'], skyscraperCount+EXTRA_SKYSCRAPERS]} >
      </instancedMesh>
      <instancedMesh ref={ref4} args={[nodes.defaultMaterial007.geometry, materials['roof.001'], skyscraperCount+EXTRA_SKYSCRAPERS]} >
      </instancedMesh>
    </>
  )
}
