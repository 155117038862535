import React from "react";

/**
 * ObjectColorPicker
 * @param mode
 * @param objectColor
 * @param pointerControls
 * @param setObjectColor
 * @constructor
 */
export function ObjectColorPicker(
  {mode, objectColor, pointerControls, setObjectColor}:
    {mode: string, objectColor: string, pointerControls: any, setObjectColor: Function }
) {

  /**
   * handleChangeObjectColor
   * @param color
   */
  const handleChangeObjectColor = (color: string) => {
    setTimeout(() => {pointerControls.current.unlock()},100);
    setObjectColor(color);
  };

  return (
    <>
      {/*todo move to component*/}
      <div className="object-color-box">
        <div className={objectColor === 'black' ? 'selected' : ''} style={{backgroundColor: 'black'}} onClick={() => handleChangeObjectColor('black')}></div>
        <div className={objectColor === 'brown' ? 'selected' : ''} style={{backgroundColor: '#4d3210'}} onClick={() => handleChangeObjectColor('brown')}></div>
        <div className={objectColor === 'red' ? 'selected' : ''} style={{backgroundColor: 'red'}} onClick={() => handleChangeObjectColor('red')}></div>
        <div className={objectColor === 'white' ? 'selected' : ''} style={{backgroundColor: 'white'}} onClick={() => handleChangeObjectColor('white')}></div>
        <div className={objectColor === 'pink' ? 'selected' : ''} style={{backgroundColor: 'pink'}} onClick={() => handleChangeObjectColor('pink')}></div>
        <div className={objectColor === 'blue' ? 'selected' : ''} style={{backgroundColor: 'blue'}} onClick={() => handleChangeObjectColor('blue')}></div>
        <div className={objectColor === 'yellow' ? 'selected' : ''} style={{backgroundColor: 'yellow'}} onClick={() => handleChangeObjectColor('yellow')}></div>
        <div className={objectColor === 'orange' ? 'selected' : ''} style={{backgroundColor: 'orange'}} onClick={() => handleChangeObjectColor('orange')}></div>
        <div className={objectColor === 'purple' ? 'selected' : ''} style={{backgroundColor: 'purple'}} onClick={() => handleChangeObjectColor('purple')}></div>

        { mode === 'rocket' && (
          <div className={objectColor === 'green' ? 'selected' : ''} style={{backgroundColor: 'green'}} onClick={() => handleChangeObjectColor('green')}></div>
        )}
        {/*<div className={objectColor === 'rainbow' ? 'selected rainbow' : 'rainbow'} onClick={() => handleChangeObjectColor('purple')}></div>*/}
      </div>
    </>
  )
}
