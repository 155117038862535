import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { RigidBody } from "@react-three/rapier"
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/mountain-one-transformed.glb`;

type GLTFResult = GLTF & {
  nodes: {
    Mountain_A: THREE.Mesh
  }
  materials: {
    LPEP_Atlas01: THREE.MeshStandardMaterial
  }
}

export default function MountainOne({ snow, ...props }: any) {
  const group = useRef<THREE.Group>(null)
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  // todo mountain position may need changing in blender

  return (
    <RigidBody type="fixed" colliders="cuboid">
      <group ref={group} {...props} dispose={null}>
        <mesh castShadow receiveShadow geometry={nodes.Mountain_A.geometry} material={materials.LPEP_Atlas01}>
          <meshStandardMaterial attach="material" color={snow ? 'white' : 'grey'} />
        </mesh>
      </group>
    </RigidBody>
  )
}
