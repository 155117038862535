import {TreeLine} from "./TreeLine";
import React from "react";

export function TreeLines({mode, forestColliders, lowGraphics, snow, objectCount}: any) {
  return (
    <>
      {/* ### Trees */}
      {/*left trees: (the -10s are to tweak for if there are a very small number of roses) */}
      {/*the * 3 on count is to fill any empty space if not many roses */}
      {[...Array(5)].map((x, i) =>
        <TreeLine mode={mode} forestColliders={forestColliders} lowGraphics={lowGraphics} colliderRotation={[0,1.55,0]} colliderPosition={[-10,0,0]} snow={snow} key={`tree${i}`} position={[-(Math.sqrt(objectCount))-10-(i*10),0,-(Math.sqrt(objectCount*10))-10]} rotation={[0,-0.4,0]} objectCount={objectCount*3} lineOffsetX={0} lineOffsetZ={0} extrasOffsetX={0} extrasOffsetZ={0}  />
      )}

      {/*right trees: 2*sqrt because 2 plots across, (the +20s are to tweak for if there are a very small number of roses) */}
      {/*the * 3 on count is to fill any empty space if not many roses */}
      {[...Array(5)].map((x, i) =>
        <TreeLine mode={mode} forestColliders={forestColliders} lowGraphics={lowGraphics} colliderRotation={[0,-1.55,0]} colliderPosition={[10,0,0]} snow={snow} key={`tree${i}`} position={[(Math.sqrt(objectCount))*3+20+(i*10),0,-(Math.sqrt(objectCount*10))+20]} rotation={[0,-0.4,0]} objectCount={objectCount*3} extrasOffsetX={0} extrasOffsetZ={0}/>
      )}
      {/*have added more here than 7*/}

      {/*back line: the *10 at the end is because it is a longer line*/}
      {[...Array(5)].map((x, i) =>
        <TreeLine mode={mode} forestColliders={forestColliders} lowGraphics={lowGraphics} colliderRotation={[0,-1.6,0]} colliderPosition={[-7,0,0]} snow={snow} key={`tree${i}`} position={[-(Math.sqrt(objectCount))-100,0,5+(i*10)]} rotation={[0,1.34,0]} objectCount={objectCount*12} extrasOffsetX={0} extrasOffsetZ={0}/>
      )}

      {/*front line: the *10 at the end is because it is a longer line*/}
      {[...Array(5)].map((x, i) =>
        <TreeLine mode={mode} forestColliders={forestColliders} lowGraphics={lowGraphics} colliderRotation={[0,1.6,0]} colliderPosition={[20,0,0]} snow={snow} key={`tree${i}`} position={[-(Math.sqrt(objectCount))-40,0,-(Math.sqrt(objectCount*10))-(i*10)]} rotation={[0,1.34,0]} objectCount={objectCount*12} extrasOffsetX={0} extrasOffsetZ={0} />
      )}
    </>
  )
}
