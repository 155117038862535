import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { useFrame } from '@react-three/fiber';
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/note-transformed.glb`;
type GLTFResult = GLTF & {
  nodes: {
    Note: THREE.Mesh
    Bow: THREE.Mesh
  }
  materials: {
    Note: THREE.MeshStandardMaterial
    Bow: THREE.MeshStandardMaterial
  }
}

export default function Note({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  useFrame(({ clock }: any) => {
    const a = clock.getElapsedTime();
    group.current.rotation.z = -a;
    group.current.rotation.x = -a;
  });

  return (
    <group ref={group} {...props} dispose={null} scale={0.03} position={[0,1,0]}>
      <mesh castShadow receiveShadow geometry={nodes.Note.geometry} material={materials.Note} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.Bow.geometry} material={materials.Bow} rotation={[-Math.PI / 2, 0, 0]} />
    </group>
  )
}
