import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import React from "react";
import {WallBase} from "../Base/Wall";

export function BrickWall({rotation, position, scale, mode}:any) {

  // todo if this is too big, it might break stuff by not loading in time, may need to deal with that
  let imgs = {
    brick: `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/brick-wall.jpg`,
  };

  const texture = useLoader(THREE.TextureLoader, imgs.brick);
  return (
    <WallBase texture={texture} rotation={rotation} position={position} scale={scale} mode={mode} />
  )
}
