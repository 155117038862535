import {useGLTF} from "@react-three/drei";
import React from "react";
import {Fences} from "../RegularVersion/Fences";

export function ChainFence({ lowGraphics, position, rotation, scale, count }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/chainFence-transformed.glb?v=1`; // ?v=1 for cache busting
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <Fences lowGraphics={lowGraphics} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} count={count} />
  )
}
