import React, {Suspense, useEffect, useState} from 'react';
import moonImg from "../images/moontexture.png";
import * as THREE from 'three';
import { useLoader } from "@react-three/fiber";
import { RigidBody } from "@react-three/rapier"

let imgs = {
  moon: `${process.env.REACT_APP_ASSETS_URL}/moontexture.png`,
};

export default function Globe({ weatherData, sunRise, sunSet, size, color, name, night}: any): JSX.Element {
  const texture = useLoader(THREE.TextureLoader, imgs.moon);
  texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
  texture.repeat.set(5, 5);

  const [position, setPosition] = useState([550, 550, 550]);
  const [moonSize, setMoonSize] = useState('full');

  // console.log(weatherData)
  
  useEffect(() => {
    if(weatherData && weatherData.time && weatherData.time.moonLocation && weatherData.time.moonSize) {
      setPosition(weatherData.time.moonLocation);
      setMoonSize(weatherData.time.moonSize);
    }
  }, [weatherData])

  return (
    <RigidBody type="fixed" colliders="ball" position={[position[0], position[1], position[2]]}>
      {/*<mesh position={[0,0,-10]}>*/}
      <mesh>
        {/*{ !night && (*/}
        {/*  <>*/}
        {/*    <meshStandardMaterial color={color} attach="material" map={texture} />*/}
        {/*    <sphereGeometry attach="geometry" args={size} />*/}
        {/*  </>*/}
        {/*)}*/}

        { (night || sunRise || sunSet) && (
          <>
            <meshStandardMaterial color={color} attach="material" map={texture} emissive={[0.3, 0.3, 0.3]} />
            <sphereGeometry attach="geometry" args={size} />
          </>
        )}
      </mesh>
    </RigidBody>
  )
}
