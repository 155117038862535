// REMEMBER, THIS CHANGES IF YOU REDEPLOY API. FIGURE OUT A WAY TO PEG IT TO UNEGMA.IO, MAYBE USING CLOUDFRONT
const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

/**
 */
export async function getNote(id: string, mode: string): Promise<any []> {
  try {
    let data = await fetch(`${API_ENDPOINT}/objects?id=${id}&mode=${mode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({
      // })
    });

    // the response will then come back as promise, the data of which will need to be accessed as such:
    data = await data.json();
    // console.log(data)
    // console.log(data);
    // @ts-ignore
    return data;
  } catch (err) {
    // console.log(err);
    return [];
  }
}

/**
 */
export async function getObjectsData(mode: string): Promise<any []> {
  try {
    let data = await fetch(`${API_ENDPOINT}/objects?mode=${mode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({
      // })
    });

    // the response will then come back as promise, the data of which will need to be accessed as such:
    data = await data.json();
    // @ts-ignore
    return data;
  } catch (err) {
    // console.log(err);
    return [];
  }
}

/**
 */
export async function getWeatherData(): Promise<any> {
  try {
    let data = await fetch(`${API_ENDPOINT}/weather`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({
      // })
    });

    // the response will then come back as promise, the data of which will need to be accessed as such:
    data = await data.json();
    // console.log(data);
    // @ts-ignore
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

/**
 */
export async function postData(objectData: any, mode: string): Promise<any> {
  try {
    let data = await fetch(`${API_ENDPOINT}/objects?mode=${mode}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectData)
    });

    // the response will then come back as promise, the data of which will need to be accessed as such:
    data = await data.json();
    // console.log(data);
    // @ts-ignore
    return data;
  } catch (err) {
    // console.log(err);
    return {};
  }
}

/**
 */
export async function postFeedback(feedback: any): Promise<any> {
  try {
    let data = await fetch(`${API_ENDPOINT}/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedback)
    });

    // the response will then come back as promise, the data of which will need to be accessed as such:
    data = await data.json();
    // console.log(data);
    // @ts-ignore
    return data;
  } catch (err) {
    // console.log(err);
    return {};
  }
}
