import React, {useEffect, useRef } from 'react'
import TreeOneSnow from "./SnowVersion/TreesSnow";
import TreesLarchSnow from "./SnowVersion/TreesLarchSnow";
import TreesBirchSnow from "./SnowVersion/TreesBirchSnow";
import {InvisibleWall} from "../Base/Wall";
import {RigidBody} from "@react-three/rapier";
import SandBagLines from "../RocketMode/DriedTrees";
import {BrickWall} from "../RocketMode/BrickWall";
import TreesLarch from "./TreesLarch";
import TreesBirch from "./TreesBirch";
import Trees from "./Trees";

export function TreeLine({ mode, forestColliders, lowGraphics, snow, objectCount, rotation, position, extrasOffsetX, extrasOffsetZ, colliderPosition, colliderRotation }: any) {

  return (
    <group rotation={rotation} position={position}>
      {/*<group >*/}
        {/*<RigidBody type="fixed" colliders="cuboid" scale={[100, 2, 10]}>*/}
      {/*todo make sure it moves when the objectCount is increased  */}
      { forestColliders && (mode === 'rose') && (
        <InvisibleWall mode={mode} rotation={colliderRotation} position={colliderPosition} />
      )}

      { forestColliders && (mode === 'rocket') && (
        <BrickWall mode={mode} rotation={colliderRotation} position={colliderPosition} />
      )}

      {/*</group>*/}

      <group>
        { snow && !(mode === 'rocket') && (<TreeOneSnow lowGraphics={lowGraphics} count={Math.sqrt(objectCount)}/>)}
        { !snow && !(mode === 'rocket') && (<Trees lowGraphics={lowGraphics} count={Math.sqrt(objectCount)}/>)}
        { (mode === 'rocket') && (<SandBagLines lowGraphics={lowGraphics} count={Math.sqrt(objectCount)}/>)}

        <group position={[extrasOffsetX, 0, extrasOffsetZ]}>
          { snow && !(mode === 'rocket') && (<TreesLarchSnow lowGraphics={lowGraphics} count={Math.cbrt(objectCount)}/>)}
          { !snow && !(mode === 'rocket') && (<TreesLarch lowGraphics={lowGraphics} count={Math.cbrt(objectCount)}/>)}
          { (mode === 'rocket') && (<SandBagLines lowGraphics={lowGraphics} count={Math.cbrt(objectCount)}/>)}
        </group>
        <group position={[extrasOffsetX, 0, extrasOffsetZ]}>
          { snow && !(mode === 'rocket') && (<TreesBirchSnow lowGraphics={lowGraphics} count={Math.cbrt(objectCount/2)}/>)}
          { !snow && !(mode === 'rocket') && (<TreesBirch lowGraphics={lowGraphics} count={Math.cbrt(objectCount/2)}/>)}
          { (mode === 'rocket') && (<SandBagLines lowGraphics={lowGraphics} count={Math.cbrt(objectCount/2)}/>)}
        </group>
      </group>
    </group>
  )
}

