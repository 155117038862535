import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    Object_7: THREE.SkinnedMesh
    _rootJoint: THREE.Bone
  }
  materials: {
    Dragon_Mat: THREE.MeshStandardMaterial
  }
}

type ActionName = 'CINEMA_4D_Main'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Dragon({position = [0,0,0], rotation = [0,0,0]}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/dragon-transformed.glb`;
  const group = useRef<THREE.Group>(null!)
  const mesh = useRef<any>(null!);
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group)

  const [start] = useState(() => Math.random() * 3000)

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => {
    mixer.clipAction(animations[0], group.current).setDuration(4);
    mixer.clipAction(animations[0], group.current).play();
  }, [animations])


  useFrame((state, delta) => {
    mesh.current.position.y = Math.sin(start + state.clock.elapsedTime) * 5

    // mesh.current.rotation.x = Math.PI / 2 + (Math.sin(start + state.clock.elapsedTime) * Math.PI) / 10

    mesh.current.rotation.y = (Math.sin(start + state.clock.elapsedTime) * Math.PI) / -15
    // mesh.current.rotation.y = 1.8
    // setInteractivePosition({x: mesh.current.position.x, y: mesh.current.position.y, z: mesh.current.position.z});

    let factor = 1 + Math.random() - 0.9;
    let speed = 1;

    // @ts-ignore
    group.current.rotation.y += Math.sin((delta * factor) / 5) * Math.cos((delta * factor) / 5) * 1.5 // these control how quickly it rotates around a central pivot point
    mixer.update(delta * speed)
  })

  useFrame((scene, delta) => {
    mixer?.update(delta)
  });

  const myPosition = {x: position[0], y: position[1], z: position[2]}

  return (
    <group ref={group} dispose={null}
           position={position}
           rotation={rotation}>
      <group name="Scene">
        <group name="Dragon" scale={0.01} ref={mesh} position={[position[0],position[1],position[2]]} rotation={[0,0,0]}>
          <primitive object={nodes._rootJoint} />
          <group name="CINEMA_4D_Editor" position={[-1791.68, 1299.65, 3487.73]} rotation={[0, 1, -0.25]}>
            <group name="Object_272" />
          </group>
          <group name="Dragon001" />
          <group name="Object_6" />
          <skinnedMesh castShadow name="Object_7" geometry={nodes.Object_7.geometry} material={materials.Dragon_Mat} skeleton={nodes.Object_7.skeleton} />
        </group>
      </group>
    </group>
  )
}
