import React, {useEffect, useState} from "react";
import {getDayOrNight} from "../../../helpers/helperFunctions";
import {Sky} from "@react-three/drei";

export function MovingSun ({weatherData, sunRise, setSunRise, sunSet, setSunSet, day, setDay}: any) {

  // these will be used where the user has switched to day time using the fire, but it is actually night
  const [sunPositionX, setSunPositionX] = useState(0);
  const [sunPositionY, setSunPositionY] = useState(100);
  const [sunPositionZ, setSunPositionZ] = useState(0);

  useEffect(() => {
    if(weatherData && weatherData.time && weatherData.time.time) {
      let time = weatherData.time.time;

      const {day, night, sunrise, sunset, sunPosition} = getDayOrNight(time);
      setSunPositionX(sunPosition.x);
      setSunPositionY(sunPosition.y);
      setSunPositionZ(sunPosition.z);
      setSunRise(sunrise);
      setSunSet(sunset);
    }
  }, [weatherData])

  return (
    <>
      {day && (
        <>
          <Sky sunPosition={[sunPositionX, sunPositionY, sunPositionZ]} />
          <ambientLight intensity={0.3} />
        </>
      )}
    </>
  )
}
