import {RocketLion} from "../Lion";
import {RocketPink, RocketSelector} from "./Rocket";
import PlacedRocket from "./PlacedRocket";
import {SignOneCharred} from "./SignOneCharred";
import {LogoSignCharred} from "./LogoSignCharred";
import Candle from "./Candle";
import {Terminal} from "./Terminal";
import {CharredGrass} from "./CharredGrass";
import {GroundCharred} from "./GroundCharred";
import City from "./City";
import {Sandbags} from "./Sandbags";
import Patch from "../Base/Patch";
import React from "react";
import {_SpaceBase} from "../Base/_SpaceBase";
import {FieldRockets} from "./FieldRockets";
import {TreeLines} from "../RegularVersion/TreeLines";
import Explosion from "./Explosion";
import Fireworks from "../Fireworks";
import {LionDark, LionNew} from "../LionNew";

/**
 * SpaceRocket
 * @param searchedRocketScale
 * @param launch
 * @param DEBUG_MODE
 * @param mode
 * @param highlightedCommunity
 * @param signArray
 * @param day
 * @param setDay
 * @param weatherData
 * @param allDone
 * @param objectData
 * @param setShowCreditsModal
 * @param searchedObject
 * @param snow
 * @param lockKeyboard
 * @param setLockKeyboard
 * @param hintMessage
 * @param setShowHintBox
 * @param setHintMessage
 * @param setSlideData
 * @param setSlideModalCharacter
 * @param setShowSlideModal
 * @param forestColliders
 * @param roseSizings
 * @param rocketSizings
 * @param setSlideMessage
 * @param setShowSizeModal
 * @param playerPosition
 * @param setPlayerPosition
 * @param setNight
 * @param night
 * @param donationAmount
 * @param objectColor
 * @param placedObject
 * @param setPlacedObject
 * @param placing
 * @param setPlacing
 * @param objectArrays
 * @param objectCount
 * @param setObjectCount
 * @param pointerControls
 * @param setShowInfoModal
 * @param setHasObject
 * @param hasObject
 * @param lowGraphics
 * @param setLowGraphics
 * @param pageNumber
 * @param setPageNumber
 * @constructor
 */
export function _SpaceRocket(
  { searchedRocketScale, launch, DEBUG_MODE, mode, highlightedCommunity, signArray, day, setDay, weatherData, allDone,
    objectData, setShowCreditsModal, searchedObject, snow, lockKeyboard, setLockKeyboard, hintMessage,
    setShowHintBox, setHintMessage, setSlideData, setSlideModalCharacter, setShowSlideModal, forestColliders,
    roseSizings, rocketSizings, setSlideMessage, setShowSizeModal, playerPosition, setPlayerPosition, setNight, night,
    donationAmount, objectColor, placedObject, setPlacedObject, placing, setPlacing, objectArrays,
    objectCount, setObjectCount, pointerControls, setShowInfoModal, setHasObject, hasObject, lowGraphics, setLowGraphics,
    pageNumber, setPageNumber, explosion, setExplosion}
    : any)
{
  return (
    <>
      <_SpaceBase
        searchedRocketScale={searchedRocketScale} launch={launch} DEBUG_MODE={DEBUG_MODE} mode={mode}
        highlightedCommunity={highlightedCommunity} signArray={signArray} day={day} setDay={setDay}
        weatherData={weatherData} allDone={allDone} objectData={objectData} setShowCreditsModal={setShowCreditsModal}
        searchedObject={searchedObject} snow={snow} lockKeyboard={lockKeyboard} setLockKeyboard={setLockKeyboard}
        setShowHintBox={setShowHintBox} setHintMessage={setHintMessage} setSlideData={setSlideData}
        setSlideModalCharacter={setSlideModalCharacter} setShowSlideModal={setShowSlideModal}
        forestColliders={forestColliders} roseSizings={roseSizings} rocketSizings={rocketSizings}
        setSlideMessage={setSlideMessage} setShowSizeModal={setShowSizeModal} playerPosition={playerPosition}
        setPlayerPosition={setPlayerPosition} setNight={setNight} night={night} donationAmount={donationAmount}
        objectColor={objectColor} placedObject={placedObject} setPlacedObject={setPlacedObject} placing={placing}
        setPlacing={setPlacing} objectCount={objectCount} setObjectCount={setObjectCount} hintMessage={hintMessage}
        pointerControls={pointerControls} setShowInfoModal={setShowInfoModal} setHasObject={setHasObject} hasObject={hasObject}
        lowGraphics={lowGraphics} setLowGraphics={setLowGraphics} pageNumber={pageNumber} setPageNumber={setPageNumber}
      >
          {/*<RocketLion*/}
          {/*  mode={mode} lockKeyboard={lockKeyboard} setHintMessage={setHintMessage} hintMessage={hintMessage}*/}
          {/*  setShowHintBox={setShowHintBox} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}*/}
          {/*  setHasObject={setHasObject} hasObject={hasObject} scale={0.09} position={[-3, 0.39, 3]} rotation={[0,0.9,0]}*/}
          {/*  setShowInfoModal={setShowInfoModal} pointerControls={pointerControls}*/}
          {/*/>*/}

          <LionDark scale={0.01} position={[-3, -0.5, 3]} night={night}
                   mode={mode} lockKeyboard={lockKeyboard} setHintMessage={setHintMessage} hintMessage={hintMessage}
                   setShowHintBox={setShowHintBox} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}
                   setHasObject={setHasObject} hasObject={hasObject} rotation={[0,0.9,0]}
                   setShowInfoModal={setShowInfoModal} pointerControls={pointerControls}
          />

          <RocketPink position={[-3.3,-0.5,3.5]} scale={1.5} />

          { explosion && (
            <>
                {/*<Explosion scale={30} position={[searchedObject.position.x, searchedObject.position.y, searchedObject.position.z]} />*/}
            </>
          )}

          { launch && (
            <>
                <Fireworks scale={0.3} position={[-500,0,-500]} duration={8.8} />
                <Fireworks scale={0.3} position={[500,0,-500]} duration={9} />
                <Fireworks scale={0.3} position={[-500,0,500]} duration={8.7} />
                <Fireworks scale={0.3} position={[500,0,500]} duration={9.2} />
                <Fireworks scale={0.3} position={[0,0,750]} duration={9.3} />
                <Fireworks scale={0.3} position={[750,0,0]} duration={9.1} />
                <Fireworks scale={0.3} position={[-750,0,0]} duration={9.4} />
                <Fireworks scale={0.3} position={[0,0,-750]} duration={8.9} />
            </>
          )}

          { objectArrays && (
            <FieldRockets searchedObject={searchedObject} objectArrays={objectArrays} />
          )}

          { placedObject && (
            <PlacedRocket
              objectColor={objectColor} placedObject={placedObject} rocketSizings={rocketSizings}
              donationAmount={donationAmount}
            />
          )}

          <SignOneCharred
            lockKeyboard={lockKeyboard} setSlideMessage={setSlideMessage}
            setShowSlideModal={setShowSlideModal} setSlideModalCharacter={setSlideModalCharacter}
            pointerControls={pointerControls} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}
            position={[1,-0.5,0]} scale={0.4} rotation={[0,0.5,0]}
          />
          {/*<LogoSignCharred signArray={signArray} />*/}

          { searchedObject && (
            <>
                <RocketSelector
                  searchedObject={searchedObject} launch={launch}
                  scale={searchedRocketScale ? searchedRocketScale : 1.5} setExplosion={setExplosion} explosion={explosion}
                />
                <Candle
                  launch={launch}
                  position={[searchedObject.position.x,searchedObject.position.y - 0.5 ,searchedObject.position.z]}
                  rotation={[1.6,0,0]} scale={0.1}
                />
                <Terminal
                  setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage}
                  setSlideModalCharacter={setSlideModalCharacter} position={[-7,0.5,1.4]} rotation={[0,-3.15,0]}
                  lockKeyboard={lockKeyboard} playerPosition={playerPosition} pointerControls={pointerControls}
                />
            </>
          )}

          { !lowGraphics && (<CharredGrass spreadDistance={8} scale={0.7} count={10} rotation={[0,0,0]} />)}
          <GroundCharred />

          <City radius={300} divisionAmount={7} />
          <Sandbags position={[-12,-1.1,6]} scale={2} rotation={[0, 1.2, 0]} />
          {/*todo change this to wall lines*/}
          <TreeLines
            mode={mode} forestColliders={forestColliders} lowGraphics={lowGraphics} snow={snow} objectCount={objectCount}
          />

          {/*odds*/}
          <Patch
            mode={mode} lowGraphics={lowGraphics} snow={snow} position={[0,0,0]} rotation={[0,9.409,0]}
            objectCount={objectCount} patch={1}
          />
          {/*evens*/}
          <Patch
            mode={mode} lowGraphics={lowGraphics} snow={snow} position={[6,0,0]} rotation={[0,-10.995,0]}
            objectCount={objectCount} patch={2}
          />

      </_SpaceBase>
    </>
  )
}
