import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {GroundBase} from "../Base/Ground";

export function GroundCharred() {
  let img = {
    charredGrass: `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/grass.jpg`
  };
  const texture = useLoader(THREE.TextureLoader, img.charredGrass);

  return (
    <GroundBase texture={texture} />
  )
}
