import React, {Suspense, useEffect, useRef, useState} from "react";
import {useFrame, useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
import * as THREE from "three";
import {interactivePlayerPosition} from "../../../helpers/helperFunctions";
import {useKeyboardControls} from "@react-three/drei"; // todo replace this

// todo figure out how to use local version
// https://discourse.threejs.org/t/how-to-use-draco-loader/5363/26
// https://stackoverflow.com/questions/56271427/can-i-make-the-glb-file-smaller
// https://stackoverflow.com/questions/56071764/how-to-use-dracoloader-with-gltfloader-in-reactjs
// https://github.com/google/draco/tree/master/javascript/example
// const DECODER_PATH="../node_modules/three/examples/js/libs/draco/gltf/"
// const DECODER_PATH="https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/";
const DECODER_PATH="https://www.gstatic.com/draco/versioned/decoders/1.4.1/";

// todo add instanced mesh with multiple birds
export default function Parakeet({pos = [0,0,0], rot = [0,0,0], setShowSlideModal, setSlideMessage, pointerControls, playerPosition, setSlideModalCharacter}: any) {
  const mesh = useRef<THREE.Mesh>(null!);

  // @ts-ignore
  // useFrame((state, delta) => (mesh.current.rotation['y'] += roty))
  const group = useRef(null)

  // todo CHANGE THIS
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/parakeet.gltf`;
  // let imageUrl = "https://assets.unegma.net/rainprotocol.xyz/various/parakeet.gltf";
  // let imageUrl = "https://assets.unegma.net/rainprotocol.xyz/various/parakeet-compressed.gltf";
  let { nodes, materials, animations }: any = useLoader(GLTFLoader, ITEM_URI, loader => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({type: 'js'});
    dracoLoader.setDecoderPath(DECODER_PATH);
    // @ts-ignore
    loader.setDRACOLoader(dracoLoader);
  });
  const myPosition = {x: pos[0], y: pos[1], z: pos[2]}
  const [start] = useState(() => Math.random() * 5000)
  const [interactivePosition, setInteractivePosition] = React.useState(myPosition);

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())
  // @ts-ignore
  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])
  useFrame((state, delta) => {
    mesh.current.position.y = Math.sin(start + state.clock.elapsedTime) * 5
    mesh.current.rotation.x = Math.PI / 2 + (Math.sin(start + state.clock.elapsedTime) * Math.PI) / 10
    mesh.current.rotation.y = (Math.sin(start + state.clock.elapsedTime) * Math.PI) / 2
    // setInteractivePosition({x: mesh.current.position.x, y: mesh.current.position.y, z: mesh.current.position.z});

    let factor = 1 + Math.random() - 0.5;
    let speed = 1;

    // @ts-ignore
    group.current.rotation.y += Math.sin((delta * factor) / 2) * Math.cos((delta * factor) / 2) * 1.5
    mixer.update(delta * speed)
  })

  // console.log('parakeet');
  // console.log(nodes);
  // console.log(animations);
  let scale = 1;

  const [, get] = useKeyboardControls();
  const [interacting, setInteracting] = React.useState(false);

  useFrame((state) => {
    const { action } = get();
    if (action && !interacting) {

      // todo this seems to be a fixed area, not the moving area of the bird
      if (interactivePlayerPosition(playerPosition, interactivePosition, 0, 3)) {
        // setInteracting(true);
        // setSlideModalCharacter('martin');
        // setSlideMessage('parakeet');
        // setShowSlideModal(true);
        // setTimeout(() => {
        //   pointerControls.current.unlock();
        // }, 100);
        // setTimeout(() => {
        //   setInteracting(false);
        // }, 100);
      }
    }
  });

  return (
    <group
      ref={group} dispose={null}
      position={pos}
      rotation={rot}
    >
      {/*/!*Chairs_1*!/*/}

      {/*todo having this makes it fly in a proper circle*/}
      <group
        position={[-50,3.15,-10]}
        rotation={[0,0,0]}
      >
        <mesh
          ref={mesh}
          name="Object_0"
          scale={[scale, scale, scale]}
          // rotation={[rot[0],rot[1],rot[2]]}
          position={[pos[0],pos[1],pos[2]]}
          material={nodes.Object_0.material}
          geometry={nodes.Object_0.geometry}
          morphTargetDictionary={nodes.Object_0.morphTargetDictionary}
          morphTargetInfluences={nodes.Object_0.morphTargetInfluences}
          rotation={[Math.PI / 2, 0, 0]}
          // geometry={nodes.Object_0.geometry}
          // material={materials.Material_0_COLOR_0}
        />


      </group>

      {/*Tables*/}
      {/*<group*/}
      {/*  position={[-50,5,-20]}*/}
      {/*  rotation={[0,0,0]}*/}
      {/*>*/}
      {/*  <mesh*/}
      {/*    ref={mesh}*/}
      {/*    scale={[scale, scale, scale]}*/}
      {/*    rotation={[rot[0],rot[1],rot[2]]}*/}
      {/*    position={[pos[0],pos[1],pos[2]]}*/}
      {/*    material={nodes.Tables.children[0].material}*/}
      {/*    geometry={nodes.Tables.children[0].geometry}*/}
      {/*  />*/}
      {/*  <mesh*/}
      {/*    ref={mesh}*/}
      {/*    scale={[scale, scale, scale]}*/}
      {/*    rotation={[rot[0],rot[1],rot[2]]}*/}
      {/*    position={[pos[0],pos[1],pos[2]]}*/}
      {/*    material={nodes.Tables.children[1].material}*/}
      {/*    geometry={nodes.Tables.children[1].geometry}*/}
      {/*  />*/}
      {/*</group>*/}


    </group>
  )
}

