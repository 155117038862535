import * as THREE from 'three'
import React, { useRef } from 'react'
import {useGLTF, useKeyboardControls} from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {RigidBody} from "@react-three/rapier";
import {useFrame} from "@react-three/fiber";
import {interactivePlayerPosition} from "../../../helpers/helperFunctions";


type GLTFResult = GLTF & {
  nodes: {
    Well: THREE.Mesh
  }
  materials: {
    Well: THREE.MeshStandardMaterial
  }
}

export function Well({ setSlideModalCharacter, setSlideMessage, setShowSlideModal, scale, position, rotation, pointerControls, playerPosition, lockKeyboard }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/regular-version/well-transformed.glb`;

  const group = useRef<THREE.Group>(null!)

  const myPosition = {x: position[0], y: position[1], z:position[2]}
  const [, get] = useKeyboardControls();
  const [interacting, setInteracting] = React.useState(false);

  useFrame((state) => {
    const {action} = get();
    if (action && !interacting && !lockKeyboard) {

      if (interactivePlayerPosition(playerPosition, myPosition, scale, 1.5)) {
        setInteracting(true);
        // console.log('Sign Interaction');
        setSlideModalCharacter('well');
        setSlideMessage('well');
        setShowSlideModal(true);
        setTimeout(() => {
          pointerControls.current.unlock();
        }, 100);
        setTimeout(() => {
          setInteracting(false);
        }, 100);
      }
    }
  })

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <group ref={group} scale={scale} position={position} rotation={rotation}>
      <mesh castShadow receiveShadow geometry={nodes.Well.geometry} material={materials.Well} rotation={[Math.PI / 2, 0, 0]} />
      <RigidBody type="fixed" colliders="cuboid">
        <mesh>
          <boxGeometry args={[70, 300, 70]} />
            <meshPhongMaterial color="#ff0000" opacity={0.01} transparent />
        </mesh>
      </RigidBody>
    </group>
  )
}
