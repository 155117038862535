import {useGLTF} from "@react-three/drei";
import React from "react";
import {SignBase} from "../RegularVersion/SignOne";

export function SignOneCharred({ lockKeyboard, setShowSlideModal, setSlideMessage, setSlideModalCharacter, playerPosition, setPlayerPosition, position, scale, pointerControls, rotation}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/Sign-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <SignBase nodes={nodes} materials={materials} lockKeyboard={lockKeyboard} setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage} setSlideModalCharacter={setSlideModalCharacter} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition} position={position} scale={scale} pointerControls={pointerControls} rotation={rotation} />
  )
}
