import * as THREE from 'three'
import React, { useRef } from 'react'
import {useGLTF, useKeyboardControls} from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { RigidBody } from '@react-three/rapier';
import {useFrame} from "@react-three/fiber";
import {interactivePlayerPosition} from "../../../../helpers/helperFunctions";

const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/snow-version/stump-snow-transformed.glb`;

type GLTFResult = GLTF & {
  nodes: {
    Stump: THREE.Mesh
  }
  materials: {
    Stump: THREE.MeshStandardMaterial
  }
}

export default function StumpSnow({ lockKeyboard, pointerControls, setShowSizeModal, playerPosition, setPlayerPosition, scale, position, rotation }: any) {
  const group = useRef<THREE.Group>(null)
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  const myPosition = {x: position[0], y: position[1], z:position[2]}
  const [, get] = useKeyboardControls();
  const [interacting, setInteracting] = React.useState(false);

  useFrame((state) => {
    const { action } = get();
    if (action && !interacting && !lockKeyboard) {
      if (interactivePlayerPosition(playerPosition, myPosition, scale, 1)) {
        setInteracting(true);
        console.log('Stump Interaction');
        setShowSizeModal(true);

        setTimeout(() => {
          pointerControls.current.unlock();
        }, 100);

        setTimeout(() => {
          setInteracting(false);
        }, 100);
      }
    }
  });

  return (
    <group ref={group} scale={scale} position={position} rotation={rotation} dispose={null}>
      <RigidBody type="fixed" colliders="cuboid">
        <mesh castShadow receiveShadow geometry={nodes.Stump.geometry} material={materials.Stump} position={[0,0,0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
      </RigidBody>
    </group>
  )
}
