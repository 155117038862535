import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

const RPC_URLS: { [chainId: number]: string } = {
  1: process.env.REACT_APP_INFURA_URL_ETHEREUM as string,
  137: process.env.REACT_APP_INFURA_URL_POLYGON as string,
};

// todo remove 80001 in production
export const injected = new InjectedConnector({ supportedChainIds: [1, 137] }); // 80001 for mumbai

// todo could potentially add these back in
export const walletconnect = new WalletConnectConnector({
  rpc: { 137: RPC_URLS[137], 1: RPC_URLS[1] },
  qrcode: true
});
