import React, {useEffect, useRef } from 'react'
import {useGLTF} from "@react-three/drei";
import {Object3D} from "three";
import {GLTF} from "three-stdlib";
import * as THREE from "three";

type GLTFResult = GLTF & {
  nodes: {
    Rose: THREE.Mesh
  }
  materials: {
    Rose: THREE.MeshStandardMaterial
  }
}

/**
 * I THINK searchdObject will be null if not viewing, it probably should be an optional parameter
 *
 * @param searchedObject
 * @param nodes
 * @param materials
 * @param objectArray
 * @constructor
 */
function Roses({searchedObject, nodes, materials, objectArray}: any) {
  const ref = useRef(null!);
  const dummy = new Object3D();

  useEffect(() => {
    if (objectArray) {
      objectArray.map((object: any, index: number) => {
        if (searchedObject && (object.position.x === searchedObject.position.x && object.position.y === searchedObject.position.y && object.position.z === searchedObject.position.z)) {
          object.position.y = -1000; // hide the object so that can replace with an animatable one
        }
        dummy.rotation.set(-0.1 - Math.random(), 0.91 + Math.random(), -1.2);

        // TODO MIGHT WANT TO DO SCALING ON BACKEND SO DON'T REVEAL DONATION AND CAN KEEP SIZE
        // todo this will cause issues if eth and matic go out of sync

        // todo move this to a 'getRoseSize' function so that you can use on the individual objects too (for when viewing your object)

        dummy.scale.setScalar(object.roseSize.size);
        dummy.position.set(object.position.x + object.roseSize.offset.x, object.position.y + object.roseSize.offset.y, object.position.z + object.roseSize.offset.z);

        dummy.updateMatrix();
        // @ts-ignore
        ref.current.setMatrixAt(index, dummy.matrix);
      })
      // @ts-ignore
      ref.current.instanceMatrix.needsUpdate = true;
    }
  }, [objectArray]);

  return (
    <>
      { objectArray && (
        <>
          <instancedMesh ref={ref} args={[nodes.Rose.geometry, materials.Rose, objectArray.length]}>
          </instancedMesh>
        </>
      )}
    </>
  )
}

export function RosesBlack({objectArray, searchedObject}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--black-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Roses searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RosesBlue({objectArray, searchedObject}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--blue-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Roses searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RosesBrown({objectArray, searchedObject}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--brown-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Roses searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RosesOrange({objectArray, searchedObject}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--orange-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Roses searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RosesPink({objectArray, searchedObject}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--pink-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Roses searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RosesPurple({objectArray, searchedObject}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--purple-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Roses searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RosesRed({objectArray, searchedObject}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--red-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Roses searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RosesWhite({objectArray, searchedObject}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--white-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Roses searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RosesYellow({objectArray, searchedObject}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--yellow-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Roses searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
