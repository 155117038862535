import React, {useEffect, useRef } from 'react'
import {useGLTF} from "@react-three/drei";
import {Object3D} from "three";
import {GLTF} from "three-stdlib";
import * as THREE from "three";

type GLTFResult = GLTF & {
  nodes: {
    Rocket_1: THREE.Mesh
    Rocket_2: THREE.Mesh
  }
  materials: {
    BodyColor: THREE.MeshPhysicalMaterial
  }
}

function Rockets({searchedObject, nodes, materials, objectArray}: any) {

  const ref1 = useRef(null!);
  const ref2 = useRef(null!);
  const dummy = new Object3D();

  useEffect(() => {
    if (objectArray) {
      objectArray.map((object: any, index: number) => {
        if (searchedObject && (object.position.x === searchedObject.position.x && object.position.y === searchedObject.position.y && object.position.z === searchedObject.position.z)) {
          object.position.y = -1000; // hide the rocket so that can replace with an animatable one
        }

        // dummy.rotation.set(-0.86 -Math.random(), 0.91 + Math.random(), -1.2);

        // TODO MIGHT WANT TO DO SCALING ON BACKEND SO DON'T REVEAL DONATION AND CAN KEEP SIZE
        // todo this will cause issues if eth and matic go out of sync
        dummy.scale.setScalar(object.rocketSize.size);
        dummy.position.set(object.position.x + object.rocketSize.offset.x, object.position.y + object.rocketSize.offset.y - 0.5, object.position.z + object.rocketSize.offset.z);

        dummy.updateMatrix();
        // @ts-ignore
        ref1.current.setMatrixAt(index, dummy.matrix);
        // @ts-ignore
        ref2.current.setMatrixAt(index, dummy.matrix);
      })
      // @ts-ignore
      ref1.current.instanceMatrix.needsUpdate = true;
      // @ts-ignore
      ref2.current.instanceMatrix.needsUpdate = true;
    }
  }, [objectArray]);

  return (
    <>
      { objectArray && (
        <>
          {/*<mesh geometry={nodes.Rocket_1.geometry} material={nodes.Rocket_1.material} />*/}
          {/*<mesh geometry={nodes.Rocket_2.geometry} material={materials.BodyColor} />      */}

          {/*<group  rotation={[-Math.PI / 2, 0, Math.PI]} scale={50}>*/}
          {/*CAN USE THIS TO MAKE IT WHITE materials.BodyColor1*/}
          <instancedMesh ref={ref2} args={[nodes.Rocket_1.geometry, nodes.Rocket_1.material, objectArray.length]}>
          </instancedMesh>
          <instancedMesh ref={ref1} args={[nodes.Rocket_2.geometry, nodes.Rocket_2.material, objectArray.length]}>
          </instancedMesh>
          {/*</group>*/}
        </>
      )}
    </>
  )
}

export function RocketsBlack({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--black-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RocketsBlue({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--blue-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RocketsBrown({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--brown-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RocketsOrange({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--orange-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RocketsPink({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--pink-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RocketsPurple({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--purple-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RocketsRed({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--red-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RocketsWhite({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--white-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RocketsYellow({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--yellow-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
export function RocketsGreen({searchedObject, objectArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--green-transformed.glb`;

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <Rockets searchedObject={searchedObject} nodes={nodes} materials={materials} objectArray={objectArray} />
  )
}
