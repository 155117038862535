import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--black-transformed.glb`;

type GLTFResult = GLTF & {
  nodes: {
    Rocket_1: THREE.Mesh
    Rocket_2: THREE.Mesh
  }
  materials: {
    BodyColor: THREE.MeshPhysicalMaterial
  }
}

export default function CarriedRocket({ position, rotation, scale }: any) {
  const group = useRef<THREE.Group>(null)
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <group ref={group} dispose={null} position={position} rotation={rotation} scale={scale}>
      <mesh geometry={nodes.Rocket_1.geometry} material={materials.BodyColor} />
      <mesh geometry={nodes.Rocket_2.geometry} material={nodes.Rocket_1.material} />
    </group>
  )
}
