import * as THREE from 'three'
import React, {useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    Rocket_1: THREE.Mesh
    Rocket_2: THREE.Mesh
  }
  materials: {
    BodyColor: THREE.MeshPhysicalMaterial
  }
}

/**
 * RocketBase
 * @param nodes
 * @param materials
 * @param launch
 * @param position
 * @param rotation
 * @param scale
 * @constructor
 */
export function RocketBase({ nodes, materials, launch, position, rotation, scale, explosion, setExplosion}: any) {
  const group = useRef<THREE.Group>(null!)

  useFrame(() => {
    if (launch) {
      group.current.position.y += 0.05; // change on candle too
    }

    if (group.current.position.y >= 50) {
      setExplosion(true);
    }
  })

  return (
    <group ref={group} dispose={null} position={position} rotation={rotation} scale={scale}>
      <mesh geometry={nodes.Rocket_1.geometry} material={nodes.Rocket_1.material} />
      <mesh geometry={nodes.Rocket_2.geometry} material={nodes.Rocket_2.material} />
    </group>
  )
}


export function RocketRed({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--red-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}

export function RocketBlack({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--black-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}

export function RocketYellow({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--yellow-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}
export function RocketOrange({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--orange-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}
export function RocketBrown({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--brown-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}
export function RocketPurple({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--purple-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}
export function RocketBlue({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--blue-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}

export function RocketWhite({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--white-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}
export function RocketPink({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--pink-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}

export function RocketRainbow({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--rainbow-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}
export function RocketGreen({ launch, position, rotation, scale, explosion, setExplosion}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/rocket--green-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RocketBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} explosion={explosion} setExplosion={setExplosion}/>
  )
}

/**
 * RocketSelector
 * @param searchedObject
 * @param launch
 * @param scale
 * @constructor
 */
export function RocketSelector({searchedObject, launch, scale, explosion, setExplosion}: any) {
  switch(searchedObject.color) {
    case 'red':
      return(<RocketRed explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    case 'black':
      return(<RocketBlack explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    case 'orange':
      return(<RocketOrange explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    case 'yellow':
      return(<RocketYellow explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    case 'purple':
      return(<RocketPurple explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    case 'white':
      return(<RocketWhite explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    case 'brown':
      return(<RocketBrown explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    case 'blue':
      return(<RocketBlue explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    case 'green':
      return(<RocketGreen explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    case 'pink':
      return(<RocketPink explosion={explosion} setExplosion={setExplosion} launch={launch} position={[searchedObject.position.x,searchedObject.position.y-0.5,searchedObject.position.z]} scale={searchedObject.rocketSize.size} rotation={[0,0,0]}/>)
    default:
      return(<></>)
  }
}
