import * as THREE from "three"
import * as RAPIER from "@dimforge/rapier3d-compat"
import { useRef } from "react"
import React from "react";
import { useThree, useFrame } from "@react-three/fiber"
import { useKeyboardControls } from "@react-three/drei"
import { CapsuleCollider, RigidBody, useRapier } from "@react-three/rapier"
import CarriedRose from "./RegularVersion/CarriedRose";
import {checkObjectPlacement} from "../../helpers/helperFunctions";
import CarriedRocket from "./RocketMode/CarriedRocket";
// import Axe from "./Axe"

let SPEED = 7; // todo change to 6 or 7
let JUMP_HEIGHT = 7.5;
// let JUMP_HEIGHT = 200; // for high level view
let PLAYER_START_POSITION = [2,1,14];
// let PLAYER_START_POSITION = [-0.05239903926849365, 1.2482167482376099, 4.106739044189453];
// let PLAYER_START_POSITION = [-40.63,26.39,-39.73]; // for debugging (top of invisible stairs)

const direction = new THREE.Vector3()
const frontVector = new THREE.Vector3()
const sideVector = new THREE.Vector3()
const rotation = new THREE.Vector3()


// todo move player position into some kind of global hook with tracking


/**
 * Player
 *
 * @param placing
 * @param setPlacing
 * @param mode
 * @param DEBUG_MODE
 * @param position
 * @param allDone
 * @param setShowCreditsModal
 * @param objectData
 * @param searchedObject
 * @param lockKeyboard
 * @param hintMessage
 * @param setHintMessage
 * @param setSlideModalCharacter
 * @param setSlideMessage
 * @param setShowSlideModal
 * @param playerPosition
 * @param setPlayerPosition
 * @param pointerControls
 * @param lerp
 * @param setHasObject
 * @param hasObject
 * @param setPlacedObject
 * @constructor
 */
export function Player(
  { placing, setPlacing, mode, DEBUG_MODE, position = PLAYER_START_POSITION, allDone,
    setShowCreditsModal, objectData, searchedObject, lockKeyboard,
    hintMessage, setHintMessage, setSlideModalCharacter, setSlideMessage, setShowSlideModal,
    playerPosition, setPlayerPosition, pointerControls, lerp = THREE.MathUtils.lerp,
    setHasObject, hasObject, setPlacedObject
  }: any)
{
  const object = useRef(null)
  const ref = useRef(null)
  const rapier = useRapier()
  const { camera } = useThree()
  const [, get] = useKeyboardControls()

  // @ts-ignore
  function handleRotation() { object.current.children[0].rotation.x = -0.5;}

  /**
   *
   */
  useFrame((state) => {
    const { forward, backward, left, right, jump, shift, control, action, place, info, debug } = get()
    // @ts-ignore
    const velocity = ref.current.linvel()

    if (control && !lockKeyboard) {
      // update camera
      // @ts-ignore
      let translation = ref.current.translation();
      // @ts-ignore
      camera.position.set(translation.x,translation.y = translation.y-0.5, translation.z)
    } else {
      // @ts-ignore
      camera.position.set(...ref.current.translation())
    }

    // update object
    if (hasObject && !searchedObject) {
      // @ts-ignore
      object.current.children[0].rotation.x = lerp(object.current.children[0].rotation.x, Math.sin((velocity.length() > 1) * state.clock.elapsedTime * 10) / 6, 0.1)
      // @ts-ignore
      object.current.rotation.copy(camera.rotation)
      // @ts-ignore
      object.current.position.copy(camera.position).add(camera.getWorldDirection(rotation).multiplyScalar(1))
    }

    // movement - backward, forward, left, right, are booleans which need converting to numbers
    frontVector.set(0, 0, backward - forward)
    sideVector.set(left - right, 0, 0)

    // todo something seems to break if press option and keys when running (remove tab indexes from everything in navbar (indluding title)

    if (shift && !lockKeyboard) { // todo might need to add a isRunning state
      direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED+5).applyEuler(camera.rotation)
    } else if (control) { // todo might need to add a isDucking state
      direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED-4).applyEuler(camera.rotation)
    } else if (debug && !lockKeyboard) {
      if (DEBUG_MODE) {
        SPEED = 25;
        JUMP_HEIGHT = 150; // 90 to hit clouds, 150 to land on moon
      }
    } else if (info && !lockKeyboard) {
      setTimeout(() => {pointerControls.current.unlock()},100);
      setShowCreditsModal(true);
    } else if (place && !placing && !lockKeyboard && (searchedObject == "" || typeof searchedObject === 'undefined')) {
      if (allDone) return; // don't let player place objects after all done

      setHintMessage('step-3')

      setPlacing(true);
      // console.log('placing')
      // @ts-ignore
      let locationClone = Object.assign({}, ref.current.translation());
      // console.log(locationClone)
      locationClone.x =Math.round((locationClone.x + Number.EPSILON) * 100) / 100;
      locationClone.y = 0;
      locationClone.z =Math.round((locationClone.z + Number.EPSILON) * 100) / 100;

      if (hasObject && !searchedObject) {

        if ((typeof playerPosition !== "undefined") &&
          // this leaves the 'corridor' free
          (playerPosition.z < 0 ) && ((playerPosition.x < 0) || (playerPosition.x > 6))
        ) {
          if (!checkObjectPlacement(objectData, locationClone)) {
            setPlacedObject(locationClone);

            if(hintMessage === 'step-2') {
              setHintMessage('step-3');
            }

            setTimeout(() => {
              // console.log('setting has object to false:', hasObject)
              setHasObject(false)
            }, 100);
          }
        } else {
          setTimeout(() => {pointerControls.current.unlock()},100);
          setSlideModalCharacter('herbert');
          setSlideMessage('outside-fence');
          setShowSlideModal(true);
        }
        setTimeout(() => {
          setPlacing(false);
        }, 500);
      } else {
        // console.log(hasObject)
        setTimeout(() => {
          setHasObject(true);
          setPlacedObject(false);
          setPlacing(false);
        }, 500);
      }
    } else if ((forward || backward || left || right) && !lockKeyboard) {  // todo this might be being called over and might not be needed (without it, the player keeps running
      direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED).applyEuler(camera.rotation)
      // @ts-ignore
      setPlayerPosition(ref.current.translation());
      if (DEBUG_MODE) {
        console.log('Player Position:', playerPosition);
      }
    } else {
      if (!lockKeyboard) {
        // todo this might be being called over and might not be needed (without it, the player keeps running
        direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED).applyEuler(camera.rotation)
      }
    }
    // @ts-ignore
    ref.current.setLinvel({ x: direction.x, y: velocity.y, z: direction.z })
    // jumping
    const world = rapier.world.raw()
    // @ts-ignore
    const ray = world.castRay(new RAPIER.Ray(ref.current.translation(), { x: 0, y: -1, z: 0 }))
    const grounded = ray && ray.collider && Math.abs(ray.toi) <= 1.75

    // todo fix bug where jump triggers i after planting object... (can't seem to replicate)
    if ((jump && grounded) && !lockKeyboard) { // @ts-ignore
      ref.current.setLinvel({ x: 0, y: JUMP_HEIGHT, z: 0 })
    }
  })

  return (
    <>
      <RigidBody ref={ref} colliders={false} mass={1} type="dynamic" position={position} enabledRotations={[false, false, false]}>
        <CapsuleCollider args={[0.75, 0.5]} />
      </RigidBody>
      { hasObject && !searchedObject && (
        <group ref={object} onPointerMissed={(e) => (handleRotation)}>
          { mode === 'rose' && (
            <CarriedRose position={[0.3, -0.3, 0.5]} />
          )}
          { mode === 'rocket' && (
            <CarriedRocket position={[0.3, -0.5, 0.5]} />
          )}
        </group>
      )}
    </>
  )
}
