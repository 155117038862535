import {ChainFence} from "../RocketMode/ChainFence";
import {WoodenFence, WoodenFenceSnow} from "../RegularVersion/Fences";

/**
 * PatchSnow
 * @param mode
 * @param lowGraphics
 * @param snow
 * @param objectCount
 * @param rotation
 * @param position
 * @param patch
 * @constructor
 */
export default function Patch({ mode, lowGraphics, snow, objectCount, rotation, position, patch}: any) {

  let fenceCount = Math.round(Math.sqrt(objectCount))
  // console.log(`fenceCount: ${fenceCount}`)

  return (
    <group rotation={rotation} position={position}>

{/*      /!*todo this is really messy, but aligning is such a pain*!/*/}

      { patch === 1 && (
        <>
          <group rotation={[0,0.02,0]}>

            {/*left front*/}
            <group position={[0,0,1]} rotation={[0,0.02,0]} >
              { mode === 'rose' && snow && (
                <WoodenFenceSnow lowGraphics={lowGraphics} scale={1} count={fenceCount+28} position={[(i:number) => -0.2, 0, (i:number) => 2.5 + i * 2.3]} rotation={[Math.PI / 2, 0, Math.PI / 2]}/>
              )}
              { mode === 'rose' && !snow && (
                <WoodenFence lowGraphics={lowGraphics} scale={1} count={fenceCount+28} position={[(i:number) => -0.2, 0, (i:number) => 2.5 + i * 2.3]} rotation={[Math.PI / 2, 0, Math.PI / 2]}/>
              )}
              { mode === 'rocket' && (
                <ChainFence lowGraphics={lowGraphics} scale={1} count={fenceCount+28} position={[(i:number) => -0.2, -0.5, (i:number) => 2.5 + i * 2.3]} rotation={[0,0,0]}/>
              )}
            </group>

            {/*left back*/}
            <group rotation={[0,-0.206,0]} position={[3,0,-0.6]}>
              { mode === 'rose' && snow && (
                <WoodenFenceSnow lowGraphics={lowGraphics} scale={1} count={fenceCount} position={[(i:number) => 0.5 + i * 2.3, 0, (i:number) => i  * -0.51]} rotation={[Math.PI / 2, 0, 2.922]}/>
              )}
              { mode === 'rose' && !snow && (
                <WoodenFence lowGraphics={lowGraphics} scale={1} count={fenceCount} position={[(i:number) => 0.5 + i * 2.3, 0, (i:number) => i  * -0.51]} rotation={[Math.PI / 2, 0, 2.922]}/>
              )}
              { mode === 'rocket' && (
                <ChainFence lowGraphics={lowGraphics} scale={1} count={fenceCount} position={[(i:number) => 0.5 + i * 2.3, -0.5, (i:number) => i  * -0.51]} rotation={[0,-1.34,0]}/>
              )}
            </group>

            {/*<>*/}

            {/*  <group rotation={[0,0.02,0]}>*/}
            {/*    {[...Array(fenceCount+28)].map((x:any, i:number) => {*/}

            {/*      /!*todo replace with mod*!/*/}
            {/*      if (i == 0 || i == 10 || i == 20 || i == 30 || i == 40 || i == 50 || i == 60 || i == 70 || i == 80 || i == 90) {*/}
            {/*        return*/}
            {/*      } else {*/}
            {/*        return (*/}
            {/*          <FenceOneOld key={`fence-${i}`} position={[-0.2, 0, i + 0.4]}/>*/}
            {/*        )*/}
            {/*      }*/}
            {/*    })}*/}
            {/*  </group>*/}

            {/*  /!*todo replace with mod*!/*/}
            {/*  {[...Array(fenceCount)].map((x:any, i:number) => {*/}
            {/*    if (i == 0 || i == 10 || i == 20 || i == 30 || i == 40 || i == 50 || i == 60 || i == 70 || i == 80 || i == 90) {*/}
            {/*      return*/}
            {/*    } else {*/}
            {/*      return (*/}
            {/*        <FenceOneOld key={`fence-${i}`} position={[0.5 + (i * 1.25), 0, 0 - (i * -0.51)]} rotation={[0, 0.8, 0]}/>*/}
            {/*      )*/}
            {/*    }*/}
            {/*  })}*/}
            {/*</>*/}

          </group>
        </>
      )}

      { patch === 2 && (
        <>
          {/*right back*/}
          <group rotation={[0,-0.015,0]}>
            <group rotation={[0,-0.22,0]} position={[3,0,-0.5]}>
              {/*<FenceOne scale={1} count={fenceCount} position={[(i:number) => 0.5 + i  * 1.25, 0, (i:number) => i  * -0.51]} rotation={[1.57, 0, 2.8]}/>*/}

                { mode === 'rose' && snow && (
                  <WoodenFenceSnow lowGraphics={lowGraphics} scale={1} count={fenceCount} position={[(i:number) => 0.5 + i * 2.3, 0, (i:number) => i  * -0.51]} rotation={[Math.PI / 2, 0, 2.922]}/>
                )}
                { mode === 'rose' && !snow && (
                  <WoodenFence lowGraphics={lowGraphics} scale={1} count={fenceCount} position={[(i:number) => 0.5 + i * 2.3, 0, (i:number) => i  * -0.51]} rotation={[Math.PI / 2, 0, 2.922]}/>
                )}
                { mode === 'rocket' && (
                  <ChainFence lowGraphics={lowGraphics} scale={1} count={fenceCount} position={[(i:number) => 0.5 + i * 2.3, -0.5, (i:number) => i  * -0.51]} rotation={[0,-1.34,0]}/>
                )}

            </group>

            {/*right front*/}
            <group position={[0,0,3]} >
              {/*<FenceOne scale={1} count={fenceCount} position={[(i:number) => -0.2, 0, (i:number) => i + 0.4]} rotation={[Math.PI / 2, 0, Math.PI / 2]}/>*/}

              { mode === 'rose' && snow && (
                <WoodenFenceSnow lowGraphics={lowGraphics} scale={1} count={fenceCount+28} position={[(i:number) => -0.2, 0, (i:number) => 0.8 + i * 2.3]} rotation={[Math.PI / 2, 0, Math.PI / 2]}/>
              )}
              { mode === 'rose' && !snow && (
                <WoodenFence lowGraphics={lowGraphics} scale={1} count={fenceCount+28} position={[(i:number) => -0.2, 0, (i:number) => 0.8 + i * 2.3]} rotation={[Math.PI / 2, 0, Math.PI / 2]}/>
              )}
              { mode === 'rocket' && (
                <ChainFence lowGraphics={lowGraphics} scale={1} count={fenceCount+28} position={[(i:number) => -0.2, -0.5, (i:number) => 0.8 + i * 2.3]} rotation={[0,0,0]}/>
              )}
            </group>

            {/*<>*/}
            {/*<group rotation={[0,-0.015,0]}>*/}
            {/*  {[...Array(fenceCount+8)].map((x:any, i:number) => {*/}
            {/*    if (i == 0 || i == 10 || i == 20 || i == 30 || i == 40 || i == 50 || i == 60 || i == 70 || i == 80 || i == 90) {*/}
            {/*      return*/}
            {/*    } else {*/}
            {/*      return (*/}
            {/*        <FenceOneOld key={`fence-${i}`} position={[0.5 + (i * 1.25), 0, 0 - (i * -0.51)]} rotation={[0, 0.8, 0]}/>*/}
            {/*      )*/}
            {/*    }*/}
            {/*  })}*/}
            {/*</group>*/}

            {/*  {[...Array(fenceCount+6)].map((x:any, i:number) => {*/}

            {/*    /!*todo replace with mod*!/*/}
            {/*    if (i == 0 || i == 10 || i == 20 || i == 30 || i == 40 || i == 50 || i == 60 || i == 70 || i == 80 || i == 90) {*/}
            {/*      return*/}
            {/*    } else {*/}
            {/*      return (*/}
            {/*        <FenceOneOld key={`fence-${i}`} position={[-0.2, 0, i + 0.4]}/>*/}
            {/*      )*/}
            {/*    }*/}
            {/*  })}*/}


            {/*</>*/}

          </group>
        </>
      )}
    </group>
  )
}

