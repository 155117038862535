import {useGLTF} from "@react-three/drei";
import React from "react";
import {GrassBase} from "../Base/Grass";

export function CharredGrass({ spreadDistance, scale, count, rotation }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/charred-grass.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <GrassBase nodes={nodes} materials={materials} spreadDistance={spreadDistance} scale={scale} count={count} rotation={rotation} />
  )
}
