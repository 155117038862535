import React, {useEffect, useRef, useState} from 'react';
import {
  Route, Routes, useSearchParams
} from "react-router-dom";
import './App.scss';
import NavBar from "./components/layout/NavBar";
import InfoModal from "./components/modals/InfoModal";
import SizeModal from "./components/modals/SizeModal";
import HintBox from "./components/modals/HintBox";
import Home from "./components/Home";
import SlideModal from "./components/modals/SlideModal";
import { getObjectsData, getNote, getWeatherData } from "./helpers/apiCalls";
import CreditsModal from "./components/modals/CreditsModal";
import PlantModal from "./components/modals/PlantModal";
import {CssBaseline, Slider} from '@mui/material';
import MobileMovementButtons from "./components/layout/MobileMovementButtons";
import CentreOverlay from "./components/modals/CentreOverlay";
import {ArrowDownwardOutlined, ArrowDownwardRounded} from "@mui/icons-material";
import {SizeSlider} from "./components/modals/SizeSlider";
import {getDayOrNight, getObjectArrays, getSigns} from "./helpers/helperFunctions";
import TimeOverlay from "./components/modals/TimeOverlay";

const PITCH_SIZE=700;

// todo might want to go through and change any meshes which load in from remote, to only load if the texture or object is loaded (see Roses)

/**
 * App
 * @constructor
 */
function App() {
  const pointerControls = useRef(null);
  const [DEBUG_MODE, setDEBUG_MODE] = useState(process.env.NODE_ENV === 'development' ? true : false); // rose or rocket
  const [mode, setMode] = useState(""); // rose or rocket
  // web3 modal (moved)
  const [modalOpen, setModalOpen] = useState(false);
  const [lowGraphics, setLowGraphics] = useState(false);
  const [lockKeyboard, setLockKeyboard] = React.useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showPlantModal, setShowPlantModal] = useState(false);
  const [showSizeModal, setShowSizeModal] = useState(false);
  const [showNFTModal, setShowNFTModal] = useState(false);
  const [infoModalText, setShowInfoModalText] = useState("Click a Picture to view info and buy.");
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [picSet, setPicSet] = React.useState(1);
  const [hasObject, setHasObject] = useState(true);
  const [night, setNight] = useState(true); // make the night default so it doesn't flash white on load
  const [day, setDay] = useState(false);
  const [snow, setSnow] = useState(false);
  const [spring, setSpring] = useState(true);
  const [noteMessage, setNoteMessage] = useState("");
  const [sendToEmail, setSendToEmail] = React.useState("");
  const [messageFrom, setMessageFrom] = React.useState("");
  const [yourOrganization, setYourOrganization] = React.useState("");
  const [receiptEmail, setReceiptEmail] = React.useState("");
  const [objectCount, setObjectCount] = React.useState(PITCH_SIZE);
  const [objectData, setObjectData] = React.useState<any>([]);
  const [weatherData, setWeatherData] = React.useState<any>(undefined);
  const [objectArrays, setObjectArrays ] = React.useState<any>([]);
  const [placedObject, setPlacedObject ] = React.useState<any>();
  const [objectColor, setObjectColor ] = React.useState<any>('red');
  const [playerPosition, setPlayerPosition ] = React.useState<any>();
  const [slideMessage, setSlideMessage ] = React.useState<any>('default');
  const [showSlideModal, setShowSlideModal ] = React.useState<any>(true);
  const [hintMessage, setHintMessage ] = React.useState<any>('step-1');
  const [showHintBox, setShowHintBox ] = React.useState<any>(false);
  const [slideModalCharacter, setSlideModalCharacter ] = React.useState<any>('herbert');
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchedObject, setSearchedObject] = React.useState("");
  const [highlightedCommunity, setHighlightedCommunity] = React.useState(undefined);
  const [slideData, setSlideData] =  React.useState<any>();
  const [allDone, setAllDone] = React.useState(false);
  const [allFailed, setAllFailed] = React.useState(false);
  const [transactionHash, setTransactionHash] = React.useState("");
  const [objectUUID, setObjectUUID] = React.useState("");
  const [forestColliders, setForestColliders] = React.useState(true);
  const [launch, setLaunch] = React.useState(false);
  const [searchedRocketScale, setSearchedRocketScale] = React.useState(null);
  const [centreOverlay, setCentreOverlay] = React.useState(null);
  const [donationAmount, setDonationAmount] = useState<number>(10);
  const [roseSizings, setRoseSizings] = useState();
  const [rocketSizings, setRocketSizings] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [placing, setPlacing] = React.useState(false) // not sure why we have placedObject AND placing
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [explosion, setExplosion] = React.useState(false)
  const isMobile = width <= 768;

  // searched object
  let searchedRoseParam = searchParams.get("rose");
  let searchedRocketParam = searchParams.get("rocket");
  useEffect(() => {
    // these are also currently used for redirecting the user with the url
    if (searchedRoseParam) {
      getNote(searchedRoseParam, 'rose').then((rose: any) => {
        if (typeof rose != undefined) {
          setSearchedObject(rose);
        }
        setMode('rose');
      });
    }
    if (searchedRocketParam) {
      getNote(searchedRocketParam, 'rocket').then((rocket: any) => {
        if (typeof rocket != undefined) {
          setSearchedObject(rocket);
        }
        setMode('rocket');
      });
    }
  }, [searchedRoseParam, objectData]);

  // Communities
  const signArray = getSigns();
  let searchedCommunityParam = searchParams.get("c");
  useEffect(() => {
    if (searchedCommunityParam) {
      const community = signArray.find(((item:any) => item.name === searchedCommunityParam));

      if (community) {
        // @ts-ignore
        setHighlightedCommunity(community);
      }
    }
  })

  // todo could refactor all this too
  // todo could add allDone here and then the rose should appear at the end
  useEffect(() => {
    if (mode !== '') {
      getObjectsData(mode).then(data => {
        // console.log('the data', data);
        // @ts-ignore
        setObjectData(data.objectDataReduced);
        // @ts-ignore
        setRoseSizings(data.roseSizings);
        // @ts-ignore
        setRocketSizings(data.rocketSizings);

        const _objectArrays = getObjectArrays(data);
        setObjectArrays(_objectArrays)

      }).catch((error: any) => {
        console.log(error);
      })
    }
  },[allDone, mode]); // refresh when all done


  useEffect(() => {
    getWeatherData().then(data => {
      // overrides for testing
      // data.time.time = 9;
      // data.weather.rainbow = true;

      setWeatherData(data);

      if(data && data.time && data.time.time) {
        let time = data.time.time;

        const {day, night} = getDayOrNight(time);
        setDay(day);
        // setNight(true);
        setNight(night);
      }
    }).catch((error: any) => {
      console.log(error);
    })
  } , []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const toggleLeftSideDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === 'keydown' && (
        (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift'))
      {
        return;
      }
      setDrawerOpen(!drawerOpen);
  };

  const showModal = () => {
    setModalOpen(true);
    setTimeout(() => { // @ts-ignore
      pointerControls.current.unlock()},100);
  }

  return (
    <div className={`App ${mode == "" ? 'App-scroll' : ''}`}>
      <CssBaseline />

      <NavBar
        DEBUG_MODE={DEBUG_MODE} setShowBookingModal={setShowBookingModal}
        setLowGraphics={setLowGraphics} lowGraphics={lowGraphics} mode={mode} setMode={setMode}
        setSnow={setSnow} snow={snow} setNight={setNight} night={night} setObjectColor={setObjectColor}
        objectColor={objectColor} showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal}
        setObjectCount={setObjectCount} showNFTModal={showNFTModal} setModalOpen={setModalOpen}
        showModal={showModal} modalOpen={modalOpen} pointerControls={pointerControls} picSet={picSet}
        setPicSet={setPicSet} toggleLeftSideDrawer={toggleLeftSideDrawer} showBookingModal={showBookingModal}
      />

      { mode != "" && (
        <>
          { roseSizings && rocketSizings && (
            <SizeSlider
              pointerControls={pointerControls} mode={mode} rocketSizings={rocketSizings} setShowInfoModal={setShowInfoModal}
              roseSizings={roseSizings} placedObject={placedObject} donationAmount={donationAmount}
              setDonationAmount={setDonationAmount} setObjectColor={setObjectColor} objectColor={objectColor}
            />
          )}
          <InfoModal
            mode={mode} yourOrganization={yourOrganization} setYourOrganization={setYourOrganization}
            lowGraphics={lowGraphics} setLowGraphics={setLowGraphics} highlightedCommunity={highlightedCommunity}
            allFailed={allFailed} transactionHash={transactionHash} objectUUID={objectUUID} allDone={allDone}
            setHasObject={setHasObject} messageFrom={messageFrom} setMessageFrom={setMessageFrom}
            setSendToEmail={setSendToEmail} setShowPlantModal={setShowPlantModal} setModalOpen={setModalOpen}
            searchedObject={searchedObject} lockKeyboard={lockKeyboard} setLockKeyboard={setLockKeyboard}
            setNoteMessage={setNoteMessage} noteMessage={noteMessage} hasObject={hasObject}
            placedObject={placedObject} setObjectColor={setObjectColor} objectColor={objectColor}
            showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} pointerControls={pointerControls}
            infoModalText={infoModalText} setPlacedObject={setPlacedObject} sendToEmail={sendToEmail}
          />
          <CreditsModal mode={mode}
            lowGraphics={lowGraphics} setLowGraphics={setLowGraphics} setShowCreditsModal={setShowCreditsModal}
            searchedObject={searchedObject} lockKeyboard={lockKeyboard} setLockKeyboard={setLockKeyboard}
            pointerControls={pointerControls} showCreditsModal={showCreditsModal}
          />
          <PlantModal
            donationAmount={donationAmount} setDonationAmount={setDonationAmount} mode={mode}
            yourOrganization={yourOrganization} setYourOrganization={setYourOrganization}
            setAllFailed={setAllFailed} setTransactionHash={setTransactionHash} setShowInfoModal={setShowInfoModal}
            setSlideModalCharacter={setSlideModalCharacter} setSlideData={setSlideData} setSlideMessage={setSlideMessage}
            setShowSlideModal={setShowSlideModal} allDone={allDone} setAllDone={setAllDone}
            setObjectUUID={setObjectUUID} receiptEmail={receiptEmail} setReceiptEmail={setReceiptEmail}
            messageFrom={messageFrom} sendToEmail={sendToEmail} noteMessage={noteMessage} placedObject={placedObject}
            objectColor={objectColor} showInfoModal={showInfoModal} lockKeyboard={lockKeyboard} placing={placing}
            setPlacing={setPlacing} setLockKeyboard={setLockKeyboard} pointerControls={pointerControls}
            showPlantModal={showPlantModal} setShowPlantModal={setShowPlantModal}
            highlightedCommunity={highlightedCommunity} setPlacedObject={setPlacedObject}
          />
          <SizeModal
            mode={mode} setLockKeyboard={setLockKeyboard} objectCount={objectCount} setObjectCount={setObjectCount}
            setForestColliders={setForestColliders} pointerControls={pointerControls} showSizeModal={showSizeModal}
            setShowSizeModal={setShowSizeModal}
          />
          <SlideModal
            setSearchedRocketScale={setSearchedRocketScale} mode={mode} setLaunch={setLaunch} launch={launch}
            setCentreOverlay={setCentreOverlay} setLowGraphics={setLowGraphics} lowGraphics={lowGraphics}
            signArray={signArray} objectUUID={objectUUID} noteMessage={noteMessage} searchedObject={searchedObject}
            setLockKeyboard={setLockKeyboard} hasObject={hasObject} setShowHintBox={setShowHintBox} slideData={slideData}
            slideMessage={slideMessage} setShowSlideModal={setShowSlideModal} showSlideModal={showSlideModal}
            slideModalCharacter={slideModalCharacter} pointerControls={pointerControls} pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
          <HintBox setShowHintBox={setShowHintBox} mode={mode} searchedObject={searchedObject} showHintBox={showHintBox} hintMessage={hintMessage} />
        </>
      )}

      {/*<TimeOverlay/>*/}
      <CentreOverlay centreOverlay={centreOverlay} setCentreOverlay={setCentreOverlay} />

      { mode != "" && (
        <div className="centre-point"><span>.</span></div>
      )}

      <Routes>
        <Route
          key={'home'}
          path="/"
          element={
            <Home
              searchedRocketScale={searchedRocketScale} donationAmount={donationAmount} spring={spring}
              setDonationAmount={setDonationAmount} mode={mode} setMode={setMode} DEBUG_MODE={DEBUG_MODE} launch={launch}
              roseSizings={roseSizings} rocketSizings={rocketSizings} signArray={signArray}
              setTransactionHash={setTransactionHash} highlightedCommunity={highlightedCommunity} placing={placing}
              setLowGraphics={setLowGraphics} lowGraphics={lowGraphics} pageNumber={pageNumber} setPlacing={setPlacing}
              weatherData={weatherData} day={day} setDay={setDay} forestColliders={forestColliders}
              objectData={objectData} allDone={allDone} setShowCreditsModal={setShowCreditsModal}
              searchedObject={searchedObject} snow={snow} lockKeyboard={lockKeyboard} setLockKeyboard={setLockKeyboard}
              hintMessage={hintMessage} setShowHintBox={setShowHintBox} setHintMessage={setHintMessage}
              setSlideModalCharacter={setSlideModalCharacter} setSlideData={setSlideData} searchParams={searchParams}
              setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage} showSizeModal={showSizeModal}
              setShowSizeModal={setShowSizeModal} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}
              night={night} setNight={setNight} setObjectColor={setObjectColor} objectColor={objectColor}
              placedObject={placedObject} setPlacedObject={setPlacedObject} objectArrays={objectArrays}
              objectCount={objectCount} setObjectCount={setObjectCount} setHasObject={setHasObject} hasObject={hasObject}
              pointerControls={pointerControls} picSet={picSet} isMobile={isMobile} cameraPosition={[0.1,0.8,0.1]}
              setShowInfoModal={setShowInfoModal} setShowNFTModal={setShowNFTModal} infoModalText={infoModalText}
              setInfoModalText={setShowInfoModalText} receiptEmail={receiptEmail} setReceiptEmail={setReceiptEmail}
              yourOrganization={yourOrganization} setYourOrganization={setYourOrganization} explosion={explosion}
              setAllDone={setAllDone} setAllFailed={setAllFailed} setPageNumber={setPageNumber} setExplosion={setExplosion}
            />
          }
        />

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>

      { mode != "" && (
        <>
          <MobileMovementButtons pointerControls={pointerControls} />
        </>
      )}

      {/*<div className={`buttons-container unegma-logo`}>*/}
      {/*  /!*{ mode === '' ? <span style={{color: 'white', textAlign: 'right'}}><ArrowDownwardRounded/></span> : ''}*!/*/}
      {/*  <a target="_blank" style={{color: 'white', textDecoration:'none'}} href="https://unegma.com">*/}
      {/*    unegma<span style={{color:'cyan'}}>.</span>com*/}
      {/*  </a>*/}
      {/*</div>*/}
    </div>
  );
}

export default App;
