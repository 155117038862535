import {ethers} from "ethers";
import * as rainSDK from "rain-sdk";
import {postData} from "./apiCalls";
const DONATION_ADDRESS = `${process.env.REACT_APP_DONATION_ADDRESS}`;

const WARNING_MESSAGE="Are you connected with your Web3 Wallet?\n(Click the Connect button at the top right)!";

/**
 * Called within the modal for making a buy
 * THIS MUST NOT BE SHOWN BEFORE getSaleData() HAS FINISHED OR THE DATA WILL BE FROM .ENV
 */
export async function initiateClaim(
  signer: any, setButtonLock: any, setLoading: any, account: string, setConsoleData: any, setConsoleColor: any, tokenAddress: string, setClaimComplete: any
) {
  try {
    if (account === "" || typeof account === 'undefined') {
      alert(WARNING_MESSAGE);
      return;
    }

    setButtonLock(true);
    setLoading(true);

    // @ts-ignore
    const emissionsErc20 = new rainSDK.EmissionsERC20(tokenAddress, signer);

    // TODO FIGURE OUT WHAT IS HAPPENING WITH ADDRESSZERO
    const claimTransaction = await emissionsErc20.claim(account, ethers.constants.AddressZero);
    const claimReceipt = await claimTransaction.wait();
    console.log('Success', claimReceipt);

    setConsoleData(`Complete!`);
    setConsoleColor(`green`); // todo add to struct
    setClaimComplete(true);
    //   setButtonLock(false); // don't set to true to disincentive users from continuing to click it
    setLoading(false);
  } catch(err) {
    setLoading(false);
    setButtonLock(false);
    setConsoleData(`Claim Failed (Check console for more data).`);
    setConsoleColor(`red`); // todo add to struct
    console.log(`Info: Something went wrong:`, err);
  }
}

/**
 * Reserve Token Balance for User
 */
export async function getReserveBalance(signer: any, account: string, reserveTokenAddress: string, setReserveTokenBalance: any) {
  try {
    console.log(`Reserve token address`, reserveTokenAddress)
    const token = new rainSDK.EmissionsERC20(reserveTokenAddress, signer);

    let balance = await token.balanceOf(account);
    let humanReadableBalance = `${parseInt(balance.toString())/10**18}`;

    console.log(`User Balance`, humanReadableBalance)
    setReserveTokenBalance(humanReadableBalance); // todo does it need /10**18?

  } catch(err) {
    console.log(`Info: Something went wrong:`, err);
  }
}

/**
 * getSignature
 *
 * @param library
 * @param account
 * @param donationAmount
 */
export async function getSignature(library: any, account: any, donationAmount: number): Promise<any|null> {
  if (typeof library === 'undefined') {
    alert('There was an error, did you connect to your Web3 Wallet (top right)?'); // this might happen on the homepage, but should be a fallback, open connect dialog if not connected first!
  }
  return library
    .getSigner(account)
    .sendTransaction({
      to: DONATION_ADDRESS,
      value: ethers.utils.parseEther(donationAmount.toString())
    })
    // .signMessage(`This will be the value ${ethDonation}ETH`)
    .then((signature: any) => {
      console.log(signature);
      return signature;
    }).catch((error: any) => {
      console.log(error);
      return null;
  })
}

/**
 * postTransaction
 *
 * @param signature
 * @param chainId
 * @param donationAmount
 * @param mode
 * @param receiptEmail
 * @param yourOrganization
 * @param getInTouch
 * @param noteMessage
 * @param objectColor
 * @param placedObject - TODO CHANGE TO PLACEDOBJECTPOSITION
 * @param highlightedCommunity
 * @param sendToEmail
 * @param messageFrom
 */
export async function postTransaction(
  signature: any, chainId: any, donationAmount: any, mode: any, receiptEmail?: any, yourOrganization?: any, getInTouch?: boolean,
  noteMessage?: any, objectColor?: any, placedObject?: any, highlightedCommunity?: any,
  sendToEmail?: any, messageFrom?: any,
): Promise<any|null> {
  return postData({
    note: noteMessage ? noteMessage : '',
    color: objectColor ? objectColor : '',
    position: placedObject ? placedObject : '',
    sendToEmail: sendToEmail ? sendToEmail : '',
    receiptEmail: receiptEmail ? receiptEmail : '',
    messageFrom: messageFrom ? messageFrom : '',
    txHash: signature.hash,
    getInTouch: getInTouch,
    // txDa: signature,
    chainId: chainId,
    amount: donationAmount, // still seems to be added as a string on the backend
    address: signature.from,
    transaction: signature,
    // addToRoseNFTList: userIsOnNFTList ? signature.from : '',
    community: (highlightedCommunity && highlightedCommunity.name) ? highlightedCommunity.name : '',
    mode: mode,
    organization: yourOrganization ? yourOrganization : '',
    // showDonation: showDonationAmount
  }, mode).then((data: any) => {
    console.log(data)
    return data;
  }).catch((error: any) => {
    console.log(error);
    return null;
  })
}
