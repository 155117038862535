import {
  RosesBlack,
  RosesBlue,
  RosesBrown,
  RosesOrange,
  RosesPink,
  RosesPurple,
  RosesRed,
  RosesWhite,
  RosesYellow
} from "./Roses";
import React from "react";

export function FieldRoses({searchedObject, objectArrays}: any) {
  return (
    <>
      <RosesBlack objectArray={objectArrays.blackObjectArray} searchedObject={searchedObject} />
      <RosesRed objectArray={objectArrays.redObjectArray} searchedObject={searchedObject} />
      <RosesBrown objectArray={objectArrays.brownObjectArray} searchedObject={searchedObject} />
      <RosesYellow objectArray={objectArrays.yellowObjectArray} searchedObject={searchedObject} />
      <RosesOrange objectArray={objectArrays.orangeObjectArray} searchedObject={searchedObject} />
      <RosesPurple objectArray={objectArrays.purpleObjectArray} searchedObject={searchedObject} />
      <RosesPink objectArray={objectArrays.pinkObjectArray} searchedObject={searchedObject} />
      <RosesWhite objectArray={objectArrays.whiteObjectArray} searchedObject={searchedObject} />
      <RosesBlue objectArray={objectArrays.blueObjectArray} searchedObject={searchedObject} />
    </>
  )
}
