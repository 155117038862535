import {Create, Description, Forum, Payment, People, Telegram, WebAsset} from "@mui/icons-material";
import React from "react";

export default function Links() {

  return (
    <>
      <p><a target="_blank" href={`${process.env.REACT_APP_ARTICLE_LINK}`}><Description/>&nbsp;Read about <b>Web4</b> and why it is Important.</a></p>
      <p><a target="_blank" href={`${process.env.REACT_APP_SPREADSHEET_LINK}`}><Create/>&nbsp;Contribute to Keywords.</a></p>
      <hr style={{opacity:"0.3"}}/>
      <p><a target="_blank" href={`${process.env.REACT_APP_MEETUP_LINK}`}><People/>&nbsp;Join <b>Web4</b> Communities in London.</a></p>
      <p><a target="_blank" href={`${process.env.REACT_APP_TELEGRAM_LINK}`}><Telegram/>&nbsp;Join <b>Web4</b> Communities on Telegram.</a></p>
      <p><a target="_blank" href={`${process.env.REACT_APP_DISCORD_LINK}`}><Forum/>&nbsp;Join <b>Web4</b> Communities on Discord.</a></p>
      <hr style={{opacity:"0.3"}}/>
      <p><a href="mailto:sales@unegma.com" target="_blank"><Payment/>&nbsp;Sponsor and get your own Sign!</a></p>
      <p><a href="https://unegma.digital" target="_blank"><WebAsset/>&nbsp;Check out: unegma.digital!</a></p>
    </>
  )
}
