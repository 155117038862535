import {
  Box,
  Button, Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import {useWeb3React} from "@web3-react/core";
import {validEmail} from "../helpers/helperFunctions";
import {getSignature, postTransaction} from "../helpers/web3Functions";
import {_SpaceRegularSnow} from "./3d/RegularVersion/SnowVersion/_SpaceRegularSnow";
import {_SpaceRocket} from "./3d/RocketMode/_SpaceRocket";
import { _SpaceRegular } from "./3d/RegularVersion/_SpaceRegular";
type ConsoleState = 'error' | 'success';

/**
 * Home
 * @param searchedRocketScale
 * @param launch
 * @param DEBUG_MODE
 * @param mode
 * @param highlightedCommunity
 * @param signArray
 * @param day
 * @param setDay
 * @param weatherData
 * @param allDone
 * @param objectData
 * @param setShowCreditsModal
 * @param searchedObject
 * @param snow
 * @param lockKeyboard
 * @param setLockKeyboard
 * @param hintMessage
 * @param setShowHintBox
 * @param setHintMessage
 * @param setSlideData
 * @param setSlideModalCharacter
 * @param setShowSlideModal
 * @param forestColliders
 * @param setSlideMessage
 * @param setShowSizeModal
 * @param playerPosition
 * @param setPlayerPosition
 * @param setNight
 * @param night
 * @param placing
 * @param setPlacing
 * @param objectColor
 * @param placedObject
 * @param setPlacedObject
 * @param objectArrays
 * @param objectCount
 * @param setObjectCount
 * @param pointerControls
 * @param setShowInfoModal
 * @param setHasObject
 * @param hasObject
 * @param lowGraphics
 * @param setLowGraphics
 * @param setMode
 * @param searchParams
 * @param showSizeModal
 * @param setObjectColor
 * @param picSet
 * @param isMobile
 * @param setShowNFTModal
 * @param infoModalText
 * @param setShowInfoModalText
 * @param donationAmount
 * @param setDonationAmount
 * @param receiptEmail
 * @param setReceiptEmail
 * @param yourOrganization
 * @param setYourOrganization
 * @param setTransactionHash
 * @param setAllDone
 * @param setAllFailed
 * @param roseSizings
 * @param rocketSizings
 * @param pageNumber
 * @param setPageNumber
 * @constructor
 */
export default function Home(
  { searchedRocketScale, launch, DEBUG_MODE, mode, highlightedCommunity, signArray, day, setDay, weatherData, allDone,
    objectData, setShowCreditsModal, searchedObject, snow, lockKeyboard, setLockKeyboard, hintMessage, spring,
    setShowHintBox, setHintMessage, setSlideData, setSlideModalCharacter, setShowSlideModal, forestColliders,
    setSlideMessage, setShowSizeModal, playerPosition, setPlayerPosition, setNight, night, placing, setPlacing,
    objectColor, placedObject, setPlacedObject, objectArrays, objectCount, setObjectCount,
    pointerControls, setShowInfoModal, setHasObject, hasObject, lowGraphics, setLowGraphics, setMode,
    searchParams, showSizeModal, setObjectColor, picSet, isMobile, setShowNFTModal, infoModalText, setShowInfoModalText,
    donationAmount, setDonationAmount, receiptEmail, setReceiptEmail, yourOrganization, setYourOrganization,
    setTransactionHash, setAllDone, setAllFailed, roseSizings, rocketSizings, pageNumber, setPageNumber, explosion, setExplosion
  }: any)
{
  const { active, account, library, chainId } = useWeb3React();
  const [localReceiptEmail, setLocalReceiptEmail] = useState("");
  const [validEmailAddress, setValidEmailAddress] = useState(false);
  const [transactionComplete, setTransactionComplete] = useState(false);
  const [transactionProcessing, setTransactionProcessing] = useState(false);
  const [consoleMessage, setConsoleMessage] = useState("");
  const [consoleState, setConsoleState] = useState<ConsoleState>('success');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [allowDonateButtonClick, setAllowDonateButtonClick] = useState(true);
  const [getInTouch, setGetInTouch] = useState(false);

  /**
   * handleGetInTouch
   * @param value
   */
  const handleGetInTouch = (value: any) => {
    setGetInTouch(value);
  };

  /**
   * toggleMode
   * @param mode
   */
  const toggleMode = (mode:string) => {
    setMode(mode)
  }

  /**
   * handleChangeDonationAmount
   * @param value
   */
  const handleChangeDonationAmount = (value: any) => {
    setDonationAmount(value);
  };

  /**
   * handleChangeYourOrganization
   * @param event
   */
  const handleChangeYourOrganization = (event: React.ChangeEvent<HTMLInputElement>) => {
    // todo still need to lock keyboard controls
    setLockKeyboard(true);
    const yourOrganization = event.target.value;

    setYourOrganization(yourOrganization);
  };

  /**
   * handleChangeReceiptEmail
   * @param event
   */
  const handleChangeReceiptEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    // todo still need to lock keyboard controls
    setTimeout(() => {pointerControls.current.unlock()},100);
    setLockKeyboard(true);
    const newEmail = event.target.value;
    setLocalReceiptEmail(newEmail)
    if (validEmail(newEmail)) {
      setValidEmailAddress(true);
      setReceiptEmail(newEmail);
    } else {
      setValidEmailAddress(false);
      setReceiptEmail("");
    }
  }

  /**
   * handleShowConfirmationModal
   */
  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  /**
   * sendTransaction
   */
  const sendTransaction = async () => {
    setTransactionProcessing(true);

    const signature = await getSignature(library, account, donationAmount);
    if (signature !== null) {
      setTransactionProcessing(false);
      setConsoleState('success');
      setConsoleMessage(`Transaction Complete.. Saving..`);
      setTransactionHash(signature.hash);
      setTransactionComplete(true);

      const data = await postTransaction(signature, chainId, donationAmount, mode, receiptEmail, yourOrganization, getInTouch, null, null, null, null, null, null);
      if (data.message === 'success') {
        // todo get receipt?
        setConsoleState('success');
        setConsoleMessage(`Thanks!`);
        setAllDone(true);
        // setRoseUUID(data.uuid);
        // setShowSlideModal(true);
        // setSlideModalCharacter('herbert');
        // setSlideData
        // setSlideMessage('thanks');
        // setShowPlantModal(false);
        // setShowInfoModal(false);
      } else {
        // in this scenario, the transaction might
        setConsoleState('error');
        setConsoleMessage(`Error Saving ${mode.replace(/\w/, (c: string) => c.toUpperCase())}. Please contact us by pressing [i] and submitting feedback (please include transaction id: ${signature ? signature.hash : ''}).`);
        setAllDone(true); // this stops the donate button being clicked again (and a user creating multiple objects in the same place on the backend
        setAllFailed(true);
        setTransactionProcessing(false);
        setConsoleState('error');
      }
    } else {
      setConsoleState('error');
      setConsoleMessage('There was an error, do you have enough funds?');
      setAllDone(true); // this stops the donate button being clicked again (and a user creating multiple objects in the same place on the backend
      setAllFailed(true);
    }
  };

  /**
   * handleClose
   */
  const handleClose = () => {
    // todo check this keeps the confirmation page open when clicking donate
    if (showConfirmationModal) {
      setShowConfirmationModal(false);
    } else {
    }
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90vw',
    minWidth: '85vw',
    maxHeight: '90vh',
    // minHeight: '85vh',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        className="info-modal"
        open={showConfirmationModal}
        // onClose={handleClose}
        // onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
      >
        <Box className="infoModal" component="div" sx={modalStyle}>

          { showConfirmationModal && (
            <>
              <h2>Confirmation</h2>
              <p><b>Receipt To</b>: {receiptEmail}</p>
              <p><b>Donation</b>: {donationAmount} {chainId === 1 ? "Eth" : ""}{chainId === 137 ? "Matic" : ""}{chainId === 80001 ? "Testnet Matic" : ""}</p>
              <p><b>Payment Network</b>: {chainId === 1 ? "Ethereum" : ""}{chainId === 137 ? "Polygon" : ""}{chainId === 80001 ? "Polygon Mumbai Testnet" : ""}</p>

              <hr/>
              <p style={{color:'green'}}>You have chosen for us to <b>{ getInTouch ? '' : 'NOT' }</b> get in touch to help you plant a Rose or Rocket.</p>

              <hr/>

              <Typography className='red'>
                Amount does not include (usually small) <b>Network Transaction Fee</b> (non-refundable).
              </Typography>

              <p className={consoleState === 'error' ? 'red' : 'green' }><b>{consoleMessage}</b></p>

              <br/>

              <Button disabled={!allowDonateButtonClick} style={{marginRight: "5px"}} className="buyButton" variant="contained" color="error" onClick={sendTransaction}>
                Donate
              </Button>

            </>
          )}

          <Button style={{float: "right"}} className="closeInfoModalButton--large" variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>

      { mode == "" && (
        <>
          <div className="home-page__container">
            <div className="home-page__Zero">
            </div>
            <div className="home-page__One">
              <div className="home-page__selectable" onClick={() => toggleMode('rocket')}>
                <img className="home-page__image" src={`${process.env.REACT_APP_ASSETS_URL}/intro-screen/rocketIntro.jpg`} />
                <div className="home-page__selectTextContainer">
                  <span className="home-page__selectText">Rocket</span>
                </div>
              </div>
            </div>
            <div className="home-page__Two">
              <div className="home-page__selectable" onClick={() => toggleMode('rose')} >
                <img className="home-page__image" src={`${process.env.REACT_APP_ASSETS_URL}/intro-screen/roseIntro.jpg`} />
                <div className="home-page__selectTextContainer">
                  <span className="home-page__selectText">Rose</span>
                </div>
              </div>
            </div>
            {/*<div className="home-page__Four">*/}
            {/*  <div className="home-page__logos-container">*/}
            {/*    <span>Partners:</span>*/}
            {/*    <a target="_blank" href="https://twitter.com/ethldn"><img src="https://assets.unegma.net/shared/client-logos/ethldn.jpg"/></a>*/}
            {/*    <a target="_blank" href="https://meetup.com/metafashion"><img src="https://assets.unegma.net/shared/client-logos/metafashion.jpg"/></a>*/}
            {/*    <a target="_blank" href="https://twitter.com/mondaomeetup"><img src="https://assets.unegma.net/shared/client-logos/mondao.jpg"/></a>*/}
            {/*    <a target="_blank" href="https://twitter.com/thursdaolondon"><img src="https://assets.unegma.net/shared/client-logos/thursdao.jpg"/></a>*/}
            {/*    <a target="_blank" href="https://twitter.com/web4london"><img src="https://assets.unegma.net/shared/client-logos/web4london.jpg"/></a>*/}
            {/*    <span>Web4.Garden is a Crowdfunder which<br/>helps support Web3/Web4 community events</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="home-page__Three">
              <h1>Welcome to Web4 Garden</h1>
              <span>Choose your Mode above.</span><br/><br/>
              <span>In Rose mode, you can plant a Rose in a Garden which changes with the seasons and time of day.<br/>
                Optionally send a message to someone of your choice.</span><br/><br/>
              <span>Rocket mode is for Degens. Leave your mark by putting down a Rocket to represent your project.</span><br/>
              <span>Watch it go to the moon.</span>
            </div>
            {/*<div className="home-page__Five">*/}
            {/*  <h1>Donation</h1>*/}
            {/*  <span>Want to just donate, or have us plant a Rocket or Rose for you?</span>*/}
            {/*  <br/>*/}

            {/*  /!*this is duplicate code so maybe refactor into a component..*!/*/}
            {/*  <>*/}
            {/*    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between" }}>*/}
            {/*      {chainId === 1 && (*/}
            {/*        <a target="_blank" href="https://chainlist.org/chain/1"><img style={{width:"175px", height:"auto", marginRight:"15px" }} src={`https://assets.unegma.net/shared/various/ethereum-logo-2.png`} /></a>*/}
            {/*      )}*/}
            {/*      {chainId === 137 && (*/}
            {/*        <a target="_blank" href="https://chainlist.org/chain/137"> <img style={{width:"140px", height:"auto" }} src={`https://assets.unegma.net/shared/various/polygon-logo.png`} /></a>*/}
            {/*      )}*/}
            {/*      {chainId === 80001 && (*/}
            {/*        <a target="_blank" href="https://chainlist.org/chain/80001"> <img style={{width:"140px", height:"auto", filter: "grayscale(1)" }} src={`https://assets.unegma.net/shared/various/polygon-mumbai-logo.jpg`} /></a>*/}
            {/*      )}*/}
            {/*    </div>*/}


            {/*    <Typography>*/}
            {/*      Please connect your <a href="https://youtu.be/6h_liI6atEk" target="_blank">Web3 Wallet</a> and enter <b>Donation Amount</b> in <b>{chainId === 1 ? "Eth (or Switch Network to use Matic)" : ""}{chainId === 137 ? "Matic (or Switch Network to use Eth)" : ""}{chainId === 80001 ? "Testnet Matic" : ""}{chainId == null ? "Eth or Matic" : ""}</b>:<br/>*/}
            {/*    </Typography>*/}

            {/*    <br/>*/}

            {/*    <FormControl variant="outlined">*/}
            {/*      <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>*/}
            {/*      <OutlinedInput*/}
            {/*        type="number"*/}
            {/*        id="outlined-adornment-amount"*/}
            {/*        value={donationAmount}*/}
            {/*        onChange={(e) => handleChangeDonationAmount(e.target.value)}*/}
            {/*        startAdornment={<InputAdornment position="start">&#8801;</InputAdornment>}*/}
            {/*        // labelWidth={60}*/}
            {/*        endAdornment={<InputAdornment position="end">{chainId === 1 ? "Eth" : ""}{chainId === 137 ? "Matic" : ""}{chainId === 80001 ? "Testnet Matic" : ""}</InputAdornment>}*/}
            {/*      />*/}
            {/*      <br/>*/}
            {/*      <TextField*/}
            {/*        className={validEmailAddress ? 'green-input' : 'red-input'}*/}
            {/*        // defaultValue={objectCount}*/}
            {/*        // inputProps={{ maxLength: 4 }}*/}
            {/*        label="Your Email (for Receipt and Contact)"*/}
            {/*        value={localReceiptEmail}*/}
            {/*        onChange={handleChangeReceiptEmail}*/}
            {/*        onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}*/}
            {/*      />*/}
            {/*      <div style={{padding: '10px 10px'}}>*/}
            {/*        <Tooltip title="">*/}
            {/*          <Checkbox checked={getInTouch} onChange={(e) => handleGetInTouch(!getInTouch)} />*/}
            {/*        </Tooltip>*/}
            {/*        <span style={{color:'red'}}>Check this box if you want us to get in touch.</span>*/}
            {/*      </div>*/}
            {/*    </FormControl>*/}

            {/*    <span className={consoleState === 'error' ? 'red' : 'green' }>{consoleMessage}</span>*/}

            {/*    <Button style={{marginRight: "5px"}} className="buyButton" variant="contained" color="error" onClick={handleShowConfirmationModal}>*/}
            {/*      Donate*/}
            {/*    </Button>*/}
            {/*  </>*/}

            {/*  <br/>*/}

            {/*</div>*/}
            <div className="home-page__Six">
              {/*todo add unegma minecraft background*/}
              {/*todo add contact details*/}
              <span>Visit <a href="https://unegma.digital" target="_blank">Unegma.Digital</a> for bespoke 3D Web services.</span>
            </div>
          </div>
        </>
      )}

      { (mode === 'rose' && snow) && (
        <_SpaceRegularSnow pageNumber={pageNumber} setPageNumber={setPageNumber}
                           mode={mode} DEBUG_MODE={DEBUG_MODE} launch={launch} roseSizings={roseSizings} rocketSizings={rocketSizings}
                           signArray={signArray} highlightedCommunity={highlightedCommunity} placing={placing} setPlacing={setPlacing}
                           setLowGraphics={setLowGraphics} lowGraphics={lowGraphics} donationAmount={donationAmount}
                           weatherData={weatherData} day={day} setDay={setDay} forestColliders={forestColliders}
                           objectData={objectData} allDone={allDone} setShowCreditsModal={setShowCreditsModal}
                           searchedObject={searchedObject} snow={snow} lockKeyboard={lockKeyboard} setLockKeyboard={setLockKeyboard}
                           hintMessage={hintMessage} setShowHintBox={setShowHintBox} setHintMessage={setHintMessage}
                           setSlideModalCharacter={setSlideModalCharacter} setSlideData={setSlideData} searchParams={searchParams}
                           setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage} showSizeModal={showSizeModal}
                           setShowSizeModal={setShowSizeModal} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}
                           night={night} setNight={setNight} setObjectColor={setObjectColor} objectColor={objectColor}
                           placedObject={placedObject} setPlacedObject={setPlacedObject} objectArrays={objectArrays} objectCount={objectCount}
                           setObjectCount={setObjectCount} setHasObject={setHasObject} hasObject={hasObject}
                           pointerControls={pointerControls} picSet={picSet} isMobile={isMobile} cameraPosition={[0.1,0.8,0.1]}
                           setShowInfoModal={setShowInfoModal} setShowNFTModal={setShowNFTModal} infoModalText={infoModalText}
                           setInfoModalText={setShowInfoModalText}
        />
      )}


      { (mode === 'rose' && !snow) && (
        <_SpaceRegular pageNumber={pageNumber} setPageNumber={setPageNumber} spring={spring}
                           mode={mode} DEBUG_MODE={DEBUG_MODE} launch={launch} roseSizings={roseSizings} rocketSizings={rocketSizings}
                           signArray={signArray} highlightedCommunity={highlightedCommunity} placing={placing} setPlacing={setPlacing}
                           setLowGraphics={setLowGraphics} lowGraphics={lowGraphics} donationAmount={donationAmount}
                           weatherData={weatherData} day={day} setDay={setDay} forestColliders={forestColliders}
                           objectData={objectData} allDone={allDone} setShowCreditsModal={setShowCreditsModal}
                           searchedObject={searchedObject} snow={snow} lockKeyboard={lockKeyboard} setLockKeyboard={setLockKeyboard}
                           hintMessage={hintMessage} setShowHintBox={setShowHintBox} setHintMessage={setHintMessage}
                           setSlideModalCharacter={setSlideModalCharacter} setSlideData={setSlideData} searchParams={searchParams}
                           setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage} showSizeModal={showSizeModal}
                           setShowSizeModal={setShowSizeModal} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}
                           night={night} setNight={setNight} setObjectColor={setObjectColor} objectColor={objectColor}
                           placedObject={placedObject} setPlacedObject={setPlacedObject} objectArrays={objectArrays} objectCount={objectCount}
                           setObjectCount={setObjectCount} setHasObject={setHasObject} hasObject={hasObject}
                           pointerControls={pointerControls} picSet={picSet} isMobile={isMobile} cameraPosition={[0.1,0.8,0.1]}
                           setShowInfoModal={setShowInfoModal} setShowNFTModal={setShowNFTModal} infoModalText={infoModalText}
                           setInfoModalText={setShowInfoModalText}
        />
      )}

      { (mode === 'rocket') && (
        <_SpaceRocket pageNumber={pageNumber} setPageNumber={setPageNumber} DEBUG_MODE={DEBUG_MODE}
                      mode={mode} launch={launch} searchedRocketScale={searchedRocketScale} roseSizings={roseSizings} rocketSizings={rocketSizings}
                      signArray={signArray} highlightedCommunity={highlightedCommunity} placing={placing} setPlacing={setPlacing}
                      setLowGraphics={setLowGraphics} lowGraphics={lowGraphics} donationAmount={donationAmount}
                      weatherData={weatherData} day={day} setDay={setDay} forestColliders={forestColliders}
                      objectData={objectData} allDone={allDone} setShowCreditsModal={setShowCreditsModal}
                      searchedObject={searchedObject} snow={snow} lockKeyboard={lockKeyboard} setLockKeyboard={setLockKeyboard}
                      hintMessage={hintMessage} setShowHintBox={setShowHintBox} setHintMessage={setHintMessage}
                      setSlideModalCharacter={setSlideModalCharacter} setSlideData={setSlideData} searchParams={searchParams}
                      setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage} showSizeModal={showSizeModal}
                      setShowSizeModal={setShowSizeModal} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}
                      night={night} setNight={setNight} setObjectColor={setObjectColor} objectColor={objectColor}
                      placedObject={placedObject} setPlacedObject={setPlacedObject} objectArrays={objectArrays} objectCount={objectCount}
                      setObjectCount={setObjectCount} setHasObject={setHasObject} hasObject={hasObject}
                      pointerControls={pointerControls} picSet={picSet} isMobile={isMobile} cameraPosition={[0.1,0.8,0.1]}
                      setShowInfoModal={setShowInfoModal} setShowNFTModal={setShowNFTModal} infoModalText={infoModalText}
                      setInfoModalText={setShowInfoModalText} setExplosion={setExplosion} explosion={explosion}
        />
      )}
    </>
  )
}
