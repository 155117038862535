import {Button, Modal, Box} from "@mui/material";
import React, {useEffect} from "react";
import {LocalFlorist} from "@mui/icons-material";
import Links from "../layout/Links";

let imgs = {
  lion: `${process.env.REACT_APP_ASSETS_URL}/lionImage.jpg`, // todo figure out why lionImage.jpg throws a cors error when used with: `const texture = useLoader(THREE.TextureLoader, imgs.lionImage)`
  lionDark: `${process.env.REACT_APP_ASSETS_URL}/lionDarkImage.jpg`, // todo figure out why lionImage.jpg throws a cors error when used with: `const texture = useLoader(THREE.TextureLoader, imgs.lionImage)`
  note: `${process.env.REACT_APP_ASSETS_URL}/noteImage.jpg`,
  noteDark: `${process.env.REACT_APP_ASSETS_URL}/noteDarkImage.jpg`,
  martin: `${process.env.REACT_APP_ASSETS_URL}/parakeetImage.jpg`,
  sign: `${process.env.REACT_APP_ASSETS_URL}/signImage.jpg`,
  signDark: `${process.env.REACT_APP_ASSETS_URL}/signDarkImage.jpg`,
  snowperson: `${process.env.REACT_APP_ASSETS_URL}/snow-version/snowpersonImage.jpg`,
  terminal: `${process.env.REACT_APP_ASSETS_URL}/terminalImage.jpg`,
  well: `${process.env.REACT_APP_ASSETS_URL}/mysteriousWell.jpg`,
};

/**
 * SlideModal
 *
 * @param pageNumber
 * @param setPageNumber
 * @param setSearchedRocketScale
 * @param setCentreOverlay
 * @param launch
 * @param setLaunch
 * @param mode
 * @param signArray
 * @param setLowGraphics
 * @param lowGraphics
 * @param objectUUID
 * @param noteMessage
 * @param setLockKeyboard
 * @param setShowHintBox
 * @param slideData
 * @param showSlideModal
 * @param setShowSlideModal
 * @param slideMessage
 * @param setSlideMessage
 * @param pointerControls
 * @param slideModalCharacter
 * @constructor
 */
export default function SlideModal (
  { pageNumber, setPageNumber, setSearchedRocketScale, setCentreOverlay, launch, setLaunch, mode, signArray,
    setLowGraphics, lowGraphics, objectUUID, noteMessage, setLockKeyboard, setShowHintBox, slideData, showSlideModal,
    setShowSlideModal, slideMessage, setSlideMessage, pointerControls, slideModalCharacter }: any
) {

  /**
   *
   */
  useEffect(() => {
    if (showSlideModal === true) {
      setLockKeyboard(true);
    } else {
      setLockKeyboard(false);
    }
  }, [showSlideModal]);

  /**
   * handleLaunch
   */
  const handleLaunch = () => {
    setSearchedRocketScale(3);
    setTimeout(() => {
      setCentreOverlay('3');
      setTimeout(() => {
        setCentreOverlay('2');
        setTimeout(() => {
          setCentreOverlay('1');
          setTimeout(() => {
            setCentreOverlay('Liftoff!');
            setTimeout(() => {
              setCentreOverlay(null);
              setLaunch(true);
            }, 1000);
          }, 1000);
        }, 1000);
      }, 1000);
    }, 1000);
    setShowSlideModal(false);
    if (pointerControls.current !== null) {
      setShowSlideModal(false);
      pointerControls.current.connect()
      setTimeout(() => {
        pointerControls.current.lock()
      }, 110) // this needs to be higher than the timeout on the modal
    }
  }

  /**
   * handleClose
   */
  const handleClose = () => {
    if (pageNumber === 1 && slideMessage === 'default') {
      setPageNumber(2);
      return; // is this needed? answer: yes, otherwise other modals don't show if scene loads
    }
    if (pageNumber === 1 && slideMessage === 'thanks') {
      setPageNumber(2);
      return; // is this needed? answer: yes, otherwise other modals don't show if scene loads
    }
    if (pageNumber === 2 && slideMessage === 'default') {
      setPageNumber(3);
      return; // is this needed? answer: yes, otherwise other modals don't show if scene loads
    }
    if (pageNumber === 2 && slideMessage === 'thanks') {
      setPageNumber(1);
    }
    if (pageNumber === 3 && slideMessage === 'default') {
      setPageNumber(4);
      return;
    }
    if (pageNumber === 4 && slideMessage === 'default') {
      setPageNumber(5);
      return;
    }
    if (pageNumber === 5 && slideMessage === 'default') {
      setPageNumber(1);
      setShowHintBox(true);
    }

    // AS THIS IS THE FIRST THING THE USER SEES, NEED TO MAKE SURE POINTERCONTROLS EXISTS OTHERWISE ERRORS
    if (pointerControls.current !== null) {
      setShowSlideModal(false);
      pointerControls.current.connect()
      setTimeout(() => {
        pointerControls.current.lock()
      }, 110) // this needs to be higher than the timeout on the modal
    }
  };

  /**
   * handleClick
   */
  const handleClick = () => {
    // AS THIS IS THE FIRST THING THE USER SEES, NEED TO MAKE SURE POINTERCONTROLS EXISTS OTHERWISE ERRORS
    if (pointerControls.current !== null) {
      setTimeout(() => {
        pointerControls.current.unlock()
      }, 100)
    }
  };

  /**
   * handleToggleGraphics
   */
  const handleToggleGraphics = () => {
   setLowGraphics(!lowGraphics);

    if (pointerControls.current !== null) {
      setTimeout(() => {
        pointerControls.current.unlock()
      }, 100)
    }
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    // maxWidth: '90vw',
    // minWidth: '85vw',
    // maxHeight: '90vh',
    // minHeight: '85vh',
    height: 'auto',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      className="slide-modal"
      open={showSlideModal}
      onClose={handleClose}
      onClick={handleClick}
    >
      <Box className="slideModal" component="div" sx={modalStyle}>

        { slideModalCharacter === 'herbert' && (
          <div className="characterTitle">
            { mode == 'rose' && (
              <img src={imgs.lion} style={{width: "90px", height: "90px"}} />
            )}
            { mode == 'rocket' && (
              <img src={imgs.lionDark} style={{width: "90px", height: "90px"}} />
            )}
            <div>
              <h1>Lihui:&nbsp;</h1>
            </div>
          </div>
        )}

        { slideModalCharacter === 'snowperson' && (
          <div className="characterTitle">
            <img src={imgs.snowperson} style={{width: "90px", height: "90px"}} />
            <div>
              <h1>Sandy:&nbsp;</h1>
            </div>
          </div>
        )}

        { slideModalCharacter === 'note' && (
          <div className="characterTitle">

            { mode == 'rose' && (
              <img src={imgs.note} style={{width: "90px", height: "90px"}} />
            )}
            { mode == 'rocket' && (
              <img src={imgs.noteDark} style={{width: "90px", height: "90px"}} />
            )}

            <div>
              <h1>Note:&nbsp;</h1>
            </div>
          </div>
        )}

        { slideModalCharacter === 'martin' && (
          <div className="characterTitle">
            <img src={imgs.martin} style={{width: "90px", height: "90px"}} />
            <div>
              <h1>Martin:&nbsp;</h1>
            </div>
          </div>
        )}

        { slideModalCharacter === 'sign' && (
          <div className="characterTitle">
            { mode == 'rose' && (
              <img src={imgs.sign} style={{width: "90px", height: "90px"}} />
            )}
            { mode == 'rocket' && (
              <img src={imgs.signDark} style={{width: "90px", height: "90px"}} />
            )}
            <div>
              <h1>Sign:&nbsp;</h1>
            </div>
          </div>
        )}

        { slideModalCharacter === 'terminal' && (
          <div className="characterTitle">
            <img src={imgs.terminal} style={{width: "90px", height: "90px"}} />
            <div>
              <h1>Terminal:&nbsp;</h1>
            </div>
          </div>
        )}

        { slideModalCharacter === 'well' && (
          <div className="characterTitle">
            <img src={imgs.well} style={{width: "90px", height: "90px"}} />
            <div>
              <h1>Well:&nbsp;</h1>
            </div>
          </div>
        )}

        { slideMessage === 'default' && (
          <>
            {pageNumber === 1 && (
              <div className="infoModal__desktop-content">
                <br/>
                <span>"Hi, I'm <b>Lihui</b> the {(mode === 'rose') ? 'Mountain Lion' : (mode === 'rocket') ? 'City Lion' : ''}!"</span><br/>
                <br/><span className="red"><i>"This Site is Currently Optimised for:"<ul><li><b>"Chrome Browser."</b></li><li><b>"On Desktop Machines."</b></li></ul></i></span>
              </div>
            )}

            {pageNumber === 2 && (
              <div className="infoModal__desktop-content">
                <br/>
                { lowGraphics && (
                  <Button onClick={handleToggleGraphics} variant="contained" color="success" className="lowGraphicsButton"><LocalFlorist />&nbsp;Click for High Graphics</Button>
                )}

                { !lowGraphics && (
                  <Button onClick={handleToggleGraphics} variant="outlined" color="success" className="lowGraphicsButton"><LocalFlorist />&nbsp;Click for Low Graphics</Button>
                )}

                <br/>
                <br/>

                <span>"If things <b>Seem Slow</b>, try toggling the <b>Graphics</b>, you can also do this on the <b>Info Menu</b>, accessible with the <b>[i]</b> key!"</span>
                <br/>
                <br/>
              </div>
            )}
            {pageNumber === 3 && (
              <div className="infoModal__desktop-content">
                <br/>
                <span><b>Basic Controls:</b></span>
                <ol>
                  <li>"Use your <b>[Mouse/Touchpad]</b> to <br/><b>Look Around</b>."</li>
                  <li>"<b>Walk</b> with the <b>[Arrow]</b> Keys."</li>
                  <li>"<b>Talk</b> using the <b>[e]</b> Key!"</li>
                </ol>
              </div>
            )}

            { pageNumber === 4 && (
              <div className="infoModal__desktop-content">
                <br/>
                <span>"Remember, you can always:"</span><br/><br/>
                <span className="red">"<b>PRESS [ESC]</b> to <b>Disengage Controls</b>."</span><br/>
                <span>"<b>[Click] Screen</b> to re-engage."</span>
                <br/><br/>
              </div>
            )}

            { pageNumber === 5 && (
              <div className="infoModal__desktop-content">
                <br/>
                <span>"Finally, we use <b>Cookies</b> to check site visits. Continuing means that's ok!"</span>
                <br/>
                <br/>
              </div>
            )}

            {/*todo remove duplicate content*/}
            <div className="infoModal__mobile-content">
              <br/>
              <span>"Hi, I'm <b>Lihui</b> the {(mode === 'rose') ? 'Mountain Lion' : (mode === 'rocket') ? 'City Lion' : ''}!"</span>
              <p className='infoModal__mobile-warning'><b>"MetaWorld CURRENTLY DESKTOP ONLY!"</b></p>
              <p>"But you can still <b>Follow these Web4 Links</b>:"</p>
              <Links />
            </div>
          </>
        )}

        { slideMessage === 'outside-fence' && (
          <>
            <div className="infoModal__desktop-content">
              <br/>
              <span>"Can't place <b>{`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b> here!"</span><br/>
              <span>"Place within <b>Fences</b>!"</span>
              <br/>
              <br/>
            </div>
          </>
        )}

        { slideMessage === 'object-note' && (
          <>
            <div className="infoModal__desktop-content">
              {/*<span>"You have a note!"</span><br/><br/>*/}
              <br/><span>"{slideData}"</span><br/><br/>
              <hr/>
              <span>Plant a {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}, or Send a Message at <a href={`${window.location.origin}`} target="_blank">Web4.Garden</a>!</span>
              <br/>
              <br/>
            </div>
          </>
        )}

        { slideMessage === 'terminal' && (
          <>
            <div className="infoModal__desktop-content">
              <br/><span>Launch Your Rocket?</span><br/><br/>
              <Button className="closeInfoModalButton--large" variant="contained" color="error" onClick={handleLaunch}>
                Launch
              </Button>
            </div>
          </>
        )}

        { slideMessage === 'well' && (
          <>
            <div className="infoModal__desktop-content">
              <br/><span>Launch Butterfly?</span><br/><br/>
              <Button className="closeInfoModalButton--large" variant="contained" color="error" onClick={handleLaunch}>
                Launch
              </Button>
            </div>
          </>
        )}

        { slideMessage === 'thanks' && (
          <>
            {pageNumber === 1 && (
              <div className="infoModal__desktop-content">
                <h2>Congratulations!</h2>
                <h3 className="green">"<b>Your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`} has been planted!</b><br/>With this link (make sure you save it), you can see or send your {`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}{noteMessage !== "" ? " and Message" : "" }:"<br/></h3>
                <a href={`${window.location.origin}?${mode}=${objectUUID}`} target="_blank">{window.location.origin}?{mode}={objectUUID}</a><br/><br/>
                <span>"Go take a look around, or come back and talk to me to see the link again!"</span>
              </div>
            )}

            {pageNumber === 2 && (
              <div className="infoModal__desktop-content">
                <h2>Where Next:</h2>
                <Links/>
              </div>
            )}
          </>
        )}

        { slideMessage === 'parakeet' && (
          <>
            <div className="infoModal__desktop-content">
              <br/>
              <span>"BGAAAAAAARK!"</span><br/><br/>
            </div>
          </>
        )}

        { slideMessage === 'snowperson' && (
          <>
            <div className="infoModal__desktop-content">
              <br/>
              <span>"Seasonal Greetings<br/>Happy Christmas and<br/>Happy Holidays!"</span><br/><br/>
            </div>
          </>
        )}

        { slideMessage === 'unegma' && (
          <>
            <div className="infoModal__desktop-content">
              <img style={{width: "100%"}} src="https://assets.unegma.net/shared/unegma-logos/unegma-logo-wide.jpg" />
              <span style={{textAlign: "right", float:"right"}}>Want your own <b>Sign</b>?<br/><a href="mailto:sales@unegma.com" target="_blank"><b>Get in touch</b></a></span><br/><br/>
              <span>Made by unegma.<br/>Website: <a href="https://unegma.com" target="_blank">unegma.com</a></span>
            </div>
          </>
        )}

        {/*todo eventually turn these to use signArray*/}

        { slideMessage === 'thursdao' && (
          <>
            <div className="infoModal__desktop-content">
              <img style={{width: "100%"}} src="https://assets.unegma.net/shared/client-logos/thursdao.jpg" />
              <span style={{textAlign: "right", float:"right"}}>Web3 Coworking in London<br/><a href="https://thursdao.xyz" target="_blank"><b>Get in touch at thursdao.xyz</b></a></span><br/><br/><br/><br/>
            </div>
          </>
        )}

        { slideMessage === 'ethldn' && (
          <>
            <div className="infoModal__desktop-content">
              <img style={{width: "100%"}} src="https://assets.unegma.net/shared/client-logos/ethldn.jpg" />
              <span style={{textAlign: "right", float:"right"}}>Ethereum/EVM Meetup in London<br/><a href="https://ethldn.org" target="_blank"><b>Get in touch at ethldn.org</b></a></span><br/><br/><br/><br/>
            </div>
          </>
        )}

        { slideMessage === 'metafashion' && (
          <>
            <div className="infoModal__desktop-content">
              <img style={{width: "100%"}} src="https://assets.unegma.net/shared/client-logos/metafashion.jpg" />
              <span style={{textAlign: "right", float:"right"}}>Metaverse Fashion in London<br/><a href="https://meetup.com/metafashion" target="_blank"><b>Get in touch at meetup.com/metafashion</b></a></span><br/><br/><br/><br/>
            </div>
          </>
        )}

        { slideMessage === 'web4london' && (
          <>
            <div className="infoModal__desktop-content">
              <img style={{width: "100%"}} src="https://assets.unegma.net/shared/client-logos/web4london.jpg" />
              <span style={{textAlign: "right", float:"right"}}>Web4 Communities in London<br/><a href="https://web4.london" target="_blank"><b>Get in touch at web4.london</b></a></span><br/><br/><br/><br/>
            </div>
          </>
        )}

        { slideMessage === 'mondao' && (
          <>
            <div className="infoModal__desktop-content">
              <img style={{width: "100%"}} src="https://assets.unegma.net/shared/client-logos/mondao.jpg" />
              <span style={{textAlign: "right", float:"right"}}>Web3 Communities in London<br/><a href="https://twitter.com/mondaomeetup" target="_blank"><b>Get in touch on Twitter</b></a></span><br/><br/><br/><br/>
            </div>
          </>
        )}

        { slideMessage === 'tuesdao' && (
          <>
            <div className="infoModal__desktop-content">
              <img style={{width: "100%"}} src="https://assets.unegma.net/shared/client-logos/tuesdao.jpg" />
              <span style={{textAlign: "right", float:"right"}}>Web3 Communities in London<br/><a href="https://tuesdao.xyz" target="_blank"><b>Get in touch at Tuesdao.xyz</b></a></span><br/><br/><br/><br/>
            </div>
          </>
        )}

        { slideMessage === 'wibt' && (
          <>
            <div className="infoModal__desktop-content">
              <img style={{width: "100%"}} src="https://assets.unegma.net/shared/client-logos/wibt.jpg" />
              <span style={{textAlign: "right", float:"right"}}>WIBT, Women in Tech Blockchain Communities<br/><a href="https://linktr.ee/WiBT" target="_blank"><b>Get in touch here!</b></a></span><br/><br/><br/><br/>
            </div>
          </>
        )}

        { slideMessage === 'thebiggerpie' && (
          <>
            <div className="infoModal__desktop-content">
              <img style={{width: "100%"}} src="https://assets.unegma.net/shared/client-logos/thebiggerpie.jpg" />
              <span style={{textAlign: "right", float:"right"}}>The Bigger Pie, Women in Tech Blockchain Community<br/><a href="https://www.thebiggerpie.io" target="_blank"><b>Get in touch at www.thebiggerpie.io</b></a></span><br/><br/><br/><br/>
            </div>
          </>
        )}

        <div className={'infoModal__button-container'}>
          {/*todo fix errors if user clicks close too quickly*/}
          <Button className="closeInfoModalButton--large" variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
