import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Rose: THREE.Mesh
  }
  materials: {
    Rose: THREE.MeshStandardMaterial
  }
}

export function RoseBase({ launch, nodes, materials, position, rotation, scale }: any) {
  const group = useRef<THREE.Group>(null!)

  return (
    <group ref={group} dispose={null}>
      <mesh geometry={nodes.Rose.geometry} material={materials.Rose} position={position} rotation={rotation} scale={scale} />
    </group>
  )
}

export function RoseRed({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--red-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}

export function RoseBlack({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--black-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}

export function RoseYellow({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--yellow-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}
export function RoseOrange({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--orange-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}
export function RoseBrown({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--brown-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}
export function RosePurple({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--purple-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}
export function RoseBlue({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--blue-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}

export function RoseWhite({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--white-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}
export function RosePink({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--pink-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}

export function RoseRainbow({ launch, position, rotation, scale }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rose--rainbow-transformed.glb`;
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <RoseBase launch={launch} nodes={nodes} materials={materials} position={position} rotation={rotation} scale={scale} />
  )
}

/**
 * Used for placing the bought rose in individual view mode
 * @param searchedObject
 * @param launch
 * @constructor
 */
export function RoseSelector({searchedObject, launch}: any) {
  console.log(searchedObject)
  switch(searchedObject.color) {
    case 'red':
      return(<RoseRed launch={launch} position={[searchedObject.position.x,searchedObject.position.y+searchedObject.roseSize.offset.y,searchedObject.position.z]} scale={searchedObject.roseSize.size} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>)
    case 'black':
      return(<RoseBlack launch={launch} position={[searchedObject.position.x,searchedObject.position.y+searchedObject.roseSize.offset.y,searchedObject.position.z]} scale={searchedObject.roseSize.size} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>)
    case 'orange':
      return(<RoseOrange launch={launch} position={[searchedObject.position.x,searchedObject.position.y+searchedObject.roseSize.offset.y,searchedObject.position.z]} scale={searchedObject.roseSize.size} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>)
    case 'yellow':
      return(<RoseYellow launch={launch} position={[searchedObject.position.x,searchedObject.position.y+searchedObject.roseSize.offset.y,searchedObject.position.z]} scale={searchedObject.roseSize.size} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>)
    case 'purple':
      return(<RosePurple launch={launch} position={[searchedObject.position.x,searchedObject.position.y+searchedObject.roseSize.offset.y,searchedObject.position.z]} scale={searchedObject.roseSize.size} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>)
    case 'white':
      return(<RoseWhite launch={launch} position={[searchedObject.position.x,searchedObject.position.y+searchedObject.roseSize.offset.y,searchedObject.position.z]} scale={searchedObject.roseSize.size} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>)
    case 'brown':
      return(<RoseBrown launch={launch} position={[searchedObject.position.x,searchedObject.position.y+searchedObject.roseSize.offset.y,searchedObject.position.z]} scale={searchedObject.roseSize.size} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>)
    case 'blue':
      return(<RoseBlue launch={launch} position={[searchedObject.position.x,searchedObject.position.y+searchedObject.roseSize.offset.y,searchedObject.position.z]} scale={searchedObject.roseSize.size} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>)
    case 'pink':
      return(<RosePink launch={launch} position={[searchedObject.position.x,searchedObject.position.y+searchedObject.roseSize.offset.y,searchedObject.position.z]} scale={searchedObject.roseSize.size} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>)
    default:
      return(<></>)
  }
}
