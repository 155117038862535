import * as THREE from 'three'
import { RigidBody } from "@react-three/rapier"
import React, { useRef, useState, useEffect } from 'react'
import {useGLTF, useAnimations, useKeyboardControls} from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { useFrame } from '@react-three/fiber';
import {interactivePlayerPosition} from "../../../helpers/helperFunctions";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/campfire-transformed.glb`;

type GLTFResult = GLTF & {
  nodes: {
    Campfire: THREE.Mesh
    fire_part_00: THREE.Mesh
    fire_part_01: THREE.Mesh
    fire_part_02: THREE.Mesh
    fire_part_03: THREE.Mesh
    fire_part_04: THREE.Mesh
    fire_part_05: THREE.Mesh
    fire_part_06: THREE.Mesh
    fire_part_07: THREE.Mesh
    fire_part_08: THREE.Mesh
    fire_part_09: THREE.Mesh
    fire_part_010: THREE.Mesh
    fire_part_011: THREE.Mesh
    fire_part_012: THREE.Mesh
    fire_part_013: THREE.Mesh
    fire_part_014: THREE.Mesh
    fire_part_026: THREE.Mesh
    fire_part_015: THREE.Mesh
    fire_part_016: THREE.Mesh
    fire_part_017: THREE.Mesh
    fire_part_025: THREE.Mesh
    fire_part_018: THREE.Mesh
    fire_part_019: THREE.Mesh
    fire_part_020: THREE.Mesh
    fire_part_021: THREE.Mesh
    fire_part_022: THREE.Mesh
    fire_part_023: THREE.Mesh
    fire_part_024: THREE.Mesh
    fire_part_027: THREE.Mesh
    fire_part_028: THREE.Mesh
  }
  materials: {
    Campfire_MAT: THREE.MeshStandardMaterial
    Campfire_fire_MAT: THREE.MeshStandardMaterial
  }
}

type ActionName =
  | 'fire_part_00|Take 001|BaseLayer'
  | 'fire_part_01|Take 001|BaseLayer'
  | 'fire_part_02|Take 001|BaseLayer'
  | 'fire_part_03|Take 001|BaseLayer'
  | 'fire_part_04|Take 001|BaseLayer'
  | 'fire_part_05|Take 001|BaseLayer'
  | 'fire_part_06|Take 001|BaseLayer'
  | 'fire_part_07|Take 001|BaseLayer'
  | 'fire_part_08|Take 001|BaseLayer'
  | 'fire_part_09|Take 001|BaseLayer'
  | 'fire_part_010|Take 001|BaseLayer'
  | 'fire_part_011|Take 001|BaseLayer'
  | 'fire_part_012|Take 001|BaseLayer'
  | 'fire_part_013|Take 001|BaseLayer'
  | 'fire_part_014|Take 001|BaseLayer'
  | 'fire_part_015|Take 001|BaseLayer'
  | 'fire_part_016|Take 001|BaseLayer'
  | 'fire_part_017|Take 001|BaseLayer'
  | 'fire_part_018|Take 001|BaseLayer'
  | 'fire_part_019|Take 001|BaseLayer'
  | 'fire_part_020|Take 001|BaseLayer'
  | 'fire_part_021|Take 001|BaseLayer'
  | 'fire_part_022|Take 001|BaseLayer'
  | 'fire_part_023|Take 001|BaseLayer'
  | 'fire_part_024|Take 001|BaseLayer'
  | 'fire_part_025|Take 001|BaseLayer'
  | 'fire_part_026|Take 001|BaseLayer'
  | 'fire_part_027|Take 001|BaseLayer'
  | 'fire_part_028|Take 001|BaseLayer'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export function Fire({ sunRise, sunSet, setSunSet, setSunRise, day, setDay, setNight, night, position, scale, playerPosition }: any) {
  const group = useRef<THREE.Group>(null)
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group)
  // console.log(animations);
  const [interacting, setInteracting] = React.useState(false);
  const [fireOff, setFireOff] = React.useState(false); // user override for turning fire off

  // const [start] = useState(() => Math.random() * 5000)

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  const myPosition = {x: position[0], y: position[1], z:position[2]}
  const [, get] = useKeyboardControls();

  useFrame((state) => {
    const { action } = get();
    if (action && !interacting) {
      if (interactivePlayerPosition(playerPosition, myPosition, scale, 1.5)) {
        setInteracting(true);

        // setSunSet(false);
        // setSunRise(false);
        // console.log('day', day)
        // console.log('night', night)
        // setNight(!night);
        setFireOff(!fireOff);
        // todo can't turn the day or night off because it gets overriden by the moving sun stuff (may have to do that on the backend if you want that feature, but that might still not work because of data being set by weatherData

        setTimeout(() => {
          setInteracting(false);
        }, 1000);
      }
    }
  });

  // todo fix this to make animations work on fire
  useEffect(() => {

    if (night && !fireOff) {
      for (let i: number = 0; i < 28; i++) {
        // console.log(actions)
        // @ts-ignore
        // mixer.clipAction(animations[i], group.current).startAt(15);
        // @ts-ignore
        // mixer.clipAction(animations[i], group.current).setDuration();
        // @ts-ignore
        mixer.clipAction(animations[i], group.current).play()
      }
    } else if (day && fireOff && !night) {
      for (let i: number = 0; i < 28; i++) {
        // console.log(actions)
        // @ts-ignore
        // mixer.clipAction(animations[i], group.current).startAt(15);
        // @ts-ignore
        // mixer.clipAction(animations[i], group.current).setDuration(2.7);
        // @ts-ignore
        mixer.clipAction(animations[i], group.current).play()
      }
    } else {
      for (let i: number = 0; i < 28; i++) {
        // console.log(actions)
        // @ts-ignore
        // mixer.clipAction(animations[i], group.current).startAt(15);
        // @ts-ignore
        // mixer.clipAction(animations[i], group.current).setDuration(2.7);
        // @ts-ignore
        mixer.clipAction(animations[i], group.current).stop()
      }
    }
  }, [day, night, fireOff]);

  // useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])

  const [showFlame1, setShowFlame1] = React.useState(true);
  const [showFlame2, setShowFlame2] = React.useState(false);

  useFrame((scene, delta) => {
    mixer?.update(delta)
  });

  // // todo trying to make flame flicker
  // useFrame(({clock}) => {
  //   if (night) {
  //     const time = clock.getElapsedTime();
  //     if (time % 3 == 0) {
  //       setShowFlame1(false);
  //     } else {
  //       setShowFlame1(true);
  //     }
  //     if (time % 2 == 0) {
  //       setShowFlame2(false);
  //     } else {
  //       setShowFlame2(true);
  //     }
  //   }
  // })

  return (
    // <RigidBody type="fixed" colliders="cuboid">
    // todo fix when adding rigidbody prevents jumping
      <group ref={group} dispose={null} position={position} scale={scale}>

        { (night && showFlame1 && !fireOff) && (
          <>
            <spotLight
              color={[255, 69, 0]}
              intensity={0.002}
              angle={4}
              penumbra={0.5}
              position={[0, 1, 0]}
              castShadow
            />
          </>
        )}
        {/*{ (night && showFlame2) && (*/}
        {/*  <>*/}
        {/*    <spotLight*/}
        {/*      color={[255, 69, 0]}*/}
        {/*      intensity={0.003}*/}
        {/*      angle={5}*/}
        {/*      penumbra={0.5}*/}
        {/*      position={[0, 1, 0]}*/}
        {/*      castShadow*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}

        {/*todo fix fire position in blender so can add collider*/}

        <group name="Scene">
          <mesh name="Campfire" geometry={nodes.Campfire.geometry} material={materials.Campfire_MAT} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_00" geometry={nodes.fire_part_00.geometry} material={materials.Campfire_fire_MAT} position={[-0.11, -0.26, -0.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_01" geometry={nodes.fire_part_01.geometry} material={materials.Campfire_fire_MAT} position={[0.03, 0.36, 0.02]} rotation={[Math.PI / 2, 0, 0.27]} scale={0} />
          <mesh name="fire_part_02" geometry={nodes.fire_part_02.geometry} material={materials.Campfire_fire_MAT} position={[-0.08, -0.62, 0.03]} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_03" geometry={nodes.fire_part_03.geometry} material={materials.Campfire_fire_MAT} position={[-0.02, 1.58, 0.01]} rotation={[Math.PI / 2, 0, 0]} scale={0} />
          <mesh name="fire_part_04" geometry={nodes.fire_part_04.geometry} material={materials.Campfire_fire_MAT} position={[0.24, -0.59, -0.3]} rotation={[Math.PI / 2, 0, 0]} scale={0} />
          <mesh name="fire_part_05" geometry={nodes.fire_part_05.geometry} material={materials.Campfire_fire_MAT} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_06" geometry={nodes.fire_part_06.geometry} material={materials.Campfire_fire_MAT} position={[0.1, -0.41, 0.03]} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_07" geometry={nodes.fire_part_07.geometry} material={materials.Campfire_fire_MAT} position={[-0.42, -1.98, 0.14]} rotation={[1.98, 0, 0]} scale={0.04} />
          <mesh name="fire_part_08" geometry={nodes.fire_part_08.geometry} material={materials.Campfire_fire_MAT} position={[0.17, -0.7, -0.22]} rotation={[Math.PI / 2, 0.5, 0]} scale={0.08} />
          <mesh name="fire_part_09" geometry={nodes.fire_part_09.geometry} material={materials.Campfire_fire_MAT} position={[0.5, -1.26, 0.21]} rotation={[0.92, 0, 0]} scale={0.04} />
          <mesh name="fire_part_010" geometry={nodes.fire_part_010.geometry} material={materials.Campfire_fire_MAT} position={[-0.1, -0.56, 0.16]} rotation={[Math.PI / 2, -0.51, 0]} scale={0.08} />
          <mesh name="fire_part_011" geometry={nodes.fire_part_011.geometry} material={materials.Campfire_fire_MAT} position={[0.68, 0.23, 0.47]} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_012" geometry={nodes.fire_part_012.geometry} material={materials.Campfire_fire_MAT} position={[0.59, 0.89, -0.73]} rotation={[1.59, 0.14, -0.04]} scale={0.03} />
          <mesh name="fire_part_013" geometry={nodes.fire_part_013.geometry} material={materials.Campfire_fire_MAT} position={[-0.8, 0.12, 0.32]} rotation={[1.8, 0.14, -0.08]} scale={0.04} />
          <mesh name="fire_part_014" geometry={nodes.fire_part_014.geometry} material={materials.Campfire_fire_MAT} position={[0.09, -1.51, -0.21]} rotation={[Math.PI / 2, 0, 0]} scale={0.08} />
          <mesh name="fire_part_026" geometry={nodes.fire_part_026.geometry} material={materials.Campfire_fire_MAT} position={[0.09, -1.08, -0.21]} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_015" geometry={nodes.fire_part_015.geometry} material={materials.Campfire_fire_MAT} position={[0.18, -0.55, -0.26]} rotation={[Math.PI / 2, 0, 0]} scale={0.08} />
          <mesh name="fire_part_016" geometry={nodes.fire_part_016.geometry} material={materials.Campfire_fire_MAT} position={[0, -1.02, -0.02]} rotation={[Math.PI / 2, 0, 0]} scale={0.12} />
          <mesh name="fire_part_017" geometry={nodes.fire_part_017.geometry} material={materials.Campfire_fire_MAT} position={[-0.12, -1.16, 0.54]} rotation={[Math.PI / 2, 0, 0]} scale={0.12} />
          <mesh name="fire_part_025" geometry={nodes.fire_part_025.geometry} material={materials.Campfire_fire_MAT} position={[-0.12, 0.05, 0.54]} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_018" geometry={nodes.fire_part_018.geometry} material={materials.Campfire_fire_MAT} position={[-0.21, -1.09, -0.61]} rotation={[Math.PI / 2, 0, 0]} scale={[0, 0.04, 0.04]} />
          <mesh name="fire_part_019" geometry={nodes.fire_part_019.geometry} material={materials.Campfire_fire_MAT} position={[0.27, -0.93, 0.04]} rotation={[Math.PI / 2, 0, 0]} scale={0.12} />
          <mesh name="fire_part_020" geometry={nodes.fire_part_020.geometry} material={materials.Campfire_fire_MAT} position={[0, -0.99, -0.19]} rotation={[Math.PI / 2, 0, 0]} scale={0.08} />
          <mesh name="fire_part_021" geometry={nodes.fire_part_021.geometry} material={materials.Campfire_fire_MAT} position={[-0.25, -0.36, 0.03]} rotation={[Math.PI / 2, 0, 0]} scale={0.12} />
          <mesh name="fire_part_022" geometry={nodes.fire_part_022.geometry} material={materials.Campfire_fire_MAT} position={[0.17, -0.28, -0.36]} rotation={[Math.PI / 2, 0, 0]} scale={0.08} />
          <mesh name="fire_part_023" geometry={nodes.fire_part_023.geometry} material={materials.Campfire_fire_MAT} position={[0.49, -0.28, 0.15]} rotation={[Math.PI / 2, 0, 0]} scale={0.08} />
          <mesh name="fire_part_024" geometry={nodes.fire_part_024.geometry} material={materials.Campfire_fire_MAT} position={[0.49, -0.45, 0.15]} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_027" geometry={nodes.fire_part_027.geometry} material={materials.Campfire_fire_MAT} position={[0.09, -0.66, -0.21]} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
          <mesh name="fire_part_028" geometry={nodes.fire_part_028.geometry} material={materials.Campfire_fire_MAT} position={[-0.01, -1.7, -0.08]} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
        </group>
      </group>
    // </RigidBody>
  )
}
