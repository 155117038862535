import {
  Button,
  Modal,
  Typography,
  Box,
  TextField
} from "@mui/material";
import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {postFeedback} from "../../helpers/apiCalls";
import {LocalFlorist} from "@mui/icons-material";
import Links from "../layout/Links";

/**
 * CreditsModal
 * @param mode
 * @param lowGraphics
 * @param setLowGraphics
 * @param searchedObject
 * @param lockKeyboard
 * @param setLockKeyboard
 * @param showCreditsModal
 * @param setShowCreditsModal
 * @param pointerControls
 * @constructor
 */
export default function CreditsModal (
  { mode, lowGraphics, setLowGraphics, searchedObject, lockKeyboard, setLockKeyboard, showCreditsModal,
    setShowCreditsModal, pointerControls }: any)
{
  const [feedbackMessage, setFeedback] = React.useState("");
  const [sentFeedback, setSentFeedback] = React.useState(false);

  /**
   *
   */
  useEffect(() => {
    if (showCreditsModal === true) {
      setLockKeyboard(true);
    } else {
      setLockKeyboard(false);
    }
  }, [showCreditsModal]);

  /**
   *
   */
  const handleClose = () => {
    setShowCreditsModal(false);
    pointerControls.current.connect()
    setTimeout(() => {pointerControls.current.lock()},110) // this needs to be higher than the timeout on the modal
  };

  /**
   * handleChangeFeedbackMessage
   * @param event
   */
  const handleChangeFeedbackMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // todo still need to lock keyboard controls
    setTimeout(() => {pointerControls.current.unlock()},100);
    setLockKeyboard(true);
    const feedback = event.target.value;
    setFeedback(feedback);
  };

  /**
   * handleSendFeedback
   */
  const handleSendFeedback = () => {
    postFeedback(feedbackMessage);
    setSentFeedback(true);
  }

  /**
   * handleToggleGraphics
   */
  const handleToggleGraphics = () => {
    setLowGraphics(!lowGraphics);

    if (pointerControls.current !== null) {
      setTimeout(() => {
        pointerControls.current.unlock()
      }, 100)
    }
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90vw',
    minWidth: '85vw',
    maxHeight: '90vh',
    // minHeight: '85vh',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      className="info-modal"
      open={showCreditsModal}
      onClose={handleClose}
      onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
    >
      <Box className="infoModal" component="div" sx={modalStyle}>

        <h2>Controls</h2>
        <Accordion style={{backgroundColor: "lightgrey"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><b>Click Here</b> for all <b>Controls</b>:</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li><b>Walk</b> by using/holding the <b>[Arrow/WASD]</b> Keys.</li>
              <li>Simultaneously use <b>[Mouse/Touchpad]</b> to <b>Guide Movement Direction</b>.</li>
              <li>Press <b>[SPACE]</b> to <b>Jump</b>.</li>
              <li><b>Hint</b>: You can <b>Run</b> by <b>holding</b> <b>[Shift]</b> whilst <b>Moving</b>!</li>
              <br/>
              <li>Press <b>[e]</b> to <b>Interact</b> with <b>Objects</b>.</li>
              <li>Press <b>[i]</b> to see this <b>Info</b>.</li>
              { !searchedObject && (
                <li>Press <b>[p]</b> to <b>Place/Remove</b> the <b>{`${mode.replace(/\w/, (c:string) => c.toUpperCase())}`}</b>.</li>
              )}
              <br/>
              <li>
                <span className="red">Remember! You can <b>PRESS [ESC]</b> to disengage MetaWorld.</span>
              </li>
              <li>
                <span><b>Click the Screen</b> to re-engage.</span>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <div className="">
          <br/>
          <div style={{display:"flex",flexDirection:"row"}}>
            <span>Remember, if things <b>Seem Slow</b>,<br/>try toggling the <b>Graphics Button</b>!&nbsp;</span>
            { lowGraphics && (
              <Button onClick={handleToggleGraphics} variant="contained" color="success" className="lowGraphicsButton"><LocalFlorist /></Button>
            )}

            { !lowGraphics && (
              <Button onClick={handleToggleGraphics} variant="outlined" color="success" className="lowGraphicsButton"><LocalFlorist /></Button>
            )}
          </div>
        </div>

        <br/>
        <hr/>

        <h2>Send Feedback</h2>

        <div style={{display: "flex", flexDirection:"column"}}>
          <TextField
            label="Feedback"
            // multiline
            // rows={1}
            value={feedbackMessage}
            onChange={handleChangeFeedbackMessage}
            onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
          />
          <Button disabled={sentFeedback } style={{alignSelf:'flex-start', marginTop: '5px'}} className="mintModalButton--large" variant="contained" color="success" onClick={handleSendFeedback}>
            <b>Send Feedback</b>
          </Button>
        </div>

        <br/>
        <hr/>

        <h2>Links</h2>
        <Links />

        <hr/>

        <h2>Credits</h2>
        <p>This site was inspired by <a href="src/components/modals/CreditsModal" target="_blank">Grosvenor Square in Mayfair London</a>. Go donate!</p>
        <p>Thanks to <a href="https://counterreality.com" target="_blank">CounterReality.com</a> for the inspiration! Keep Dreaming new realities!</p>
        <p>Special thanks to everyone who tested and gave feedback: especially the London Tech Community, and my family.</p>
        <p>
          <a href="https://sketchfab.com/3d-models/minecraft-wolf-9aa99b5c203240f7ac357c4e8b84e1d6" target="_blank">Lion as a Modification of this model</a> (no affiliation with Minecraft).<br/>
          <a href="https://sketchfab.com/3d-models/rose-d4fa134db50e4755bce34a47e2c6359b" target="_blank">Rose as a Modification of this model.</a><br/>
          <a href="https://sketchfab.com/3d-models/low-poly-rocket-cb7bd0af3223462489b55ce2628e43af" target="_blank">Rocket as a Modification of this model.</a>
        </p>

        <div className={`infoModal__button-container`}>
          <Button className="closeInfoModalButton--large" variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
