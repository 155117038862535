import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {ReactThreeFiber} from "@react-three/fiber";
import {InstancedBufferAttribute, InstancedMesh, Object3D} from "three";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/TreeLarch-transformed.glb`;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // TODO UPDATE TO ALLOW NULL ON TYPES
      instancedMesh: ReactThreeFiber.Object3DNode<InstancedMesh, typeof InstancedMesh>
      instancedBufferAttribute: ReactThreeFiber.Object3DNode<InstancedBufferAttribute, typeof InstancedBufferAttribute>
    }
  }
}

type GLTFResult = GLTF & {
  nodes: {
    TreeLarch: THREE.Mesh
  }
  materials: {
    Trees: THREE.MeshStandardMaterial
  }
}

export default function TreesLarch({ position, rotation, scale, count }: any) {
  const ref = useRef(null!);
  const dummy = new Object3D();
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult


  useEffect(() => {
    // @ts-ignore
    // geometry.computeVertexNormals();

    // Math.random()+1
    //

    // pow 2 because value comes in as a sqrt
    for (let i = 0; i < Math.pow(count,2); i++) {
      dummy.position.set(5*Math.random(),-1,i*5+Math.random());
      dummy.rotation.set(Math.PI / 2, 0, Math.PI / 2); // rotation={[Math.PI / 2, 0, 0]}
      dummy.scale.setScalar(0.05+Math.random()+Math.random());
      dummy.updateMatrix();
      // @ts-ignore
      ref.current.setMatrixAt(i++, dummy.matrix);
    }
    // @ts-ignore
    ref.current.instanceMatrix.needsUpdate = true;
  }, [count]);  // todo may need to re-add geometry (and then move geometry into variable above)

  {/*<group ref={group} {...props} dispose={null}>*/}
  {/*  <mesh castShadow receiveShadow geometry={nodes['01_Tree_Fir_2'].geometry} material={materials.Mat_3} position={[0,0,0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />*/}
  {/*</group>*/}

  // todo is it worth making only the first few lines do shadow stuff ?

  return (
    <instancedMesh castShadow receiveShadow ref={ref} args={[nodes.TreeLarch.geometry, materials.Trees, count]} scale={scale}>
    </instancedMesh>
  )
}
