/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import {useGLTF, useKeyboardControls} from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {RigidBody} from "@react-three/rapier";
import {useFrame} from "@react-three/fiber";
import {interactivePlayerPosition} from "../../../helpers/helperFunctions";

type GLTFResult = GLTF & {
  nodes: {
    Terminal: THREE.Mesh
  }
  materials: {
    Terminal: THREE.MeshStandardMaterial
  }
}

export function Terminal({ setSlideModalCharacter, setSlideMessage, setShowSlideModal, scale, position, rotation, pointerControls, playerPosition, lockKeyboard }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/rocket-mode/terminal-transformed.glb`;
  const group = useRef<THREE.Group>(null!)

  const myPosition = {x: position[0], y: position[1], z:position[2]}
  const [, get] = useKeyboardControls();
  const [interacting, setInteracting] = React.useState(false);

  useFrame((state) => {
    const {action} = get();
    if (action && !interacting && !lockKeyboard) {

      if (interactivePlayerPosition(playerPosition, myPosition, scale, 1.5)) {
        setInteracting(true);
        // console.log('Sign Interaction');
        setSlideModalCharacter('terminal');
        setSlideMessage('terminal');
        setShowSlideModal(true);
        setTimeout(() => {
          pointerControls.current.unlock();
        }, 100);
        setTimeout(() => {
          setInteracting(false);
        }, 100);
      }
    }
  })

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <group ref={group} scale={scale} position={position} rotation={rotation} dispose={null}>
      <RigidBody type="fixed" colliders="cuboid">
        <mesh castShadow receiveShadow geometry={nodes.Terminal.geometry} material={materials.Terminal}  />
      </RigidBody>
    </group>
  )
}
