import * as THREE from "three"
import {useLoader} from "@react-three/fiber";
import {GroundBase} from "../../Base/Ground";

export function GroundSnow() {
  let img = {
    snowGrass: `${process.env.REACT_APP_ASSETS_URL}/snow-version/grass-snow.jpg`
  };
  const texture = useLoader(THREE.TextureLoader, img.snowGrass);

  return (
    <GroundBase texture={texture} />
  )
}

