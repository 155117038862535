import {Button, createStyles, makeStyles, Modal, Theme, Typography, Box, MenuItem, Select, InputLabel} from "@mui/material";
import React, { useEffect } from "react";
import {TextField} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {ChatBubbleOutline, Create, Description, People, Telegram} from "@mui/icons-material";
import {useLoader} from "@react-three/fiber";
import * as THREE from "three";

let imgs = {
  choppingBlock: `${process.env.REACT_APP_ASSETS_URL}/choppingBlock.jpg`,
};

/**
 * SizeModal
 * @param mode
 * @param setForestColliders
 * @param setLockKeyboard
 * @param objectCount
 * @param setObjectCount
 * @param showSizeModal
 * @param setShowSizeModal
 * @param pointerControls
 * @constructor
 */
export default function SizeModal (
  { mode, setForestColliders, setLockKeyboard, objectCount, setObjectCount, showSizeModal, setShowSizeModal,
    pointerControls }: any
) {

  const min = 100;
  const max = 5000; /// 5000 shows a gap in the trees, and performance takes a bit of a hit

  useEffect(() => {
    if (showSizeModal === true) {
      setLockKeyboard(true);
    } else {
      setLockKeyboard(false);
    }
  }, [showSizeModal]);

  const handleClose = () => {
    setShowSizeModal(false);
    pointerControls.current.connect()
    setTimeout(() => {pointerControls.current.lock()},110) // this needs to be higher than the timeout on the modal
  };
  const handleChangeObjectCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newObjectCount = parseInt(event.target.value);
    if (newObjectCount >= min && newObjectCount <=  max) {
      setObjectCount(newObjectCount);
      setForestColliders(false); // todo remove forest colliders for now, because can't get their position to update
    }
  }

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90vw',
    minWidth: '85vw',
    maxHeight: '90vh',
    // minHeight: '85vh',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      className="info-modal"
      open={showSizeModal}
      onClose={handleClose}
      onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
    >
      <Box className="infoModal" component="div" sx={modalStyle}>

        <div className="characterTitle">
          <img src={imgs.choppingBlock} style={{width: "90px", height: "90px"}} />
          <div>
            <h1>Chopping Block:</h1>
            <div className="infoModal__desktop-content">
            </div>
          </div>
        </div>


        <div>
          <br/>
          <p><b>Expand or Contract Field:</b></p>
          <p><b>Min: {min}, Max: {max}</b></p>

          <TextField
            defaultValue={objectCount}
            inputProps={{ maxLength: 4 }}
            onChange={handleChangeObjectCount}
            onClick={() => setTimeout(() => {pointerControls.current.unlock()},100)}
          />
          <br/>
        </div>

        <div className={'infoModal__button-container'}>

          <Button className="closeInfoModalButton--large" variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
