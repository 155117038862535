import * as THREE from 'three'
import React, {useRef } from 'react'
import { RigidBody } from '@react-three/rapier';

// todo these arent working properly when expanding the field

/**
 * WallBase
 *
 * @param texture
 * @param rotation
 * @param position
 * @param scale
 * @param mode
 * @constructor
 */
export function WallBase({ texture, rotation, position, scale, mode }: any) {
  const rigidBody = useRef(null!);
  // const [localPosition, setLocalPosition] = useState(position);

  // todo somehow try and move colliders when updating roseCount
  // useEffect(() => {
  //
  //   if(rigidBody.current != null) {
  //     console.log('rigidBody', rigidBody.current)
  //     // @ts-ignore
  //     rigidBody.current.setTranslation([1,1,1])
  //   }
  // }, [position]);
  // texture.wrapS = texture.wrapS = THREE.RepeatWrapping;
  // texture.wrapT = texture.wrapT = THREE.RepeatWrapping;
  // texture.offset.set( 0, 0 );
  // texture.repeat.set( 2, 2 );

  const textureRef = useRef(null!);

  // todo fix so that the wall isn't blurred when change modes
  // useEffect(() => {
    if (texture) {
      // @ts-ignore
      // textureRef.current.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
      texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
      // @ts-ignore
      // textureRef.current.repeat.set(150, 3);
      texture.repeat.set(150, 3);
    }
  // },[texture])

  // console.log(textureRef)
  return (
    <group rotation={rotation} position={position}>
      {/*<RigidBody type="fixed" colliders="cuboid" scale={[100, 2, 10]}>*/}

      { (mode === 'rose' && !texture) && (
        <>
          {/*todo can't seem to get rigidbody to move when adjusting using the stump - try changing fixed to dynamic?*/}
          <RigidBody ref={rigidBody} colliders="cuboid" type="fixed">
            <mesh>
              <boxGeometry args={[1000, 20, 2]} />
                <meshPhongMaterial color="#ff0000" opacity={0.01} transparent />
              {/*<meshPhongMaterial color="#ff0000"  />*/}
            </mesh>
          </RigidBody>
        </>
      )}

      { (mode === 'rocket' && texture) && (
        <>
          {/*todo can't seem to get rigidbody to move when adjusting using the stump - try changing fixed to dynamic?*/}
          <RigidBody ref={rigidBody} colliders="cuboid" type="fixed">
            <mesh>
              <boxGeometry args={[1000, 20, 2]} />
              { texture && (
                <meshStandardMaterial ref={textureRef} attach="material" side={THREE.DoubleSide} map={texture} map-repeat={[10, 10]} color="white" />
              )}
              {/*<meshPhongMaterial color="#ff0000"  />*/}
            </mesh>
          </RigidBody>
        </>
      )}
    </group>
  )
}

/**
 * InvisibleWall
 *
 * @param rotation
 * @param position
 * @param scale
 * @param mode
 * @constructor
 */
export function InvisibleWall({rotation, position, scale, mode}:any) {

  return (
    <WallBase texture={null} rotation={rotation} position={position} scale={scale} mode={mode} />
  )
}
